import { ImageGalleryProivderContext } from "./ImageGalleryProivder.context";
import { useContext } from "react"

export const useImageGalleryProvider = () => {
  const context = useContext(ImageGalleryProivderContext);

  if(context === undefined){
    throw Error("useGamesProvider must be used within a GamesProvider");
  }

  return context;
}