import { FormActionProps } from "./FormAction.types";
import { Box, Button } from "@material-ui/core";
import React, { ComponentType } from "react";

export const FormAction: ComponentType<FormActionProps> = ({
  isModal = false,
  ...rest
}) => {
  return (
    <Box display="flex" justifyContent="flex-start" width="100%">
      <Button variant="contained" {...rest}>
        {isModal ? "Save" : "Next"}
      </Button>
    </Box>
  );
};
