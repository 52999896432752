import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "75%;", // 4:3
  },
  cardSelected: {
    backgroundColor: theme.palette.primary.dark,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
  },
}));
