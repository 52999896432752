import React, { FunctionComponent, useMemo } from "react";
import { useStyles } from "./CustomButton.styles";
import { Box, Button } from "@material-ui/core";

export const CustomButtonComponent: FunctionComponent = (props: any) => {
  const classes = useStyles({
    widthPercentage: props.blockProps.widthPercentage,
    textColor: props.blockProps.textColor,
    buttonColor: props.blockProps.color,
  });

  const getAlignment = useMemo(() => {
    const align = props.blockProps.alignment;
    if (align === "left") return "flex-start";
    if (align === "right") return "flex-end";
    return "center";
  }, [props.blockProps.alignment]);

  return (
    <Box
      justifyContent={getAlignment}
      alignItems="center"
      display="flex"
      width="100%"
    >
      <Button
        onClick={() => props.blockProps.onClick()}
        className={classes.root}
        rel="noopener noreferrer"
        target="_blank"
        component="a"
      >
        {props.blockProps.text}
      </Button>
    </Box>
  );
};
