import React from "react";
import { Box, Grid, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation
} from "react-router-dom";
import { GenericSettings } from "./views/GenericSettings";
import { ConnectedDeployments } from "./views/ConnectedDeployments";
import { Administration } from "./views/Administration";

const useStyles = makeStyles((theme) => ({
    tabsContainer: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minHeight: "20vh"
    }
}))

const SettingsTab = ({ path, exact = false, label, value }: any) => {
    let match = useRouteMatch({
        path,
        exact,
    });

    return <Tab
        value={value}
        label={label}
        selected={Boolean(match)}
        component={Link}
        to={path}
    />

}

export const Settings = () => {
    let location = useLocation();
    let { path } = useRouteMatch();
    const classes = useStyles();
    console.log(location)

    const links = [
        { label: "General Settings", path: `${path}`, exact: true },
        { label: "Connected Deployments", path: `${path}/connected-deployments` },
        //{ label: "Administration", path: `${path}/administration` },
    ]

    const currentValue = links.findIndex((l) => l.path === location.pathname);

    return (
        <>
            <Typography variant="h2" paragraph>Application Settings</Typography>
            <Grid container>
                <Grid item xs={12} md={2} className={classes.tabsContainer}>
                    <Tabs
                        value={currentValue}
                        orientation="vertical"
                        variant="scrollable"
                    >
                        {links.map((linkProps, index) =>
                            <SettingsTab key={index} value={index} {...linkProps} />
                        )}
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Box padding={2}>
                        <Switch>
                            <Route path={path} exact>
                                <GenericSettings />
                            </Route>
                            <Route path={`${path}/connected-deployments`}>
                                <ConnectedDeployments />
                            </Route>
                            <Route path={`${path}/administration`}>
                                <Administration />
                            </Route>
                        </Switch>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}