import { FirestoreCollection } from "@react-firebase/firestore";
import React, { FunctionComponent } from "react";
import { InboxSentimentProviderContext } from "./InboxSentimentProvider.context";

export const InboxSentimentProvider: FunctionComponent = ({ children }) => {
  return (
    <FirestoreCollection path="/inbox-sentiment/">
      {({ isLoading, value }) => {
        const availableSentiment = value?.map(tag => tag.name)
        return (
          <InboxSentimentProviderContext.Provider
            value={{
              availableSentiment,
              isLoading,
            }}
          >
            {children}
          </InboxSentimentProviderContext.Provider>
        );
      }}
    </FirestoreCollection>
  );
};
