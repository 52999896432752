import { defaultValues } from "./RichInboxProvider.const";
import {
  RichInboxProviderState,
  RichInboxPostType,
  LanguageFilterFilterMode,
} from "./RichInboxProvider.types";

export const createStore = (post?: RichInboxPostType) => {
  const initialPostData:RichInboxPostType = { ...defaultValues, ...post };
  if (!initialPostData.localisation.languageFilter) {
    initialPostData.localisation.languageFilter = {
      filterMode: LanguageFilterFilterMode.All
    };
  }

  const store: RichInboxProviderState = {
    post: initialPostData,
    setPost(post) {
      this.post = { ...this.post, ...post };
    },
    setDraft(isDraft) {
      this.post.publishing.isDraft = isDraft;
    },
    setTitle(title) {
      this.post.title = title;
    },
    setAppVersionFilters(appVersionFilters) {
      this.post.appVersionFilters = appVersionFilters;
    },
    setContentRating(rating) {
      this.post.rating = rating;
    },
    setLanguageFilterMode(filterMode) {
      this.post.localisation.languageFilter!.filterMode = filterMode;
    },
    setCountriesFilterMode(filterMode) {
      this.post.localisation.countries!.filterMode = filterMode;
    },
    setCountriesFilterValues(filterValues) {
      this.post.localisation.countries!.filterValues = filterValues;
    },
    setLocalisation(localisation) {
      this.post.localisation = { ...this.post.localisation, ...localisation };
    },
    setPlacementType(type) {
      this.post.placementType = type;
    },
    setSegments(segments) {
      this.post.segments = segments;
    },
    addSentiment(sentiment) {
      this.post.sentiment.concat(sentiment);
    },
    removeSentiment(sentiment) {
      this.post.sentiment = this.post.sentiment.filter((s) => s !== sentiment);
    },
    addTag(tag) {
      this.post.tags.concat(tag);
    },
    removeTag(tag) {
      this.post.tags = this.post.tags.filter((t) => t !== tag);
    },
    deleteSKUFilter() {
      delete this.post.SKUFilter;
    },
    setSKUFilter(filter) {
      this.post.SKUFilter = filter;
    },
    get getLanguages() {
      return this.post.localisation.languages ?? [];
    },
    get getTranslations() {
      return this.post.localisation.translations ?? [];
    },
  };
  return store;
};
