import { Avatar, Box, Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { LoadingIndicator } from 'Components/LoadingIndicator';
import { useFirebaseProvider } from 'Providers/FirebaseProvider';
import React from 'react';
import { useAsyncTask } from 'react-hooks-async';
import { revertChange } from './async';
import WarningIcon from "@material-ui/icons/Warning";
import { useStyles } from './RevertDialog.styles';
import { PublisherRevertDialogProps } from './RevertDialog.types';

export const RevertDialog = ({ onCancel, onComplete, entry }: PublisherRevertDialogProps) => {
  const firebase = useFirebaseProvider();
  const classes = useStyles();

  const revertChangeTask = useAsyncTask(revertChange);

  const doRevert = async () => {
    if (entry) {
      await revertChangeTask.start(firebase.functions, {
        postId: entry.postId
      });
      onComplete();
    }
  };

  return (
  <Dialog 
    PaperProps={{ className: classes.paperMods }}
    open={entry !== undefined} 
    aria-labelledby="customized-dialog-title"
    >
      <Avatar className={classes.WarningIconAvator}>
        <WarningIcon className={classes.WarningIcon} />
      </Avatar>
      <Typography variant="h3" align="center">
        DANGER
      </Typography>
      <DialogContent>
          {revertChangeTask.started && <>
            <LoadingIndicator />
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              width="100%"
            >
              <Typography variant="h3">Reverting Change to {entry?.name}...</Typography>
            </Box>
          </>}
          {!revertChangeTask.started && <>
            <Typography variant="body1" paragraph>
              You are about to revert all un-published edits to the post named "{entry?.name}".
            </Typography>

            <Box display={"flex"} justifyContent="space-between" padding={5}>
            <Button
              variant="contained"
              onClick={doRevert}
              color="primary"
            >
              Revert Changes
            </Button>
              <Button variant="contained" color="default" onClick={onCancel}>Cancel</Button>
            </Box>
          </>}
    </DialogContent>
  </Dialog>);
}
