import { functions as fn } from "firebase";

export const revertToPublishedState = async (_: any, functions: fn.Functions) => {
  try {
    const getCall = functions.httpsCallable("revertToPublishedState");
    return await getCall();
  } catch (error) {
    console.log("[revertToPublishedState]: An error occured", error);
    return {
        error: error.toString()
    };
  }
};
