import React, { ComponentType, Fragment, useCallback, useState } from "react";
import { ManagePlacementTypeProps } from "./ManagePlacementType.types";
import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { placementOptions } from "./ManagePlacementType.const";
import { PlacementOptionCard } from "./PlacementOptionCard";
import { Typography, Grid, Box } from "@material-ui/core";
import { useStyles } from "./ManagePlacementType.styles";
import { InboxAvatar } from "./InboxAvatar";
import { FormAction } from "../FormAction";
import { observer } from "mobx-react-lite";
import {
  useRichInboxProvider,
  parsePlacementType,
} from "Domains/RichInbox/components/RichInboxProvider";
import { InboxBanner } from "./InboxBanner";

export const ManagePlacementType: ComponentType<ManagePlacementTypeProps> = observer(
  ({ isModal = false, onSubmit }) => {
    const firebase = useFirebaseProvider();
    const store = useRichInboxProvider();
    const classes = useStyles();

    const [type, setType] = useState(store.post.placementType);

    const handleChange = async (type: string) => {
      setType(parsePlacementType(type as any));

      if (type !== "inbox" && store.post.avatarUrl) {
        await firebase.storage.refFromURL(store.post.avatarUrl).delete();
        store.setPost({ avatarUrl: undefined });
      }
    };

    const submitForm = useCallback(() => {
      store.setPlacementType(type);
      onSubmit();
    }, [store, type, onSubmit]);

    return (
      <Fragment>
        <Typography variant="h5" paragraph>
          Post Placement
        </Typography>
        <Typography variant="body1" paragraph>
          Choose from one of the below RichInbox post placements, each placement
          type will apply different constraints on the content you can display
          in your post
        </Typography>
        <Box marginBottom={4} marginTop={2}>
          <Grid container spacing={4} justify="flex-start" alignItems="stretch">
            {placementOptions.map((placement, index) => (
              <Grid
                key={index}
                component={Box}
                display="flex"
                md={4}
                xs={12}
                item
                className={classes.cardContainer}
              >
                <PlacementOptionCard
                  key={index}
                  PlacementChoice={type}
                  image={placement.image}
                  clickAction={handleChange}
                  placement={placement.placement}
                  description={placement.description}
                />
              </Grid>
            ))}
            {type === parsePlacementType("inbox") && (
              <>
                <Grid item xs={12}>
                  <InboxAvatar />
                </Grid>
                <Grid item xs={12}>
                  <InboxBanner />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <FormAction
          disabled={type === undefined}
          onClick={submitForm}
          isModal={isModal}
        />
      </Fragment>
    );
  }
);
