import { Box, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useImageGalleryProvider } from 'Providers/ImageGalleryProivder'
import React, { useState } from 'react'
import { ExistingImageView } from './ExistingImageView';
import { FileUploadView } from './FileUploadView/FileUploadView';
import { ImageGalleryModalProps } from './ImageGalleryModal.types'

export const ImageGalleryModal = ({ isIcons = false, isOpen = false, onClose = () => { }, onInsertImage }: ImageGalleryModalProps) => {
    const { galleryLoading } = useImageGalleryProvider()
    const [value, setValue] = useState('1');

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    return (
        <Dialog
            fullWidth
            maxWidth={"xl"}
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>{isIcons ? "Icon" : "Image"} Gallery</DialogTitle>
            <DialogContent>
                <Box
                    minHeight={"30vh"}>
                    {galleryLoading && (<Box
                        height={"100%"}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>)}
                    {!galleryLoading &&
                        <TabContext value={value}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Existing Images" value="1" />
                                <Tab label="Upload New Image" value="2" />
                            </TabList>
                            <TabPanel value="1">
                                <ExistingImageView isIcons={isIcons} onSelectImage={(url) => { onInsertImage(url); onClose(); }} />
                            </TabPanel>
                            <TabPanel value="2">
                                <FileUploadView
                                    isIcons={isIcons}
                                    onUploadComplete={() => setValue("1")}
                                    acceptedFiles={[".jpg", ".jpeg", ".png"]}
                                    destinationPath={"/gallery/"}
                                />
                            </TabPanel>
                        </TabContext>
                    }
                </Box>
            </DialogContent>
        </Dialog>
    )
}