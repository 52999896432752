import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { AppNavMenuItemProps } from "./NavMenuItem.types";
import React from "react";
import {
  LinkProps as RouterLinkProps,
  Link as RouterLink,
  useRouteMatch,
} from "react-router-dom";

export const NavMenuItem = ({ icon, label, to }: AppNavMenuItemProps) => {
  const match = useRouteMatch(to);

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem selected={!!match} button component={renderLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};
