import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    errorBtn: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },  
    media: {
      height: 0,
      paddingTop: "42.8571%", // 16:9
    },
    cardSelected: {
      backgroundColor: theme.palette.primary.dark,
    },
    cardContainer: {
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
    card: {
      display: "flex",
      flex: "1 1 100%",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
  })
);
