import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme, { ratio: number }>({
  outerWrapper: {
    height: 0,
    paddingBottom: (props) => `${(1 / props.ratio) * 100}%`,
    position: "relative",
    width: "100%",
  },
  innerWrapper: {
    bottom: 0,
    left: 0,
    overflow: "hidden",
    position: "absolute",
    right: 0,
    top: 0,

    "& img": {
      objectFit: "cover",
      width: "100%",
    },
  },
});
