import { Box, Button, Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core'
import { AspectRatio } from 'Components/AspectRatio'
import { DeleteButton } from 'Components/DeleteButton'
import { useFirebaseProvider } from 'Providers/FirebaseProvider'
import { useImageGalleryProvider } from 'Providers/ImageGalleryProivder'
import { GalleryItem, GalleryItemType } from 'Providers/ImageGalleryProivder/ImageGalleryProivder.types'
import React, { useCallback, useState } from 'react'
import { useStyles } from './ExistingImageView.styles'
import { ExistingImageViewProps } from './ExistingImageView.types'

export const ExistingImageView = ({ isIcons = false, onSelectImage }: ExistingImageViewProps) => {
    const classes = useStyles();
    const { storage, firestore } = useFirebaseProvider();
    const { galleryItems } = useImageGalleryProvider()

    const [selectedImage, setSelectedImage] = useState<GalleryItem | undefined>();

    const useImage = useCallback(
        () => {
            onSelectImage(selectedImage!.URL);
            setSelectedImage(undefined);
        },
        [onSelectImage, selectedImage]
    )

    const deleteImage = useCallback(async () => {
        setSelectedImage(undefined);
        await storage.refFromURL(selectedImage!.URL).delete();
        await firestore.collection("gallery").doc(selectedImage!.Id).delete();
    }, [selectedImage, storage, firestore])

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="start">
                <Typography variant="h2" paragraph>Existing {isIcons ? "Icons" : "Images"}</Typography>
                <div>
                    <DeleteButton disabled={!selectedImage} onDelete={deleteImage}></DeleteButton>
                    {" "}
                    <Button variant="contained" color="primary" disabled={!selectedImage} onClick={useImage}>
                        Use This {isIcons ? "Icon" : "Image"}
                    </Button>
                </div>
            </Box>
            <Typography variant="body1" paragraph>
                Select an {isIcons ? "Icon" : "Image"} from the list below, or if you would like
                to add a new {isIcons ? "Icon" : "Image"} please upload it using the upload tab
            </Typography>
            {!galleryItems && <>You Have no {isIcons ? "Icons" : "Images"} Uploaded yet, please add some through the upload tab.</>}
            {galleryItems!.length && <>
                <Grid container spacing={3}>
                    {galleryItems.filter(i => isIcons ? i.type === GalleryItemType.Icon : i.type === GalleryItemType.Generic).map(item =>
                        <Grid item xs={2}>
                            <Card className={selectedImage && selectedImage!.Id === item!.Id ? classes.cardSelected : ""}>
                                <CardActionArea onClick={() => setSelectedImage(item)}>
                                    <CardContent>
                                        <AspectRatio ratio="1x1">
                                            <img src={item.URL} alt="source" />
                                        </AspectRatio>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </>}
        </div>
    )
}

