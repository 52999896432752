import { RawDraftContentState } from "draft-js";
import {
  LocalisationConfig,
  LanguageFilter,
  PlacementType,
  Translation,
  Language,
  Segment,
  Post,
  ESRBRating,
  AppVersionFilter,
  SKUFilter,
} from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import { Countries } from "@halfbrick/network-effect-schemas/dist/typescript/Post";

export enum CountriesFilterMode {
  All = "all",
  Exclude = "exclude",
  Include = "include",
}

export enum LanguageFilterFilterMode {
  All = "all",
  Translated = "translated",
}

export enum AppVersionFilterFilterMode {
  None = "none",
  Equal = "equal",
  Greater = "greater",
  Less = "less",
  NotEqual = "not-equal",
}

export type RichInboxPostType = Omit<Post, "localisation"> & {
  localisation: RichInboxLocalisationConfig;
};

export type RichInboxLocalisationConfig = Omit<
  LocalisationConfig,
  "translations" | "countries" | "defaultLanguage" | "languageFilter"
> & {
  
  languageFilter?: RichInboxLanguageFilterConfig;
  countries: CountriesFilterConfig;
  translations: RichInboxTranslation[];
};

export type RichInboxLanguageFilterConfig = Omit<LanguageFilter, "filterMode"> & {
  filterMode: LanguageFilterFilterMode
}

export type CountriesFilterConfig = Omit<Countries, "filterMode"> & {
  filterMode: CountriesFilterMode;
};

export type RichInboxTranslation = Omit<Translation, "content"> & {
  content: RawDraftContentState;
  subject: string;
};

export type RichInboxProviderState = {
  post: RichInboxPostType;
  setPost: (post: Partial<RichInboxPostType>) => void;
  setTitle: (title: string) => void;
  setDraft: (isDraft: boolean) => void;
  setContentRating: (rating: ESRBRating) => void;
  setLanguageFilterMode: (filterMode: LanguageFilterFilterMode) => void;
  setLocalisation: (localisation: Partial<RichInboxLocalisationConfig>) => void;
  setCountriesFilterMode: (filterMode: CountriesFilterMode) => void;
  setCountriesFilterValues: (filterValues: string[]) => void;
  setPlacementType: (type: PlacementType) => void;
  setSegments: (segments: Segment[]) => void;
  setAppVersionFilters: (appVersionFilters: AppVersionFilter[]) => void;
  addSentiment: (sentiment: string) => void;
  removeSentiment: (sentiment: string) => void;
  addTag: (tag: string) => void;
  removeTag: (tag: string) => void;
  deleteSKUFilter: () => void;
  setSKUFilter: (filter: SKUFilter) => void;
  readonly getLanguages: Language[];
  readonly getTranslations: RichInboxTranslation[];
};

export type RichInboxProviderProps = {
  post?: RichInboxPostType;
};

