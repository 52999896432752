import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, Input, InputLabel, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { LoadingIndicator } from 'Components/LoadingIndicator';
import { useFirebaseProvider } from 'Providers/FirebaseProvider';
import React, { useState } from 'react';
import { useAsyncRun, useAsyncTask } from "react-hooks-async";
import { fetchPendingChanges, fetchPublishedVersions, publishSelectedChanges } from './async';
import RefreshIcon from "@material-ui/icons/Refresh";
import MUIDataTable from 'mui-datatables';
import { overviewColumns } from './Publisher.const';
import { PublishChangesResponse, PublishDiscoveryDiff } from '@halfbrick/network-effect-web-shared';
import { auth } from 'firebase';
import { ShowDiffDialog } from './components';
import { RevertDialog } from './components/RevertDialog';
import { fetchGames } from 'Providers/GamesProvider/async';

export const Publisher = () => {
  const firebase = useFirebaseProvider();
  
  const fetchPendingChangesTask = useAsyncTask(fetchPendingChanges);
  const fetchPublishedVersionsTask = useAsyncTask(fetchPublishedVersions);
  const publishChangesTask = useAsyncTask(publishSelectedChanges);
  const fetchGamesTask = useAsyncTask(fetchGames);
  
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [publishMessage, setPublishMessage] = useState<string | undefined>(undefined);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [publishCompleteDialogOpen, setPublishCompleteDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogData, setErrorDialogData] = useState<string | undefined>();
  const [publishDiffDialogData, setPublishDiffDialogData] = useState<PublishDiscoveryDiff | undefined>();
  const [revertDialogData, setRevertDialogData] = useState<PublishDiscoveryDiff | undefined>();

  useAsyncRun(fetchPendingChangesTask, firebase.functions);
  useAsyncRun(fetchPublishedVersionsTask, firebase.functions);
  useAsyncRun(fetchGamesTask, firebase.functions);

  const refreshPendingChanges = () => {
    fetchPendingChangesTask.start(firebase.functions);
  };

  const refreshPublishedVersions = () => {
    fetchPublishedVersionsTask.start(firebase.functions);
  };

  const clearConfirmationDialogsAndRefresh = () => {
    refreshPendingChanges();
    refreshPublishedVersions();
    setPublishCompleteDialogOpen(false);
    setErrorDialogOpen(false);
    setErrorDialogData(undefined);
    setPublishMessage(undefined);
    setPublishDiffDialogData(undefined);
    setRevertDialogData(undefined);
  };

  const customToolbar = () => {
    return (
      <Tooltip title="Refresh Pending Changes">
        <IconButton aria-label="reload" onClick={refreshPendingChanges}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const onRowSelectionChange = (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) => {
    setSelectedRows(allRowsSelected);
  };

  const convertGameId = (gameId:string):string => {
    if (fetchGamesTask.result) {
      var doc = fetchGamesTask.result.find((r) => r.titleID.split("-").join("_") === gameId);
      if (doc) {
        return doc.displayName;
      }
    }
    return gameId;
  };

  const canPublish = selectedRows.length > 0 && publishMessage && publishMessage.trim().length > 0;
  const currentUserDiaplyName = auth().currentUser!.displayName;
  const currentUser = auth().currentUser!.email;
  
  const doPublish = async () => {
    setConfirmationDialogOpen(false);
    if (publishMessage && canPublish && currentUser) {
      const result = await publishChangesTask.start(firebase.functions, {
        user: currentUser,
        message: publishMessage,
        ids: selectedRows.map((r) => fetchPendingChangesTask.result!.changes.pending[r.dataIndex].postId)
      }) as PublishChangesResponse;

      if (!result) {
        setErrorDialogData("No result returned from publish changes request");
        setErrorDialogOpen(true);
      } else if (result.error) {
        setErrorDialogData(result.error);
        setErrorDialogOpen(true);
      } else {
        setPublishCompleteDialogOpen(true);
      }
    }
  };

  return (<>
    <Box display={"flex"} alignItems="center" justifyContent="space-between" paddingBottom={2}>
        <Typography variant="h2">Publisher {customToolbar()}</Typography>
    </Box>
    {fetchPendingChangesTask.pending && <>
      <LoadingIndicator />
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        width="100%"
      >
        <Typography variant="h3">Loading...</Typography>
      </Box>
    </>} 
    {publishChangesTask.started && <>
      <LoadingIndicator />
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        width="100%"
      >
        <Typography variant="h3">Publishing Changes...</Typography>
      </Box>
    </>}
    {!fetchPendingChangesTask.pending && fetchPendingChangesTask.result && fetchPendingChangesTask.result.error && 
        <Alert severity="error" variant="filled">
            {fetchPendingChangesTask.result.error}
        </Alert>
    }
    {!fetchPendingChangesTask.pending && fetchPendingChangesTask.result && !fetchPendingChangesTask.result.error && fetchPendingChangesTask.result.changes && !publishChangesTask.started && <>
      <MUIDataTable
        title=""
        data={fetchPendingChangesTask.result.changes.pending}
        columns={[
          ...overviewColumns,
          { 
            name: "Actions",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const rowData = fetchPendingChangesTask.result.changes.pending[tableMeta.rowIndex];
                return (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    width="100%"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setPublishDiffDialogData(rowData);
                      }}>
                        Show
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setRevertDialogData(rowData);
                      }}>
                        Revert
                    </Button>
                  </Box>
                );
              }
            }
          }
        ]}
        options={{
          print: false,
          download: false,
          filter: false,
          selectableRows: "multiple",
          customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (<></>),
          customToolbar,
          onRowSelectionChange
        }}
      />
      <Typography variant="caption">User: {currentUser}</Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="publishMessage">Change Description</InputLabel>
        <Input
          id="publishMessage"
          aria-describedby="publishMessage-helper"
          type="text"
          value={publishMessage}
          onChange={(event) => setPublishMessage(event.target.value)}
        />
        <FormHelperText id="publishMessage-helper">
          Short description describing what has changed and why for the change log.
        </FormHelperText>
      </FormControl>
      <Button variant="contained" disabled={!canPublish} onClick={() => setConfirmationDialogOpen(true)}>
          Publish
      </Button>
    </>
    }
    <Box display={"flex"} alignItems="center" justifyContent="space-between" paddingTop={15}>
      <Typography variant="h4">Published Versions 
        <Tooltip title="Refresh Version Numbers">
          <IconButton aria-label="reload" onClick={refreshPublishedVersions}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Typography>
    </Box>
    {fetchPublishedVersionsTask.started && <>
      <LoadingIndicator />
    </>}
    {!fetchPublishedVersionsTask.pending && fetchPublishedVersionsTask.result && fetchPublishedVersionsTask.result.error && 
      <Alert severity="error" variant="filled">
          {fetchPublishedVersionsTask.result.error}
      </Alert>
    }
    {!fetchPublishedVersionsTask.pending && fetchPublishedVersionsTask.result && !fetchPublishedVersionsTask.result.error && fetchPublishedVersionsTask.result.versions && <>
      {fetchPublishedVersionsTask.result.versions.map(r => {
        return <> {convertGameId(r.gameId) + ": " + r.version} <br/></>;
      })}
    </>}

    <Dialog open={confirmationDialogOpen}>
        <DialogTitle>Publish Confirmation</DialogTitle>
        <DialogContent>
            {currentUserDiaplyName} Are you sure you want to publish <b>{selectedRows.length}</b> posts?<br/>
            With the change description:<br/>
            <Typography variant="caption">{publishMessage}</Typography>
            <Box display={"flex"} justifyContent="space-between" padding={5}>
                <Button variant="contained" color="default" onClick={() => setConfirmationDialogOpen(false)}>Cancel</Button>
                <Button variant="contained" color="secondary" onClick={doPublish}>Publish</Button>
            </Box>
        </DialogContent>
    </Dialog>

    <Dialog open={errorDialogOpen}>
        <DialogTitle>Publish Error</DialogTitle>
        <DialogContent>
            An error has occurred during the publish step:<br/><br/>
            {errorDialogData}
            <Box display={"flex"} justifyContent="space-between" padding={5}>
                <Button variant="contained" color="default" onClick={clearConfirmationDialogsAndRefresh}>Close</Button>
            </Box>
        </DialogContent>
    </Dialog>

    <Dialog open={publishCompleteDialogOpen}>
        <DialogTitle>Publish Completed</DialogTitle>
        <DialogContent>
            Data was published successfully and is now live.
            <Box display={"flex"} justifyContent="space-between" padding={5}>
                <Button variant="contained" color="default" onClick={clearConfirmationDialogsAndRefresh}>Close</Button>
            </Box>
        </DialogContent>
    </Dialog>

    <ShowDiffDialog 
      onClose={() => setPublishDiffDialogData(undefined)}
      entry={publishDiffDialogData}
    />
    <RevertDialog 
      onCancel={() => setRevertDialogData(undefined)}
      onComplete={() => clearConfirmationDialogsAndRefresh()}
      entry={revertDialogData}
    />
  </>);
};
