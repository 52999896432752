import React, { useState } from 'react';
import { useAsyncTask } from 'react-hooks-async'
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, CircularProgress, IconButton, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from "@material-ui/icons/Close";
import { AdminConfirmationPopupData, AdminConfirmationType, AdminResultPopupData } from './Administration.types';
import { regenerateGameSpecificCollections } from './async/regenerateGameSpecificCollections';
import { clearGameSpecificCollections } from './async/clearGameSpecificCollections';
import { useFirebaseProvider } from 'Providers/FirebaseProvider';
import { revertToPublishedState } from './async/revertToPublishedState';
import { regenerateAllGameVersions } from './async/regenerateAllGameVersions';

export const Administration = () => {
    const { functions } = useFirebaseProvider();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState<AdminConfirmationPopupData | undefined>();

    const [resultDialogOpen, setResultDialogOpen] = useState(false);
    const [resultDialogContent, setResultDialogContent] = useState<AdminResultPopupData | undefined>();

    const regenerateTask = useAsyncTask(regenerateGameSpecificCollections);
    const clearTask = useAsyncTask(clearGameSpecificCollections);
    const revertToPublishedStateTask = useAsyncTask(revertToPublishedState);
    const regenerateAllGameVersionsTask = useAsyncTask(regenerateAllGameVersions);

    const openDialog = (data:AdminConfirmationPopupData) => {
        setDialogContent(data);
        setDialogOpen(true);
    };
    const closeDialog = () => {
        setDialogOpen(false);
        setDialogContent(undefined);
    };

    const openResultDialog = (data:AdminResultPopupData) => {
        setResultDialogContent(data);
        setResultDialogOpen(true);
    };

    const closeResultDialog = () => {
        setResultDialogOpen(false);
        setResultDialogContent(undefined);
    };

    const performAction = async () => {
        let result:any;
        switch (dialogContent?.action) {
            case AdminConfirmationType.RegenerateGameCollections:
                result = await regenerateTask.start(functions);
                break;
            case AdminConfirmationType.ClearGameCollections:
                result = await clearTask.start(functions);
                break;
            case AdminConfirmationType.RevertToPublishedState:
                result = await revertToPublishedStateTask.start(functions);
                break;
            case AdminConfirmationType.RegenerateAllGameVersions:
                result = await regenerateAllGameVersionsTask.start(functions);
                break;
            default:
                let errorMessage = "Unsupported Action: " + dialogContent?.action;
                console.error(errorMessage);
                result = {
                    success: false,
                    message: errorMessage
                };
                break;
        }
        closeDialog();

        let title:string;
        if (result?.data?.success) {
            title = "Success";
        } else {
            title = "Fail";
        }
        openResultDialog({
            title,
            message: <pre>{JSON.stringify(result, null, 2)}</pre>
        });
    };
    
    const regenerateData:AdminConfirmationPopupData = {
        message: (
            <>
                This will delete and recreate <b>all game specific collections</b>. <br/>
                <Typography color="error">This could result in everyone who is online re-downloading everything.</Typography>
            </>
        ),
        buttonName: "Recreate Collections",
        action: AdminConfirmationType.RegenerateGameCollections
    };
    const clearData:AdminConfirmationPopupData = {
        message: (
            <>
                This will delete all game specific collections. <br />
                <Typography color="error">This could break clients!</Typography>
            </>
        ),
        buttonName: "Delete All",
        action: AdminConfirmationType.ClearGameCollections
    };
    
    const revertToPublishedStateData:AdminConfirmationPopupData = {
        message: (
            <>
                This will revert ALL edits and reset back to only what's currently published. <br />
            </>
        ),
        buttonName: "Full Revert",
        action: AdminConfirmationType.RevertToPublishedState
    };

    const regenerateAllGameVersionsStateData:AdminConfirmationPopupData = {
        message: (
            <>
                This will re-generate ALL game files and update all configurations. <br />
                <Typography color="error">This will cause all posts to be invalidated and redownloaded by all clients</Typography>
            </>
        ),
        buttonName: "Force Regenerate",
        action: AdminConfirmationType.RegenerateAllGameVersions
    };

    const isLoading = (regenerateTask.started && regenerateTask.pending) || 
                        (clearTask.started && clearTask.pending) || 
                        (revertToPublishedStateTask.started && revertToPublishedStateTask.pending) ||
                        (regenerateAllGameVersionsTask.started && regenerateAllGameVersionsTask.pending);
    return (
        <>
            <Box display={"flex"} alignItems="center" justifyContent="space-between" paddingBottom={2}>
                <Typography variant="h3">Administration</Typography>
            </Box>
            <Alert severity="warning" variant="filled">
                WARNING: <b>These controls are <u>dangerous</u>!!</b> <br/> 
                Don't touch anything in here unless you know what you are doing!!
            </Alert>
            <br />
            <br />
            <Button variant="contained" color="secondary" onClick={() => openDialog(regenerateData)}>Regenerate Game Specific Collections</Button>
            <br />
            <br />
            <Button variant="contained" color="secondary" onClick={() => openDialog(clearData)}>Clear all Game Specific Collections</Button>
            <br />
            <br />
            <Button variant="contained" color="secondary" onClick={() => openDialog(revertToPublishedStateData)}>Revert all edits</Button>
            <br />
            <br />
            <Button variant="contained" color="secondary" onClick={() => openDialog(regenerateAllGameVersionsStateData)}>Regenerate All Game Files and Configuration</Button>
            
            <Dialog open={dialogOpen}>
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="flex-start">
                        <Grid item>
                            <Typography variant="h3">WARNING</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="close" onClick={closeDialog}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogTitle>WARNING</DialogTitle>
                <DialogContent>
                    {isLoading && <>
                        <Box display={"flex"} justifyContent="space-between" padding={5}>
                            <Typography variant="h3">Loading...</Typography>
                        </Box>
                        <Box display={"flex"} justifyContent="space-between" padding={5}>
                            <CircularProgress />
                        </Box>
                    </>}
                    {!isLoading && <>
                        {dialogContent?.message}
                        <Box display={"flex"} justifyContent="space-between" padding={5}>
                            <Button variant="contained" color="secondary" onClick={performAction}>{dialogContent?.buttonName}</Button>
                            <Button variant="contained" color="default" onClick={closeDialog}>Close</Button>
                        </Box>
                    </>}
                </DialogContent>
            </Dialog>
            <Dialog open={resultDialogOpen}>
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="flex-start">
                        <Grid item>
                            <Typography variant="h3">{resultDialogContent?.title}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="close" onClick={closeResultDialog}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {resultDialogContent?.message}
                    <Box display={"flex"} justifyContent="space-between" padding={5}>
                        <Button variant="contained" color="default" onClick={closeResultDialog}>Close</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};