import { FirestoreCollection } from '@react-firebase/firestore';
import { connectionIntent, ParsedConnectedDeployment } from 'Domains/Settings/views/ConnectedDeployments/ConnectedDeployments.types';
import React, { FunctionComponent } from 'react'
import { ConnectedDeploymentsProviderContext } from './ConnectedDeploymentsProvider.context';

export const ConnectedDeploymentsProvider: FunctionComponent = ({ children }) => {
    return (
        <FirestoreCollection path="/connected-deployments/">
            {({ isLoading: loading, value, ids }) => {
                const deploys = (value as ParsedConnectedDeployment[])?.map((v, i) => { v.id = ids[i]; return v })
                return (
                    <ConnectedDeploymentsProviderContext.Provider
                        value={{
                            loading,
                            demotionConnections: deploys?.filter(v => v.connectionIntent === connectionIntent.Demote) ?? [],
                            promotionConnections: deploys?.filter(v => v.connectionIntent === connectionIntent.Promote) ?? [],
                        }}
                    >
                        {children}
                    </ConnectedDeploymentsProviderContext.Provider>
                );
            }}
        </FirestoreCollection>
    )
}

export default ConnectedDeploymentsProvider
