import { NOOP } from "@halfbrick/network-effect-web-shared";
import { DateToISO } from "../RichInbox.utilities";
import { functions as fn, auth } from "firebase";
import {
  richInboxPostToPost,
  RichInboxPostType,
} from "../components/RichInboxProvider";
import { add } from "date-fns";

export const createPost = async (
  functions: fn.Functions,
  richinboxPost: RichInboxPostType,
) => {
  try {
    const parsedPost = richInboxPostToPost(richinboxPost);
    
    const currentUser = auth().currentUser!.email

    const createDate = DateToISO(new Date());
    const publishPost = {
      ...parsedPost,
      publishing: {
        ...parsedPost.publishing,
        publishDateTime: DateToISO(
          parsedPost.publishing.publishDateTime ?? new Date()
        ),
        expiry: DateToISO(
          parsedPost.publishing.expiry ?? add(new Date(), { days: 365 })
        ),
      },
      createdOn: createDate,
      lastUpdated: createDate,
      createdBy: currentUser
    };

    const tasks:Promise<void>[] = [];

    // we persist the tags of this post so we can suggest them next time
    const addTags = functions.httpsCallable("addTags");
    tasks.push(addTags({ tags: publishPost.tags }).then(NOOP));

    // we persist the sentiment of this post so we can suggest them next time
    const addSentiment = functions.httpsCallable("addSentiment");
    tasks.push(addSentiment({ sentiment: publishPost.sentiment }).then(NOOP));

    // now we actually call the update function
    const create = functions.httpsCallable("addNewPost");
    tasks.push(create(publishPost).then(NOOP));

    await Promise.all(tasks);
  } catch (error) {
    console.log("[createPost]: An error occured fetching the posts", error);
  }
};
