import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  coloredRed: {
    color: theme.palette.error.main,
  },
  editorWrapper: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    height: "30rem",
    overflowY: "auto",
    position: "relative",
  },
  editorComponent: {
    height: "auto",
    padding: theme.spacing(2),
  },
}));
