import { AllowedPostPlacements } from "Domains/RichInbox/components/RichInboxProvider";
import { ValidPlacementType } from "./ManagePlacementType.types";
import interstitialImage from "Media/interstitial.jpg";
import { Typography } from "@material-ui/core";
import inboxImage from "Media/Inbox.jpg";
// import feedImage from "Media/Feed.jpg";
import React from "react";

export const placementOptions = [
  {
    id: "interstitial" as ValidPlacementType,
    placement: AllowedPostPlacements.Interstitial,
    image: interstitialImage,
    description: (
      <>
        <Typography variant="h6" paragraph>
          MORTAR GAMES ONLY!
        </Typography>
        <Typography variant="body2" paragraph>
          Post is displayed in the format of an interstitial ad, the users are
          forced to view this.
        <br />
        this does not trigger an OS notification
      </Typography>
      </>
    ),
  },
  {
    id: "inbox" as ValidPlacementType,
    placement: AllowedPostPlacements.Inbox,
    image: inboxImage,
    description: (
      <Typography variant="body2" paragraph>
        Post is displayed as a player inbox message.
        <br />
        this can be used to trigger an OS notification
      </Typography>
    ),
  },
  // {
  //   id: "news" as ValidPlacementType,
  //   placement: AllowedPostPlacements.News,
  //   image: feedImage,
  //   description: (
  //     <Typography variant="body2" paragraph>
  //       Post is displayed in a feed of posts filtered and arranged at the
  //       discretion of the game.
  //       <br />
  //       this does not trigger an OS notification
  //     </Typography>
  //   ),
  // },
];
