import { ESRBRating } from "@halfbrick/network-effect-web-shared";
import { GameFormState } from "./GameForm.types";

export const fieldDescriptions = {
  titleID: {
    label: "Title ID",
    helperText: "The playfab titleID or Firebase project name",
  },
  APIKey: {
    label: "API Key",
    helperText: "The playfab API Key from the game manager",
  },
  enabled: {
    label: "SDK Enabled",
    helperText: "Enable the Network Effects SDK",
  },
  unity: {
    label: "Enable Inline Previewer",
    helperText: "Enable the inline previewer for this game?",
  },
  rating: {
    label: "ESRB Rating",
    helperText: "the game's ESRB Rating",
  },
  cloudProvider: {
    label: "Cloud Provider",
    helperText: "The cloud provider for this game",
  },
  serviceAccount: {
    label: "Service Account",
    helperText: "Stringified JSON service account file",
  },
  publishToGameRemoteConfigKey: {
    label: "Publish to Game Side Remote Config Key",
    helperText: "If 'Publish To Game Side Remote Config' is enabled, this will be the key we write to. If empty it will use the default key"
  },
  publishToGameRemoteConfig: {
    label: "Publish To Game Side Remote Config",
    helperText: "When we publish, should we also write to the remote config used in the service account? (WARNING: This will cause the remote config on the attached service account to be updated when posts are published, make sure you have the service account for the right environment, E.g. DO NOT enable this option if this is dev/staging/qa network effects and the service account is for the game's production firebase instance)"
  },
  displayName: {
    label: "Display Name",
    helperText: "The display Name of this Game",
  },
  logoUrl: { label: "Logo URL", helperText: "URL of the Logo for this Game" },
};

export const cloudProviders = ["playfab", "firebase"];

export const defaultValues: GameFormState = {
  titleID: "",
  APIKey: "",
  enabled: false,
  unity: false,
  cloudProvider: cloudProviders[0],
  displayName: "",
  logoUrl: "",
  rating: ESRBRating.E,
  serviceAccount: "",
  publishToGameRemoteConfigKey: "",
  publishToGameRemoteConfig: false,
};
