import { GameListingResponse, GameItem } from "@halfbrick/network-effect-web-shared";
import { functions as fn } from "firebase";

export const fetchGames = async (_: any, functions: fn.Functions):Promise<GameItem[]> => {
  try {
    const getCall = functions.httpsCallable("fetchGames");
    var data = await getCall();
    if (data === undefined || data === null || data.data === undefined || data.data === null) {
      console.log("Error. No listing returned from call");
      return [];
    }

    const apiResponse:GameListingResponse = data.data;

    return apiResponse.games;
  } catch (error) {
    console.log("error: ", error);
    return [];
  }
};

export const fetchAvailableGames = async (arg1:any, functions: fn.Functions):Promise<GameItem[]> => {
  const games = await fetchGames(arg1, functions);
  return games.filter((game) => game.enabled);
};
