import { RichInboxProviderContext } from "./RichInboxProvider.context";
import { RichInboxProviderProps } from "./RichInboxProvider.types";
import { observer, useLocalObservable } from "mobx-react-lite";
import { createStore } from "./RichInboxProvider.store";
import React, { ComponentType } from "react";

export const RichInboxProvider: ComponentType<RichInboxProviderProps> = observer(
  ({ post, children }) => {
    const store = useLocalObservable(() => createStore(post));

    return (
      <RichInboxProviderContext.Provider value={store}>
        {children}
      </RichInboxProviderContext.Provider>
    );
  }
);
