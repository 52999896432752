import React, { ComponentType, Fragment, useState } from "react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { PreviewTagsProps } from "./PreviewTags.types";
import { observer } from "mobx-react-lite";
import {
  ListItemText,
  Collapse,
  ListItem,
  Chip,
  Grid,
  Box,
} from "@material-ui/core";

export const PreviewTags: ComponentType<PreviewTagsProps> = observer(
  ({ tags }) => {
    const [open, setOpen] = useState(false);

    return (
      <Fragment>
        <ListItem
          disableGutters
          dense
          //button={Boolean(tags.length)}
          onClick={() => setOpen((prevProps) => !prevProps)}
        >
          <ListItemText
            primary={`${tags.length} Tag${tags.length !== 1 ? "s" : ""}`}
          />
          {Boolean(tags.length) && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {Boolean(tags.length) && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginY={1}>
              <Grid container spacing={1}>
                {tags.map((name, key) => {
                  return (
                    <Grid key={key} item>
                      <Chip label={name} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Collapse>
        )}
      </Fragment>
    );
  }
);
