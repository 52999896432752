import React from 'react'
import { MUIDataTableColumnDef, MUIDataTableMeta } from "mui-datatables";
import { isBefore, isAfter } from "date-fns";
import { PublishedStateConfig } from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import { Chip, Grid } from "@material-ui/core";
import { GameIconChip } from 'Components/GameIconChip/GameIconChip';
import { GameItem } from '@halfbrick/network-effect-web-shared';
import { FetchPendingChangesResult } from 'Domains/Publisher/Publisher.types';

export const overviewColumns = (
  values,
  Games: GameItem[],
  pendingChanges: FetchPendingChangesResult
): MUIDataTableColumnDef[] => [
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
      },
    },
    {
      name: "placementType",
      label: "Placement",
      options: {
        filter: false,
        display: "excluded",
      },
    },
    {
      name: "games",
      label: "Games",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          renderValue: (value: string) => {
            return Games.find(g => g.titleID === value)?.displayName ?? "tester"
          }
        },
        customFilterListRender: (value: any) => {
          return Games.find(g => g.titleID === value)?.displayName ?? "tester"
        },
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <Grid container spacing={1} alignItems="center">
              {values[dataIndex].games.map((titleID) => {
                return (
                  <Grid item>
                    <GameIconChip titleID={titleID} />
                  </Grid>
                )
              })}
            </Grid>
          )
        },
        display: true,
      },
    },
    {
      name: "publishing",
      label: "Status",
      options: {
        display: true,
        filter: true,
        customBodyRender: (value: PublishedStateConfig, tableMeta:MUIDataTableMeta) => {
          const documentId = tableMeta.rowData[tableMeta.rowData.length-1];
          if (pendingChanges.changes) {
            if (!documentId) {
              return "Unpublished New Post";
            }
            const pendingPost = pendingChanges.changes.pending.find((item) => item.postId === documentId);
            if (pendingPost) {
              return "Unpublished Changes";
            }
            if (!value.isDraft && pendingChanges.changes.publishedPostIds.indexOf(documentId) < 0) {
              return "Unpublished Post";
            }
          }
          if (value.isDraft) {
            return "Draft";
          } else if (
            value.isScheduled &&
            isBefore(new Date(), value.publishDateTime)
          ) {
            return "Scheduled";
          } else if (!isBefore(new Date(), value.publishDateTime)) {
            return "Live";
          } else if (isAfter(new Date(), value.expiry)) {
            return "Expired";
          } else {
            return "Invalid";
          }
        },
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        display: true,
        filter: false,
      },
    },
    {
      name: "isAdvertisement",
      label: "Advertisement",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value: boolean | undefined) => {
          return Boolean(value) ? "Yes" : "No";
        }
      },
    },
    {
      name: "createdOn",
      label: "Created On",
      options: {
        display: true,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return new Date(Date.parse(value)).toLocaleString("en-AU");
          } else {
            return ""
          }
        },
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: "lastUpdated",
      label: "Updated",
      options: {
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return new Date(Date.parse(value)).toLocaleString("en-AU");
          } else {
            return ""
          }
        },
      },
    },
    {
      name: "tags",
      label: "Tags",
      options: {
        filter: true,
        filterType: "multiselect",
        display: false,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <Grid container spacing={1} alignItems="center">
              {values[dataIndex].tags.map((tag, i) => {
                return (
                  <Grid key={i} item>
                    <Chip size="small" label={tag} />
                  </Grid>
                )
              })}
            </Grid>
          )
        }
      },
    },
    // this MUST be the last item in the array
    {
      name: "documentId",
      label: "ID",
      options: {
        filter: false,
        display: "excluded",
      },
    },
  ];
