export const overviewColumns = [
    { name: "name", label: "Name" },
    { name: "state", label: "Change Type" },
    {
      name: "documentId",
      label: "ID",
      options: {
        display: false,
      },
    },
  ];
  