import { richInboxPostToPost, useRichInboxProvider } from "../RichInboxProvider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Chip, FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import { MortarJSONPreviewerBoxProps } from "./MortarJSONPreviewerBox.types";
import { Assignment, AssignmentTurnedIn } from "@material-ui/icons";
import { RichTextEditor } from "Components/RichTextEditor";
import { convertToRaw, EditorState } from "draft-js";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { convertIEFTtoName } from "../ManageLocalisation/ManageLocalisation.const";

export const MortarJSONPreviewerBox = observer(({ activeTitleForPreview }: MortarJSONPreviewerBoxProps) => {
    const store = useRichInboxProvider();

    const defaultLanguage:string = useMemo(() => {
        const englishDefault = convertIEFTtoName["en"];
        if (store.post.localisation.translations.length > 0 && !store.post.localisation.translations.find((translation) => translation.name === englishDefault)) {
            return store.post.localisation.translations[0].name;
        }
        return englishDefault;
    }, [store]);

    const [isCopied, setIsCopied] = useState(false)
    const [activeTranslation, setActiveTranslation] = useState(defaultLanguage);
    const [showJSON, setShowJSON] = useState(false)

    const copyPostJSONtoClipboard = useCallback(
        async () => {
            await navigator.clipboard.writeText(JSON.stringify(store.post, null, 2))
            setIsCopied(true)
        },
        [store.post],
    )

    const currentContent = useMemo(() => {
        const item = store.post.localisation.translations.find(({ name }) => name === activeTranslation);
        if (item?.content) return item.content;
        return convertToRaw(EditorState.createEmpty().getCurrentContent());
    }, [store.post.localisation.translations, activeTranslation]);

    const currentSubject = useMemo(() => {
        const item = store.post.localisation.translations.find(({ name }) => name === activeTranslation);
        if (item?.subject) return item.subject;
        return "";
    }, [store.post.localisation.translations, activeTranslation]);


    useEffect(() => {
        var timeout;
        if (isCopied) {
            timeout = setTimeout(function () { setIsCopied(false) }, 1500);
        }
        return () => {
            clearTimeout(timeout);
        }
    }, [isCopied, setIsCopied])

    return <>
        <Grid container justify="space-between">
            <Grid item>
                <Typography variant="h5" paragraph>Post Previewer</Typography>
            </Grid>
            <Grid item >
                <FormControlLabel
                    control={
                        <Switch
                            checked={showJSON}
                            onChange={() => setShowJSON((prev) => !prev)}
                            name="json-view"
                            color="primary"
                        />
                    }
                    label="JSON View"
                />
                <Button
                    onClick={copyPostJSONtoClipboard}
                    variant="contained"
                    color={isCopied ? "primary" : "default"}
                    endIcon={isCopied ? <AssignmentTurnedIn /> : <Assignment />}
                >{isCopied ? "JSON is Copied!" : "Copy JSON to Clipboard"}</Button>
            </Grid>
        </Grid>
        {showJSON && (<>
            <Typography paragraph>Copy the below JSON into the richinbox previewing system for {activeTitleForPreview}</Typography>
            <div style={{
                maxWidth: "100%",
                overflowX: "auto"
            }}>
                <pre style={{
                    overflowX: "auto",
                    whiteSpace: "pre-wrap"
                }}>{JSON.stringify(richInboxPostToPost(toJS(store.post)), null, 2)}</pre>
            </div>
        </>)}
        {!showJSON && (<>
            <Typography paragraph>The below preview is indicative only and will not look like this on device</Typography>
            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                marginTop={1}
                marginBottom={1}
            >
                <Grid container spacing={2} alignItems={"center"}>
                    {store.post.localisation.languages?.map((language, index) => (
                        <Grid key={index} item>
                            <Chip
                                color={
                                    activeTranslation === language?.name ? "primary" : "default"
                                }
                                label={language?.name}
                                onClick={() => setActiveTranslation(language?.name ?? "English")}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <style>
                {`
                #previewer #mui-rte-toolbar{
                    display: none;
                }
                `}
            </style>
            {currentSubject.length > 0 && (<>
                <Typography variant="h5" paragraph>Post Subject:</Typography>
                <Typography paragraph>{currentSubject}</Typography>
            </>)}
            <Typography variant="h5" paragraph>Post Body:</Typography>
            <div id="previewer">
                <RichTextEditor
                    isModal={false}
                    onSave={() => { }}
                    readOnly={true}
                    defaultValue={currentContent}
                />
            </div>
        </>)}

    </>
});
