import { createMuiTheme, colors } from "@material-ui/core";

export const defaultTheme = createMuiTheme({
  props: {
    MuiFormControl: {
      fullWidth: true,
      margin: "normal",
    },
  },
  palette: {
    type: "dark",
    primary: colors.purple,
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});
