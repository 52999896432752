
export const DateToISO = (date: Date) => {
  return date.toISOString();
};

export const ISOToDate = (timestamp: string) => {
  return new Date(Date.parse(timestamp));
};

export const EnsureRawJSONPostISOIsDate = (rawPostJSON: any) => {
  if (rawPostJSON && rawPostJSON?.publishing?.publishDateTime) {
    const parsedTime = ISOToDate(rawPostJSON?.publishing?.publishDateTime);
    const parsedExpiry = ISOToDate(rawPostJSON?.publishing?.expiry);
    return {
      ...rawPostJSON,
      publishing: {
        ...rawPostJSON.publishing,
        publishDateTime: parsedTime,
        expiry: parsedExpiry,
      },
    };
  }
};
