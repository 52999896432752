import { firestore as fbFirestore } from "firebase";

export const updateSettings = async (
  _: any,
  firestore: fbFirestore.Firestore,
  data: any
) => {
  try {
    const settingsRef = firestore
      .collection("settings")
      .doc("application-settings");
    await settingsRef.set(data, { merge: true });
  } catch (error) {
    console.log("error: ", error);
  }
};
