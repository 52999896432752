import React from 'react'
import { Palette } from "@material-ui/icons"
import { BlockPicker } from 'react-color';
import { makeStyles } from '@material-ui/core';

export type ColorPickerProps = {
  expanded: boolean,
  onExpandEvent: (any) => void,
  onChange: (...any) => void,
  currentState: any,
}

const useStyles = makeStyles(() => {
  return {
    "demo-color-wrapper": {
      display: "flex",
      alignItems: "center",
      marginBottom: "6px",
      position: "relative",
    },
    "rdw-color-picker-portal": {
      position: "absolute",
      right: "-65px",
      top: "135%",
      zIndex: 10,
    }

  }
})


export const ColorPicker = ({ expanded, currentState, onChange, onExpandEvent }: ColorPickerProps) => {
  const classes = useStyles()

  const handleChange = (color) => {
    onChange('color', color.hex);
  }


  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const renderModal = () => {
    const { color } = currentState;
    return (
      <div
        className={classes['rdw-color-picker-portal']}
        onClick={stopPropagation}
      >
        <BlockPicker color={color} onChangeComplete={handleChange} />
      </div>
    );
  };

  return (
    <div
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
      className={classes['demo-color-wrapper']}
    >
      <div
        className="rdw-option-wrapper"
        onClick={onExpandEvent}
      >
        <Palette />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  );

}