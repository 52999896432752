import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'
import { ConfirmButtonProps } from './ConfirmButton.types';


export const ConfirmButton = ({
    onConfirm,
    confirmButtonText,
    children,
    confirmTitle,
    confirmMessage,
    className,
    disabled = false
}: ConfirmButtonProps) => {
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
    return (
        <>
            <Button disabled={disabled} className={className} onClick={() => { setConfirmationDialogOpen(true) }} variant="contained" color="primary" >{children}</Button>
            <Dialog
                open={confirmationDialogOpen}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>{confirmTitle || "Confirm"}
                </DialogTitle>
                <DialogContent>
                    {confirmMessage || <DialogContentText>Are you sure you want to do this?</DialogContentText>}
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => setConfirmationDialogOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={async () => {
                                setConfirmationDialogOpen(false)
                                onConfirm();
                            }}
                        >
                            {confirmButtonText || "Yes"}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}
