import { useRichInboxProvider } from "Domains/RichInbox/components/RichInboxProvider";
import { CountriesFilterMode, LanguageFilterFilterMode } from "Domains/RichInbox/components/RichInboxProvider/RichInboxProvider.types";
import { Language } from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import React, { ComponentType, Fragment, useCallback, useState } from "react";
import { ManageLocalisationProps } from "./ManageLocalisation.types";
import { languageOptions } from "./ManageLocalisation.const";
import { observer } from "mobx-react-lite";
import { FormAction } from "../FormAction";
import { toJS } from "mobx";
import {
  FormControlLabel,
  Typography,
  Checkbox,
  Divider,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import countriesDB from "countries-db";
import { Autocomplete } from "@material-ui/lab";

export const ManageLocalisation: ComponentType<ManageLocalisationProps> = observer(
  ({ isModal = false, onSubmit }) => {
    const store = useRichInboxProvider();

    const selectCountryMode = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
      store.setCountriesFilterMode(event.target.value as CountriesFilterMode);
      if (event.target.value as CountriesFilterMode === CountriesFilterMode.All) {
        store.setCountriesFilterValues([]);
      }
    }, [store]);

    const [languages, setLanguages] = useState(toJS(store.getLanguages));

    const toggleSelectAll = useCallback(() => {
      if (languages.length !== languageOptions.length) {
        setLanguages(languageOptions);
      } else {
        setLanguages([]);
      }
    }, [languages]);

    const addLanguage = useCallback((selected: Language) => {
      setLanguages((prevProps) => prevProps.concat(selected));
    }, []);

    const removeLanguage = useCallback((selected: Language) => {
      setLanguages((prevProps) =>
        prevProps.filter(({ name }) => name !== selected.name)
      );
    }, []);

    const languageIsSelected = useCallback(
      (selected: Language) => {
        return Boolean(
          languages.find(({ shortName }) => shortName === selected.shortName)
        );
      },
      [languages]
    );

    const submitForm = useCallback(() => {
      store.setLocalisation({ languages });
      onSubmit();
    }, [store, languages, onSubmit]);

    const requiresEnglish = store.post.localisation.languageFilter?.filterMode !== LanguageFilterFilterMode.Translated;

    return (
      <Fragment>
        <Box marginY={2}>
          <Typography variant="h5" paragraph>
            Localisation
          </Typography>
          <Typography variant="h6" paragraph>
            Countries
          </Typography>
          <Typography variant="body1" paragraph>
            Please select the countries you wish to publish this post in
          </Typography>
          <FormControl >
            <InputLabel id="country-availability-mode">Availability Mode</InputLabel>
            <Select
              labelId="country-availability-mode"
              value={store.post.localisation.countries!.filterMode}
              onChange={selectCountryMode}
            >
              <MenuItem value={CountriesFilterMode.All}>All</MenuItem>
              <MenuItem value={CountriesFilterMode.Exclude}>Exclude</MenuItem>
              <MenuItem value={CountriesFilterMode.Include}>Include</MenuItem>
            </Select>
          </FormControl>
          {store.post.localisation.countries!.filterMode !== CountriesFilterMode.All && (
            <Autocomplete
              multiple
              options={Object.values(countriesDB.getAllCountries()).map((c: any) => c.id).filter(c => !store.post.localisation.countries!.filterValues!.includes(c))}
              onChange={(_, value) => {
                store.setCountriesFilterValues(value);
              }}
              defaultValue={store.post.localisation.countries!.filterValues}
              getOptionLabel={(option: any) =>
                countriesDB.getCountry(option, 'name')}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
          <br />
          <br />
          <FormControl >
            <InputLabel id="language-availability-mode">Language Availability Mode</InputLabel>
            <Select
              labelId="language-availability-mode"
              value={store.post.localisation.languageFilter!.filterMode}
              onChange={(event) => store.setLanguageFilterMode(event.target.value as LanguageFilterFilterMode)}
            >
              <MenuItem value={LanguageFilterFilterMode.All}>All (Must have English translation selected)</MenuItem>
              <MenuItem value={LanguageFilterFilterMode.Translated}>Only Languages With Translations</MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          <Typography variant="h6" paragraph>
            Languages
          </Typography>
          <Typography variant="body1" paragraph>
            Please select the languages you wish to publish this post in
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={"all"}
                    checked={languages.length === languageOptions.length}
                    onChange={toggleSelectAll}
                    color="primary"
                  />
                }
                label={`All Languages (${languageOptions.length})`}
              />
              <Divider />
            </Grid>
            {languageOptions.map((option, index) => {
              return (
                <Grid item key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={option.shortName}
                        checked={languageIsSelected(option)}
                        onChange={() => {
                          if (
                            languageIsSelected(option) ||
                            languages.length === languageOptions.length
                          ) {
                            removeLanguage(option);
                          } else {
                            addLanguage(option);
                          }
                        }}
                        color="primary"
                      />
                    }
                    label={option.name}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <FormAction
          disabled={!(Boolean(languages.length > 0)) || (requiresEnglish && !languages.find((lang) => lang.IEFT === "en"))}
          onClick={submitForm}
          isModal={isModal}
        />
      </Fragment>
    );
  }
);
