import React from 'react';
import tinycolor from "tinycolor2"

export default function defaultInlineUnityTags(style) {
  if (style === "BOLD") {
    return <b />;

  } else if (style.includes("color")) {
    const parts = style.split("-")
    const hex = parts[1]
    var color = tinycolor(hex);

    return <stripcolor color={`${color.toHex8String()}`} />;
  } else if (style.includes("size")) {
    const parts = style.split("-")
    const sz = parts[1]
    return <stripsize size={sz} />;
  } else {
    return {
      start: '',
      end: '',
    };

  }
}
