import { useFirebaseProvider } from "Providers/FirebaseProvider/FirebaseProvider.hooks";
import { useRichInboxProvider } from "Domains/RichInbox/components/RichInboxProvider";
import React, { ComponentType, useCallback, useState, useMemo } from "react";
import { Box, Button, Card, CardActionArea, Chip, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import InfoIcon from '@material-ui/icons/Info';
import { ManageContentProps } from "./ManageContent.types";
import { RichTextEditor } from "Components/RichTextEditor";
import { observer } from "mobx-react-lite";
import { FormAction } from "../FormAction";
import { Alert } from "@material-ui/lab";
import "draft-js/dist/Draft.css";
import { toJS } from "mobx";
import { ESRBRating, Ratings } from "@halfbrick/network-effect-web-shared";
import { useStyles } from "./ManageContent.styles";
import { Info } from "@material-ui/icons";
import { convertIEFTtoName } from "../ManageLocalisation/ManageLocalisation.const";

export const ManageContent: ComponentType<ManageContentProps> = observer(
  ({ onSubmit, isModal = false }) => {
    const classes = useStyles();

    const { functions } = useFirebaseProvider();
    const store = useRichInboxProvider();

    const defaultLanguage:string = useMemo(() => {
      if (store.post.localisation.languages && 
          store.post.localisation.languages.length > 0 && 
          !store.post.localisation.languages.find((loc) => loc.IEFT === "en")) {
        return store.post.localisation.languages[0].name;
      }
      return convertIEFTtoName["en"];
  }, [store]);
  
    const [activeTranslation, setActiveTranslation] = useState(defaultLanguage);
    const [translating, setTranslating] = useState(false);

    const [translations, setTranslations] = useState(
      toJS(store.getTranslations)
    );

    const currentContent = useMemo(() => {
      const item = translations.find(({ name }) => name === activeTranslation);
      if (item?.content) return item.content;
      return convertToRaw(EditorState.createEmpty().getCurrentContent());
    }, [translations, activeTranslation]);

    const currentSubject = useMemo(() => {
      const item = translations.find(({ name }) => name === activeTranslation);
      if (item?.subject) return item.subject;
      return "";
    }, [translations, activeTranslation]);


    const remainingTranslations = useMemo(() => {
      const content = translations.filter(({ content }) =>
        EditorState.createWithContent(convertFromRaw(content))
          .getCurrentContent()
          .hasText()
      );
      return store.getLanguages.length - content.length;
    }, [store.getLanguages, translations]);

    const processTranslation = useCallback(async () => {
      setTranslating(true);
      try {
        const translate = functions.httpsCallable("translatePostContentText");

        const targetTranslation = translations.find(
          ({ name }) => name === "English"
        );

        const targetLanguage = store.getLanguages.find(
          (language) => language.name === activeTranslation
        );

        if (targetLanguage && targetTranslation) {
          const { data: { blocks, entityMap } } = await translate({
            blocks: targetTranslation.content.blocks ?? [],
            entityMap: targetTranslation.content.entityMap ?? [],
            target: toJS(targetLanguage.ISO6391),
          });

          

          setTranslations((prevProps) =>
            prevProps
              .filter(({ name }) => name !== activeTranslation)
              .concat({
                name: activeTranslation,
                subject: currentSubject,
                content: {
                  blocks: blocks.map((b) => {
                    b.inlineStyleRanges = [];
                    return b;
                  }),
                  entityMap,
                },
              })
          );
        }
      } catch (error) {
        console.log(
          `[InterstitialContentEditor Error]: ${JSON.stringify(error)}`
        );
      } finally {
        setTranslating(false);
      }
    }, [store, activeTranslation, functions, translations, currentSubject]);

    const handleContentSave = useCallback(
      (data: RawDraftContentState) => {
        setTranslations((prevProps) =>
          prevProps
            .filter(({ name }) => name !== activeTranslation)
            .concat({
              name: activeTranslation,
              subject: currentSubject,
              content: data,
            })
        );
      },
      [activeTranslation, currentSubject]
    );

    const handleSubjectChange = useCallback(
      (event: any) => {
        event.persist();
        console.log(event)
        setTranslations((prevProps) =>
          prevProps
            .filter(({ name }) => name !== activeTranslation)
            .concat({
              name: activeTranslation,
              content: currentContent,
              subject: event?.target?.value,
            })
        );
      },
      [activeTranslation, currentContent]
    );


    const submitForm = useCallback(() => {
      store.setLocalisation({ translations });
      onSubmit();
    }, [store, translations, onSubmit]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" paragraph>
            Post Content
          </Typography>
          <Typography variant="h6" paragraph>
            Content Rating:
          </Typography>
          <Typography variant="body1" paragraph>
            The post content requires an estimated rating to ensure that we do not violate the advertising policies in place. please choose one from the following list:
          </Typography>
          <Grid container spacing={3}>
            {Array.from(Ratings, ([key, value]) => (
              <Grid item xs>
                <Card variant="outlined" className={store.post.rating === key ? classes.cardSelected : ""}>
                  <CardActionArea
                    onClick={() => store.setContentRating(key as ESRBRating)}
                  >
                    <Box padding={2}>
                      <Grid container justify="space-between" alignItems="center" >
                        <Grid item>
                          <Typography variant="body1">
                            {value.RatingKey} - {value.RatingName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Tooltip title={value.Description} arrow>
                            <InfoIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          {remainingTranslations > 0 && (
            <Box paddingY={2}>
              <Alert severity={"warning"} variant={"filled"}>
                {remainingTranslations} Pending translations! Please add these
                translations in order to complete this post
              </Alert>
            </Box>
          )}
          <Box
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            marginBottom="1rem"
            marginTop="1rem"
          >
            <Typography variant="h6">Translations:</Typography>
            <Button
              variant="contained"
              disabled={activeTranslation === "English"}
              onClick={processTranslation}
            >
              Auto Translate Post Body
            </Button>
          </Box>
          <Grid container spacing={2} alignItems={"center"}>
            {store.getLanguages.map((language, index) => (
              <Grid key={index} item>
                <Chip
                  color={
                    activeTranslation === language.name ? "primary" : "default"
                  }
                  label={language?.name}
                  onClick={() =>
                    setActiveTranslation(language.name ?? "English")
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Box paddingY={5}>
            <Box display="flex" alignItems="center" >
              <Typography variant="h6">
                Post Subject
            </Typography>
              <Tooltip title="The this will be displayed as the message subject for your translation in th inbox message list of the Game UI">
                <Box paddingX={2}>
                  <Info />
                </Box>
              </Tooltip>
            </Box>
            <TextField onChange={handleSubjectChange} value={currentSubject} style={{ maxWidth: "60rem" }} fullWidth id="outlined-basic" placeholder="Keep this breif be engaging" variant="outlined" />
            <Box display="flex" alignItems="center" >
              <Typography variant="h6">
                Post Body
            </Typography>
              <Tooltip title="The content that players will consume when they interact with your
              post, typicaly this is some text and images, in some cases you may
              want to link users out to another service, this can be done by
              adding a 'Button Link' element">
                <Box paddingX={2}>
                  <Info />
                </Box>
              </Tooltip>
            </Box>
            <RichTextEditor
              isModal={isModal}
              onSave={handleContentSave}
              readOnly={translating}
              defaultValue={currentContent}
            />
          </Box>
        </Grid>
        <FormAction
          disabled={remainingTranslations > 0}
          onClick={submitForm}
          isModal={isModal}
        />
      </Grid>
    );
  }
);
