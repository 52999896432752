import { functions as fn } from "firebase";

export const regenerateGameSpecificCollections = async (_: any, functions: fn.Functions) => {
  try {
    const getCall = functions.httpsCallable("regenerateGameSpecificCollections");
    return await getCall();
  } catch (error) {
    console.log("[regenerateGameSpecificCollections]: An error occured while re-generating game specific collections", error);
    return {
        error: error.toString() 
    };
  }
};