export const overviewColumns = [
  { name: "displayName", label: "Display Name" },
  { name: "titleID", label: "Title ID" },
  { name: "cloudProvider", label: "Cloud Provider" },
  {
    name: "Id",
    label: "ID",
    options: {
      display: false,
    },
  },
];
