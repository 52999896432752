import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { createPost } from "Domains/RichInbox/async/createPost";
import React, { Fragment, useCallback, useState } from "react";
import { useStyles } from "./RichInboxNewPost.styles";
import { Link, useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import { steps } from "./RichInboxNewPost.const";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import {
  CircularProgress,
  StepContent,
  Typography,
  StepButton,
  Stepper,
  Button,
  Avatar,
  Step,
  Grid,
  Box,
} from "@material-ui/core";
import {
  useRichInboxProvider,
  RichInboxProvider,
  RichInboxPostType,
} from "Domains/RichInbox/components/RichInboxProvider";

const RichInboxNewPostUI = observer(() => {
  const [updating, setUpdating] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isDraft, setIsDraft] = useState(false);
  const [updated, setUpdated] = useState(false);
  const firebase = useFirebaseProvider();
  const store = useRichInboxProvider();
  const history = useHistory();
  const classes = useStyles();

  const saveAsDraft = useCallback(async () => {
    setIsDraft(true);
    setUpdating(true);
    await createPost(firebase.functions, toJS(store.post));
    setUpdating(false);
    setIsDraft(false);
    setUpdated(true);
  }, [
    firebase.functions,
    setUpdating,
    store.post,
    setUpdated,
  ]);

  const handlePublish = useCallback(
    async (post: RichInboxPostType) => {
      setUpdating(true);
      await createPost(
        firebase.functions,
        {
          ...post,
          publishing: { ...post.publishing, isDraft: false },
        });
      setUpdating(false);
      setUpdated(true);
    },
    [firebase.functions, setUpdating, setUpdated]
  );

  if (updating) {
    return (
      <Box className={classes.loadingBox} padding={3}>
        <CircularProgress size={150} />
        <br />
        <Typography variant="h2">
          {isDraft ? "Saving" : "Creating"} Post
        </Typography>
      </Box>
    );
  }

  if (updated) {
    return (
      <Box className={classes.loadingBox} padding={3}>
        <Avatar className={classes.SuccessIconAvator}>
          <CheckIcon className={classes.SuccessIcon} />
        </Avatar>
        <br />
        <Typography variant="h2">Success</Typography>
        <Typography variant="h4" paragraph>
          Your post has been Saved
        </Typography>
        <Button
          variant={"contained"}
          color = "primary"
          onClick={() => {
            history.push(`/publisher`);
          }}
        >
          Go to Publisher
        </Button>
        <br />
        <Button variant={"outlined"} onClick={() => history.push(`/richinbox`)}>
          Back to All Posts
        </Button>
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h2">New Post</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" component={Link} to={`/richinbox`}>
            Back to All Posts
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={store.post.title === undefined}
            variant="contained"
            color="primary"
            onClick={saveAsDraft}
          >
            Save Draft
          </Button>
        </Grid>
      </Grid>
      <Box paddingY={2}>
        <Stepper
          className={classes.stepper}
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map(({ label, Component }, index) => (
            <Step key={label}>
              <StepButton onClick={() => setActiveStep(index)}>
                {label}
              </StepButton>
              <StepContent>
                {steps.length - 1 === index ? (
                  <Component
                    publishPost={handlePublish}
                    onSubmit={() => setActiveStep((prevProps) => prevProps + 1)}
                  />
                ) : (
                  <Component
                    onSubmit={() => setActiveStep((prevProps) => prevProps + 1)}
                  />
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Fragment>
  );
});

export const RichInboxNewPost = () => (
  <RichInboxProvider>
    <RichInboxNewPostUI />
  </RichInboxProvider>
);
