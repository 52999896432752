import { functions as fn } from "firebase";
import { FetchPendingChangesResult } from "../Publisher.types";

export async function fetchPendingChanges(_: any, functions: fn.Functions):Promise<FetchPendingChangesResult> {
  try {
    const getCall = functions.httpsCallable("publisherListChanges");
    var data = await getCall();
    if (data === undefined || data === null || data.data === undefined || data.data === null) {
      return {
          error: "No data returned from call",
          changes: {
            pending: [],
            publishedPostIds: []
          }
      };
    }
    return {
      error: undefined,
      changes: data.data
    };

  } catch (error) {
    console.log("[fetchPendingChanges]: An error has occurred fetching published changes", error);
    return {
        error: error.toString(),
        changes: {
          pending: [],
          publishedPostIds: []
        }
    };
  }
};