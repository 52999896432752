import React, { ComponentType, Fragment, useCallback, useMemo, useState } from "react";
import { ManageGeneralInformation } from "../ManageGeneralInformation";
import { DialogStatePortal } from "Components/DialogStatePortal";
import { ConfirmPublishDialog } from "../ConfirmPublishDialog";
import { LoadingIndicator } from "Components/LoadingIndicator";
import { ManagePlacementType } from "../ManagePlacementType";
import { Typography, Button, Grid, ListItem, ListItemText } from "@material-ui/core";
import { ManageLocalisation } from "../ManageLocalisation";
import { PostPreviewerProps } from "./PostPreviewer.types";
import { useGamesProvider } from "Providers/GamesProvider";
import { ManagePublishing } from "../ManagePublishing";
import { AttributeSection } from "./AttributeSection";
import { PreviewSentiment } from "./PreviewSentiment";
import { PreviewLanguages } from "./PreviewLanuages";
import { PreviewSegments } from "./PreviewSegments";
import { ManageSegments } from "../ManageSegments";
import { PreviewSegment } from "./PreviewSegment";
import { ManageContent } from "../ManageContent";
import { PreviewTags } from "./PreviewTags";
import { observer } from "mobx-react-lite";
import { Alert } from "@material-ui/lab";
import format from "date-fns/format";
import { toJS } from "mobx";
import {
  useRichInboxProvider,
  RichInboxPostType,
  defaultValues,
} from "Domains/RichInbox/components/RichInboxProvider";
import { differenceInDays } from "date-fns/esm";
import { Ratings } from "@halfbrick/network-effect-web-shared";

export const PostPreviewer: ComponentType<PostPreviewerProps> = observer(
  ({ edit = false, publishPost }) => {
    const games = useGamesProvider();
    const store = useRichInboxProvider();

    const [dialogOpen, setDialog] = useState(false);

    if (games.gamesLoading || !games.availableGames) {
      return <LoadingIndicator />;
    }

    const handlePublish = useCallback(
      (post: RichInboxPostType) => {
        if (publishPost) {
          publishPost(toJS(post));
        }
      },
      [publishPost]
    );

    const hasContent = useMemo(() => {
      return store.post.localisation.translations.some(t => t.content.blocks.length > 0);
    }, [store.post.localisation.translations])

    const hasSegments = useMemo(() => {
      return store.post.segments.length > 0;
    }, [store.post.segments])

    const hasContentRating = store.post.rating !== undefined;

    const hasContentRatingIssue = useMemo(() => {
      if (hasContentRating) {
        const storedRating = Ratings.get(store.post.rating!);
        const segmentRaings = store.post.segments.map(s => games!.availableGames.find(g => g.titleID === s.titleID)!.rating).map(key => Ratings.get(key))
        return segmentRaings.some(des => storedRating!.minAge <= des!.minAge);
      }
      // dont show the warning because we have already shown one
      return true;
    }, [store.post.rating, store.post.segments, games, hasContentRating])

    return (
      <Fragment>
        <Grid container spacing={2}>
          {!hasContentRating && (
            <Grid item xs={12}>
              <Alert severity="warning" variant="filled">
                <strong>Warning:</strong> There is no content rating for this post, if it is already live consider recreating it with a rating or applying one.
              </Alert>
            </Grid>
          )}
          {!hasContentRatingIssue && (
            <Grid item xs={12}>
              <Alert severity="warning" variant="filled">
                <strong>Warning:</strong> The content rating for this message is higher than one or more of your destination segments.<br />
                Before publishing this post you should esnure that the destination games have age gating enabled and are applying those filters to the inbox posts when shown.
              </Alert>
            </Grid>
          )}
          {!hasSegments && (
            <Grid item xs={12}>
              <Alert severity="error" variant="filled">
                <strong>Error:</strong> Posts must have at least 1 destination
                segment in order to publish
              </Alert>
            </Grid>
          )}
          {!hasContent && <Grid item xs={12}>
            <Alert severity="error" variant="filled">
              <strong>Error:</strong> Posts must have content in at least 1 translation in order to publish
              </Alert>
          </Grid>
          }
          <Grid item xs={12} md={4}>
            <AttributeSection
              title="Title"
              ValueComponent={
                <Typography variant={"body1"} paragraph>
                  {store.post.title}
                </Typography>
              }
              canEdit={edit}
              EditComponent={
                <DialogStatePortal>
                  {({ closeDialog }) => (
                    <ManageGeneralInformation
                      isModal={true}
                      onSubmit={closeDialog}
                    />
                  )}
                </DialogStatePortal>
              }
            />
            <AttributeSection
              title="Placement Type"
              ValueComponent={
                <Typography variant={"body1"} paragraph>
                  {store.post.placementType}
                </Typography>
              }
              canEdit={edit}
              EditComponent={
                <DialogStatePortal>
                  {({ closeDialog }) => (
                    <ManagePlacementType
                      isModal={true}
                      onSubmit={closeDialog}
                    />
                  )}
                </DialogStatePortal>
              }
            />
            <AttributeSection
              title="Meta"
              ValueComponent={
                <Fragment>
                  <PreviewTags tags={store.post.tags} />
                  <PreviewSentiment sentiment={store.post.sentiment} />
                  <Typography variant={"body1"} paragraph>
                    {(store.post.priority === undefined || store.post.priority === null) ? "No" : store.post.priority} Priority (Optional)
                  </Typography>
                  <Typography variant={"body1"} paragraph>
                    Is Advertisement: {Boolean(store.post.isAdvertisement) ? "Yes" : "No"}
                  </Typography>
                </Fragment>
              }
              canEdit={edit}
              EditComponent={
                <DialogStatePortal>
                  {({ closeDialog }) => (
                    <ManageGeneralInformation
                      isModal={true}
                      onSubmit={closeDialog}
                    />
                  )}
                </DialogStatePortal>
              }
            />
            <AttributeSection
              title="Localisation"
              ValueComponent={
                <PreviewLanguages
                  countries={store.post.localisation?.countries ?? defaultValues.localisation.countries!}
                  languages={store.post.localisation?.languages ?? []}
                  languageFilter={store.post.localisation?.languageFilter ?? defaultValues.localisation.languageFilter}
                />
              }
              canEdit={edit}
              EditComponent={
                <DialogStatePortal>
                  {({ closeDialog }) => (
                    <ManageLocalisation isModal={true} onSubmit={closeDialog} />
                  )}
                </DialogStatePortal>
              }
            />
            {!!store.post.SKUFilter && (<AttributeSection
              title="SKU Filtering"
              ValueComponent={
                <ListItem
                  disableGutters
                  dense
                >
                  <ListItemText>{store.post.SKUFilter} Only</ListItemText>
                </ListItem>
              }
              canEdit={edit}
              EditComponent={
                <DialogStatePortal>
                  {({ closeDialog }) => (
                    <ManageSegments isModal={true} onSubmit={closeDialog} />
                  )}
                </DialogStatePortal>
              }
            />)}
            <AttributeSection
              title="Destination Segments"
              ValueComponent={
                <PreviewSegments
                  segments={store.post.segments}
                  appVersionFilters={store.post.appVersionFilters}
                />
              }
              canEdit={edit}
              EditComponent={
                <DialogStatePortal>
                  {({ closeDialog }) => (
                    <ManageSegments isModal={true} onSubmit={closeDialog} />
                  )}
                </DialogStatePortal>
              }
            />
            <AttributeSection
              title="Publishing"
              ValueComponent={
                <>
                  <Typography variant={"body1"} paragraph>
                    {`This Post will be live on ${format(
                      store.post?.publishing?.publishDateTime ?? new Date(),
                      "dd/MM/yyyy 'at' hh:mm b"
                    )}`}
                  </Typography>
                  <Typography variant={"body1"} paragraph>
                    {`it is due to expire ${differenceInDays(store.post?.publishing?.expiry,
                      store.post?.publishing?.publishDateTime,
                    )} days after it is published`}
                  </Typography>
                </>
              }
              canEdit={edit}
              EditComponent={
                <DialogStatePortal>
                  {({ closeDialog }) => (
                    <ManagePublishing isModal={true} onSubmit={closeDialog} />
                  )}
                </DialogStatePortal>
              }
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <AttributeSection
              title="Post Content"
              ValueComponent={
                <PreviewSegment />
              }
              canEdit={edit}
              EditComponent={
                <DialogStatePortal>
                  {({ closeDialog }) => (
                    <ManageContent isModal={true} onSubmit={closeDialog} />
                  )}
                </DialogStatePortal>
              }
            />
          </Grid>
          {!edit && (
            <Grid item xs={12}>
              <Button
                disabled={!hasContent || !hasSegments}
                onClick={() => setDialog(true)}
                color="primary"
                variant="contained"
              >
                Queue this post for publish
              </Button>
            </Grid>
          )}
        </Grid>
        <ConfirmPublishDialog
          publishPost={() => handlePublish(store.post)}
          open={dialogOpen && !edit}
          onClose={() => setDialog(false)}
        />
      </Fragment>
    );
  }
);
