import { HistoryDiffData, HistoryWithId } from "@halfbrick/network-effect-web-shared";
import { functions as fn } from "firebase";

export const fetchHistoryData = async (_: any, functions: fn.Functions, entry:HistoryWithId):Promise<HistoryDiffData> => {
  try {
    const getCall = functions.httpsCallable("fetchHistoryData");
    var data = await getCall(entry);
    if (data === undefined || data === null || data.data === undefined || data.data === null) {
      return {
        error: "No data returned from API",
        entry,
        before: "",
        after: ""
      };
    }
    const result:HistoryDiffData = data.data;

    if (result.before === null) {
      result.before = undefined;
    }
    if (result.after === null) {
      result.after = undefined;
    }

    return result;
  } catch (error) {
    console.log("error: ", error);

    const errorStr = error.toString();
    return {
      error: errorStr,
      entry,
      before: errorStr,
      after: errorStr
    };
  }
};
