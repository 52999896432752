import { useRichInboxProvider } from "Domains/RichInbox/components/RichInboxProvider";
import { updatePost as update } from "Domains/RichInbox/async/updatePost";
import { deletePost as delPost } from "Domains/RichInbox/async/deletePost";
import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { useStyles } from "../RichInboxPost.styles";
import LaunchIcon from "@material-ui/icons/Launch";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
import { ToolbarProps } from "./Toolbar.types";
import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import isEqual from "lodash.isequal";
import { toJS } from "mobx";
import { PostMigrationControls } from "../PostMigrationControls";

export const Toolbar = observer(
  ({
    originalPost,
    setUpdating,
    setUpdated,
    setLocalPost,
    setDeleted,
    setDeleting,
  }: ToolbarProps) => {
    const firebase = useFirebaseProvider();
    const store = useRichInboxProvider();
    const classes = useStyles();

    const hasContent = useMemo(() => {
      return store.post.localisation.translations.some(t => t.content.blocks.length > 0);
    }, [store.post.localisation.translations])

    const hasSegments = useMemo(() => {
      return store.post.segments.length > 0;
    }, [store.post.segments])


    const [confirmDelete, setConfirmDelete] = useState(false)

    const updatePost = useCallback(async () => {
      try {
        setUpdating(true);
        await update(firebase.functions, toJS(store.post));
        setUpdated(true);
        setLocalPost(store.post);
      } catch (error) {
        console.log("An error occured updating the post: ", error);
      } finally {
        setUpdating(false);
      }
    }, [firebase, setUpdating, setUpdated, store.post, setLocalPost]);

    const deletePost = useCallback(async () => {
      try {
        setDeleting(true);
        await delPost(firebase.functions, toJS(store.post));
        setDeleted(true);
      } catch (error) {
        console.log("An error occured updating the post: ", error);
      } finally {
        setDeleting(false);
      }
    }, [firebase, setDeleting, setDeleted, store.post]);

    return (
      <Grid container spacing={2}>
        {store.post.publishing.isDraft && (
          <Grid item>

            <Button
              color={"primary"}
              variant="contained"
              onClick={() => {
                store.setDraft(false);
                updatePost();
              }}
              disabled={!isEqual(originalPost, toJS(store.post) || !hasContent || !hasSegments)}
            >
              Queue for Publishing
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            color={"primary"}
            variant="contained"
            onClick={updatePost}
            disabled={isEqual(originalPost, toJS(store.post))}
          >
            Save Changes
          </Button>
        </Grid>
        <Grid item>
          <PostMigrationControls />
        </Grid>
        <Grid item>
          <Button
            href={"https://halfbrickdata.au.looker.com/dashboards-next/167?Post+ID=" + store.post.__id}
            target="_blank"
            variant="contained"
            endIcon={<LaunchIcon />}
          >
            View Reports
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.errorBtn}
            endIcon={<DeleteIcon />}
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
          <Dialog
            open={confirmDelete}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>Are you sure you want to delete this post?</DialogContentText>
              <DialogContentText>THIS CANNOT BE UNDONE!</DialogContentText>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => setConfirmDelete(false)}
                >
                  Cancel
              </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => deletePost()}
                >
                  Yes, Delete it!
              </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    );
  }
);
