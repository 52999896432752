import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { DialogStatePortalProps } from "./DialogStatePortal.types";
import React, { Fragment, useState } from "react";
import { Edit } from "@material-ui/icons";

export const DialogStatePortal = ({
  closeOnBackdropClick = true,
  children,
}: DialogStatePortalProps) => {
  const [open, setOpen] = useState(false);

  const closeDialog = () => setOpen(false);

  return (
    <Fragment>
      <IconButton size={"small"} onClick={() => setOpen(true)}>
        <Edit fontSize="small" />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={() => closeOnBackdropClick && setOpen(false)}
      >
        <DialogContent>{children({ closeDialog })}</DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default DialogStatePortal;
