import { GamesProviderContext } from "./GamesProvider.context";
import { useContext } from "react"

export const useGamesProvider = () => {
  const context = useContext(GamesProviderContext);

  if(context === undefined){
    throw Error("useGamesProvider must be used within a GamesProvider");
  }

  return context;
}