import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  WarningIconAvator: {
    backgroundColor: theme.palette.error.dark,
    position: "absolute",
    top: "-75px",
    width: 150,
    height: 150,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: theme.zIndex.modal + 10,
  },
  WarningIcon: {
    fontSize: "4.2875rem",
  },
  paperMods: {
    paddingTop: 100,
    overflow: "initial",
  },
}));
