import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { LoadingIndicator } from 'Components/LoadingIndicator';
import { useFirebaseProvider } from 'Providers/FirebaseProvider';
import React, { useEffect } from 'react';
import { useAsyncTask } from 'react-hooks-async';
import { fetchHistoryData } from './async';
import { HistoryDiffDialogProps } from './ShowDiffDialog.types';
import ReactDiffViewer from 'react-diff-viewer';
import stringify from 'json-stable-stringify';

const stringifyArgs = {
  space: '  '
};

export const ShowDiffDialog = ({ onClose, entry }: HistoryDiffDialogProps) => {
  const firebase = useFirebaseProvider();

  const fetchHistoryDataTask = useAsyncTask(fetchHistoryData);

  useEffect(() => {
    if (entry) {
      fetchHistoryDataTask.start(firebase.functions, entry);
    }
    // disable error that "fetchHistoryDataTask" isn't in dependency list (if its added it causes an infinate loop)
    // eslint-disable-next-line
  }, [entry]);

  return (<Dialog open={entry !== undefined} fullScreen={true}>
    <DialogTitle>
      <Grid container justify="space-between" alignItems="flex-start">
        <Grid item>
          <Typography variant="h3">{entry?.displayName}</Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent>
        {fetchHistoryDataTask.pending && <>
          <LoadingIndicator />
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            width="100%"
          >
            <Typography variant="h3">Loading...</Typography>
          </Box>
        </>}
        <ReactDiffViewer 
          leftTitle={fetchHistoryDataTask.result ? "Before" : undefined}
          rightTitle={fetchHistoryDataTask.result ? "After" : undefined}
          oldValue={stringify(fetchHistoryDataTask.result?.before, stringifyArgs)}
          newValue={stringify(fetchHistoryDataTask.result?.after, stringifyArgs)}
          splitView={true}
          styles={{
            line: {
              color: "grey"
            }
          }}
        />
        <Box display={"flex"} justifyContent="space-between" padding={5}>
            <Button variant="contained" color="default" onClick={onClose}>Close</Button>
        </Box>
    </DialogContent>
  </Dialog>);
};