import { Language } from "@halfbrick/network-effect-schemas/dist/typescript/Post";

// IEFT codes: https://www.venea.net/web/culture_code
export const languageOptions: Language[] = [
  { name: "English", ISO6391: "en", shortName: "en", IEFT: "en" },
  { name: "French", ISO6391: "fr", shortName: "fr", IEFT: "fr" },
  { name: "Italian", ISO6391: "it", shortName: "it", IEFT: "it" },
  { name: "German", ISO6391: "de", shortName: "de", IEFT: "de" },
  { name: "Greek", ISO6391: "el", shortName: "el", IEFT: "el" },
  { name: "Spanish (Spain)", ISO6391: "es", shortName: "es-sp", IEFT: "es-es" },
  { name: "Spanish (Latin America)", ISO6391: "es", shortName: "es-la", IEFT: "es" },
  { name: "Arabic", ISO6391: "ar", shortName: "ar", IEFT: "ar" },
  { name: "Chinese (Simplified)", ISO6391: "zh-Hans", shortName: "zh-Hans", IEFT: "zh-chs" },
  { name: "Chinese (Traditional)", ISO6391: "zh-Hant", shortName: "zh-Hant", IEFT: "zh-cht" },
  { name: "Japanese", ISO6391: "ja", shortName: "ja", IEFT: "ja" },
  { name: "Korean", ISO6391: "ko", shortName: "ko", IEFT: "ko" },
  { name: "Polish", ISO6391: "pl", shortName: "pl", IEFT: "pl" },
  { name: "Portuguese", ISO6391: "pt", shortName: "pt", IEFT: "pt" },
  { name: "Portuguese (Brazil)", ISO6391: "pt", shortName: "pt-bra", IEFT: "pt-br" },
  { name: "Russian", ISO6391: "ru", shortName: "ru", IEFT: "ru" },
  { name: "Turkish", ISO6391: "tr", shortName: "tr", IEFT: "tr" },
  { name: "Thai", ISO6391: "th", shortName: "th", IEFT: "th" },
  { name: "Vietnamese", ISO6391: "vi", shortName: "vi", IEFT: "vi" },
  { name: "Indonesian", ISO6391: "in", shortName: "in", IEFT: "id" },
];

export const convertIEFTtoName:{ [IEFT:string]:string } = {};
languageOptions.forEach((lang) => {
  convertIEFTtoName[lang.IEFT] = lang.name;
});
