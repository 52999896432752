import { Segment } from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import { getGlobalSegmentsFromSegmentList } from "../ManageSegments.utilities";
import { GlobalSelectionCardProps } from "./GlobalSelectionCard.types";
import { useGamesProvider } from "Providers/GamesProvider";
import { useStyles } from "./GlobalSelectionCard.styles";
import AllGamesImage from "Media/AllGames.png";
import React, { useCallback } from "react";
import {
  FormControlLabel,
  CardContent,
  CardHeader,
  Typography,
  CardMedia,
  FormGroup,
  Checkbox,
  colors,
  Card,
} from "@material-ui/core";

export const GlobalSelectionCard = ({
  globalSegments,
  setSegments,
  savedSegments,
}: GlobalSelectionCardProps) => {
  const games = useGamesProvider();
  const classes = useStyles();

  const destinationHasValues = useCallback(
    (label: string) => {
      const globals = getGlobalSegmentsFromSegmentList(
        savedSegments,
        games.availableGames
      );
      return Object.keys(globals).includes(label);
    },
    [savedSegments, games.availableGames]
  );

  const handleChange = useCallback(
    (label: string, values: Segment[]) => {
      if (!destinationHasValues(label)) {
        const added = savedSegments.concat(values);
        setSegments(added);
      } else {
        const pruned = savedSegments.filter(
          (segment) => segment.Name !== label
        );
        setSegments(pruned);
      }
    },
    [savedSegments, setSegments, destinationHasValues]
  );

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        style={{ backgroundColor: colors.orange[900] }}
        image={AllGamesImage}
      ></CardMedia>
      <CardHeader title="All Games" />
      <CardContent>
        {globalSegments && (
          <>
            <Typography paragraph>Segments:</Typography>
            <FormGroup>
              {Object.entries(globalSegments).map(([label, values], index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={destinationHasValues(label)}
                        onChange={() => handleChange(label, values)}
                        color="primary"
                      />
                    }
                    label={label}
                  />
                );
              })}
            </FormGroup>
          </>
        )}
      </CardContent>
    </Card>
  );
};
