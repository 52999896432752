import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  promoteBtn: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
}));
