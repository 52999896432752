import {
  FirebaseProvider,
  ImageGalleryProivder,
  InboxSentimentProvider,
  InboxTagsProvider,
  ThemeProvider,
  GamesProvider,
  ConnectedDeploymentsProvider
} from "Providers";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { App } from "Components/App";
import ReactDOM from "react-dom";
import * as React from "react";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <FirebaseProvider>
        <ConnectedDeploymentsProvider>
          <GamesProvider>
            <ImageGalleryProivder>
              <InboxTagsProvider>
                <InboxSentimentProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </InboxSentimentProvider>
              </InboxTagsProvider>
            </ImageGalleryProivder>
          </GamesProvider>
        </ConnectedDeploymentsProvider>
      </FirebaseProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
