import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { useFirebaseProvider } from 'Providers/FirebaseProvider'
import React, { useState } from 'react'
import { useAsyncRun, useAsyncTask } from 'react-hooks-async'
import { fetchConnections } from './async/fetchConnections'
import Form from "@rjsf/material-ui";
import { JSONSchema7 } from 'json-schema';
import Schema from '@halfbrick/network-effect-schemas/src/models/ConnectedDeployment.v1.json'
import ServiceAccountSchema from '@halfbrick/network-effect-schemas/src/models/ServiceAccountConfig.v1.json'
import { ParsedConnectedDeployment } from './ConnectedDeployments.types'
import { updateConnection } from './async/updateConnection'
import { createConnection } from './async/createConnection'
import { deleteConnection } from './async/deleteConnection'
import { DeleteButton } from 'Components/DeleteButton/DeleteButton'
import Ajv from "ajv"


const columns = [
    {
        name: "id",
        options: {
            display: false
        }
    },
    {
        name: "Name",
        label: "Deployment Name"
    },
    {
        name: "connectionIntent",
        label: "Connection Intent"
    },
];

export const ConnectedDeployments = () => {

    const { firestore } = useFirebaseProvider();

    const createTask = useAsyncTask(createConnection);
    const deleteTask = useAsyncTask(deleteConnection);
    const updateTask = useAsyncTask(updateConnection);

    const fetchTask = useAsyncTask(fetchConnections);
    useAsyncRun(fetchTask, firestore)

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState<ParsedConnectedDeployment | undefined>()

    const openNewDialog = () => {
        setDialogContent(undefined);
        setDialogOpen(true)
    }

    const openEditDialog = (data: ParsedConnectedDeployment) => {
        setDialogContent(data);
        setDialogOpen(true)
    }

    const closeDialog = () => {
        setDialogContent(undefined);
        setDialogOpen(false)
    }

    const isDialogEditing = Boolean(dialogContent)

    function validateSchema(formData, errors) {
        try {
            const serviceObj = JSON.parse(formData.ServiceAccountJSON)
            const ajv = new Ajv();
            const validate = ajv.compile(ServiceAccountSchema);
            const valid = validate(serviceObj)
            if (valid === false) {
                validate.errors!.forEach(err => {
                    errors.ServiceAccountJSON.addError(err.message)
                })
            }
        } catch (err) {
            errors.ServiceAccountJSON.addError(err.message)
        }
        return errors;
    }


    return (
        <>
            <Box display={"flex"} alignItems="center" justifyContent="space-between" paddingBottom={2}>
                <Typography variant="h3">Connected Deployments</Typography>
                <Button variant="contained" color="primary" onClick={openNewDialog}>Add New</Button>
            </Box>
            {fetchTask.pending && <CircularProgress />}
            {fetchTask.result && <MUIDataTable
                title={""}
                data={fetchTask.result}
                columns={columns}
                options={{
                    print: false,
                    download: false,
                    filter: false,
                    selectableRows: "none",
                    onRowClick: (rowData) => { openEditDialog(fetchTask.result!.find((c: any) => c.id === rowData[0])!) },
                }}

            />}
            <Dialog open={dialogOpen}>
                <DialogTitle >{!isDialogEditing ? "Add New" : "Edit"} Connected Deployment</DialogTitle>
                <DialogContent>
                    {createTask.started && createTask.pending && <CircularProgress />}
                    {updateTask.started && updateTask.pending && <CircularProgress />}
                    {!createTask.started && !updateTask.started && <Form
                        formData={dialogContent ?? {}}
                        validate={validateSchema}
                        schema={Schema as JSONSchema7}
                        onSubmit={async ({ formData }) => {
                            if (isDialogEditing) {
                                await updateTask.start(firestore, dialogContent!.id, formData)
                            } else {
                                await createTask.start(firestore, formData)
                            }
                            closeDialog();
                            await fetchTask.start(firestore);

                        }}
                    >
                        <Box display={"flex"} justifyContent="space-between" paddingY={2}>
                            <Button variant="contained" color="default" onClick={closeDialog} >Close</Button>
                            <Grid style={{ width: "auto" }} container spacing={1} >
                                {isDialogEditing && (
                                    <Grid item>
                                        <DeleteButton
                                            onDelete={async () => {
                                                await deleteTask.start(firestore, dialogContent!.id)
                                                closeDialog();
                                                await fetchTask.start(firestore);
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button variant="contained" color="primary" type="submit">{isDialogEditing ? "Update" : "Create"}</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

