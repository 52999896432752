import { firestore as fbFirestore } from "firebase";

export const deleteConnection = async (
  _: any,
  firestore: fbFirestore.Firestore,
  id: string
) => {
  try {
    await firestore.collection("connected-deployments").doc(id).delete();
  } catch (error) {
    console.log("error: ", error);
  }
};
