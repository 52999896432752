import { Route, Switch, useRouteMatch } from "react-router-dom";
import { GamesOverview } from "./views/GamesOverview";
import { GameEdit } from "./views/GameEdit";
import React from "react";

export const Games = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={url}>
        <GamesOverview />
      </Route>
      <Route path={`${url}/:id`}>
        <GameEdit />
      </Route>
    </Switch>
  );
};
