import { GameSegmentListItemProps } from "./GameSegmentListItem.types";
import React, { ComponentType, Fragment, useState } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  ListItemAvatar,
  ListItemText,
  Collapse,
  ListItem,
  Avatar,
  List,
} from "@material-ui/core";

export const GameSegmentListItem: ComponentType<GameSegmentListItemProps> = ({
  game,
  segments,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <ListItem
        disableGutters
        dense
        button
        onClick={() => setOpen((prev) => !prev)}
      >
        <ListItemAvatar>
          <Avatar alt={game.displayName} src={game.logoUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={`${game.displayName} - ${segments.length} Segment${
            segments.length > 1 ? "s" : ""
          }`}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {segments.map((segment, index) => (
            <ListItem key={index}>
              <ListItemText primary={segment.Name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};
