import { PublishDiscoveryDiff, PublisherDiffData } from "@halfbrick/network-effect-web-shared";
import { functions as fn } from "firebase";

export const fetchPublisherDiffData = async (_: any, functions: fn.Functions, entry:PublishDiscoveryDiff):Promise<PublisherDiffData> => {
  try {
    const getCall = functions.httpsCallable("publisherFetchDiffData");
    var data = await getCall(entry);
    if (data === undefined || data === null || data.data === undefined || data.data === null) {
      return {
        error: "No data returned from API",
        entry,
        publishedPost: "",
        editPost: ""
      };
    }

    const result:PublisherDiffData = data.data;
    if (result.editPost === null) {
      result.editPost = undefined;
    }
    if (result.publishedPost === null) {
      result.publishedPost = undefined;
    }
    return data.data;
  } catch (error) {
    console.log("error: ", error);

    const errorStr = error.toString();
    return {
      error: errorStr,
      entry,
      publishedPost: errorStr,
      editPost: errorStr
    };
  }
};
