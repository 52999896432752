import { getGlobalSegmentsFromSegmentList } from "../../ManageSegments/ManageSegments.utilities";
import { GlobalSegmentListItem } from "./GlobalSegmentListItem";
import { PreviewSegmentsProps } from "./PreviewSegments.types";
import { GameSegmentListItem } from "./GameSegmentListItem";
import { useGamesProvider } from "Providers/GamesProvider";
import React, { ComponentType, useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { AppVersionFilter } from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import { AppVersionFilterFilterMode } from "../../RichInboxProvider";

export const PreviewSegments: ComponentType<PreviewSegmentsProps> = observer(
  ({ segments, appVersionFilters }) => {
    const games = useGamesProvider();

    const globals = getGlobalSegmentsFromSegmentList(
      segments,
      games.availableGames
    );

    const filterString = useCallback(
      (filter: AppVersionFilter) => {
        const Game = games.availableGames.find(g => g.titleID === filter.titleID)
        let modeText = ""
        switch (filter.filterMode as unknown as AppVersionFilterFilterMode) {
          case AppVersionFilterFilterMode.Equal:
            modeText = `Equal To`
            break;
          case AppVersionFilterFilterMode.NotEqual:
            modeText = `Not Equal To`
            break;
          case AppVersionFilterFilterMode.Greater:
            modeText = `Greater Than`
            break;
          case AppVersionFilterFilterMode.Less:
            modeText = `Less Than`
            break;
        }
        return `${Game?.displayName} Version is ${modeText} ${filter.filterValue}`
      },
      [games],
    )

    const [versionFiltersOpen, setVersionFiltersOpen] = useState(false)

    return (
      <>
        <List component="nav">
          {Object.entries(globals).length > 0 && (
            <GlobalSegmentListItem segments={globals} />
          )}
          {games
            .availableGames!.filter((game) =>
              segments
                .filter((s) => !Object.keys(globals).includes(s.Name))
                .some((segment) => segment.titleID === game.titleID)
            )
            .map((game) => {
              return (
                <GameSegmentListItem
                  key={game.titleID}
                  game={game}
                  segments={segments
                    .filter((s) => s.titleID === game.titleID)
                    .filter((s) => !Object.keys(globals).includes(s.Name))}
                />
              );
            })}
        </List>
        {appVersionFilters?.length > 0 && (
          <>
            <ListItem
              disableGutters
              dense
              button
              onClick={() => setVersionFiltersOpen((prev) => !prev)}
            >
              <ListItemText
                primary={`App Version Filters`}
              />
              {versionFiltersOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={versionFiltersOpen}>
              <List component="div" disablePadding>
                {appVersionFilters.map((filter, index) =>
                (
                  <ListItem key={index}>
                    <ListItemText primary={filterString(filter)} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </>
        )}
      </>
    );
  }
);
