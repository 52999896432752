import React from "react";
import { Tooltip } from "@material-ui/core";
import { useGamesProvider } from "Providers/GamesProvider";
import { useStyles } from "./GameIconChip.styles";
import { GameIconChipProps } from "./GameIconChip.types";
import ErrorIcon from "@material-ui/icons/Error";


export const GameIconChip = ({ titleID }: GameIconChipProps) => {
    const classes = useStyles();
    const { availableGames, gamesLoading } = useGamesProvider();
    const GameObject = availableGames.find(g => g.titleID === titleID)

    return <>
        {!gamesLoading && GameObject && (
            <Tooltip key={titleID} title={GameObject!.displayName}>
                <div className={classes.mediaButton}>
                    <img src={GameObject!.logoUrl} alt={GameObject!.displayName} />
                </div>
            </Tooltip>
        )}
        {!gamesLoading && !GameObject && (
            <Tooltip key={titleID} title={"ERROR: " + titleID}>
                <div className={classes.mediaButton}>
                    <ErrorIcon />
                </div>
            </Tooltip>
        )}
    </>


}