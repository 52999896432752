import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { LoadingIndicator } from "Components/LoadingIndicator";
import { useAsyncRun, useAsyncTask } from "react-hooks-async";
import { useHistory, useRouteMatch } from "react-router-dom";
import { overviewColumns } from "./GamesOverview.const";
import React, { useCallback, useState } from "react";
import { GameForm } from "Domains/Games/components";
import CloseIcon from "@material-ui/icons/Close";
import MUIDataTable from "mui-datatables";
import last from "lodash.last";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  Dialog,
  Grid,
  Box,
} from "@material-ui/core";
import { fetchGames } from "Providers/GamesProvider/async";

export const GamesOverview = () => {
  const firebase = useFirebaseProvider();
  const [dialogOpen, setDialog] = useState(false);

  const { url } = useRouteMatch();
  const history = useHistory();

  const handleRowClick = useCallback(
    (row: string[]) => {
      const documentId = last(row);
      history.push(`${url}/${documentId}`);
    },
    [url, history]
  );

  const fetchGamesTask = useAsyncTask(fetchGames);

  useAsyncRun(fetchGamesTask, firebase.functions);
  const { result, pending, start } = fetchGamesTask;

  return (
    <Box>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography paragraph variant="h2">
            Manage Games
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDialog(true)}
          >
            Add Game
          </Button>
        </Grid>
      </Grid>
      {pending && <LoadingIndicator />}
      {!pending && result && (
        <Box paddingY={2}>
          <MUIDataTable
            title=""
            data={result}
            columns={overviewColumns}
            options={{
              print: false,
              download: false,
              filter: false,
              selectableRows: "none",
              onRowClick: handleRowClick,
            }}
          />
        </Box>
      )}
      <Dialog open={dialogOpen} onClose={() => setDialog(false)}>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h3">Add New Game</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={() => setDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <GameForm onClose={() => {setDialog(false); start(firebase.functions);}} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
