import { useContext } from "react"
import { InboxSentimentProviderContext } from "./InboxSentimentProvider.context";

export const useInboxSentimentProvider = () => {
  const context = useContext(InboxSentimentProviderContext);

  if(context === undefined){
    throw Error("useInboxSentimentProvider must be used within a InboxSentimentProvider");
  }

  return context;
}