import { Route, Switch, useRouteMatch } from "react-router-dom";
import { RichInboxNewPost } from "./views/RichInboxNewPost";
import { RichInboxOverview, RichInboxPost } from "./views";
import React from "react";

export const RichInbox = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={url}>
        <RichInboxOverview />
      </Route>
      <Route path={`${url}/new`}>
        <RichInboxNewPost />
      </Route>
      <Route path={`${url}/:id`}>
        <RichInboxPost />
      </Route>
    </Switch>
  );
};
