import { useRichInboxProvider } from "Domains/RichInbox/components/RichInboxProvider";
import { ManageGeneralInformationProps } from "./ManageGeneralInformation.types";
import React, { ComponentType, Fragment, useState } from "react";
import { FormAction } from "../FormAction";
import { observer } from "mobx-react-lite";
import {
  FormHelperText,
  FormControl,
  Typography,
  InputLabel,
  Input,
  Box,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useInboxTagsProvider } from "Providers/InboxTagsProvider";
import { useInboxSentimentProvider } from "Providers/InboxSentimentProvider";

export const ManageGeneralInformation: ComponentType<ManageGeneralInformationProps> = observer(
  ({ isModal = false, onSubmit }) => {
    const store = useRichInboxProvider();

    const [sentiment, setSentiment] = useState(store.post.sentiment);
    const [tags, setTags] = useState(store.post.tags);
    const [title, setTitle] = useState(store.post.title);
    const [priority, setPriorityInternal] = useState(store.post.priority);
    const [isAdvertisement, setIsAdvertisement] = useState(Boolean(store.post.isAdvertisement));

    const setPriority = (newPriority) => {
      if (newPriority === undefined || isNaN(newPriority)) {
        setPriorityInternal(undefined);
      } else {
        setPriorityInternal(newPriority);
      }
    };

    const submitForm = () => {
      store.setPost({ title, tags, sentiment, priority, isAdvertisement });
      onSubmit();
    };

    const hasValidContent = () => {
      return title && tags.length && sentiment.length;
    };

    const { isLoading: isTagsLoading, availableTags } = useInboxTagsProvider();
    const { isLoading: isSentimentLoading, availableSentiment } = useInboxSentimentProvider();
       
    return (
      <Fragment>
        <Typography variant="h5" paragraph>
          General Information
        </Typography>
        <Typography variant="body1" paragraph>
          Provide some general infromation about this post.
        </Typography>
        <Box marginBottom={4} marginTop={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="title">Title</InputLabel>
            <Input
              id="title"
              aria-describedby="title-helper"
              type="text"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
            <FormHelperText id="title-helper">
              The title of this post (not displayed to the user).
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Switch checked={isAdvertisement} onChange={(e) => setIsAdvertisement(e.target.checked)} />}
              label="Is Advertisement"
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="title">Priority (Optional)</InputLabel>
            <Input
              id="priority"
              aria-describedby="priority-helper"
              type="number"
              value={priority}
              onChange={(event) => setPriority(parseInt(event.target.value))}
            />
            <FormHelperText id="priority-helper">
              The priority (sort order) of the post on the client. (Higher number shown first, if no priority is specified it uses timestamp for sorting)
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Autocomplete
              disabled={isTagsLoading}
              multiple
              freeSolo
              id="tags-standard"
              options={availableTags}
              getOptionLabel={(option) => option}
              defaultValue={tags}
              noOptionsText={"Tag Doesnt Exist, Press Enter to Create It!"}
              onChange={(_, value) => {
                setTags(value)
              }}
              filterOptions={(options, state) => options.filter(o => o.includes(state.inputValue)).filter(o => !tags.includes(o))}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="standard"
                    label={isTagsLoading ? "Loading Exisiting Tags..." : "Tags"}
                    placeholder={isTagsLoading ? "Loading Exisiting Tags..." : ""}
                    helperText="What your post contains, e.g; advert, merch, install"
                  />
                )
              }}
            />
          </FormControl>
          <FormControl>
            <Autocomplete
              multiple
              freeSolo
              id="sentiment-standard"
              options={availableSentiment}
              getOptionLabel={(option) => option}
              defaultValue={sentiment}
              filterOptions={(options, state) => options.filter(o => o.includes(state.inputValue)).filter(o => !sentiment.includes(o))}
              noOptionsText={"Sentiment Doesnt Exist, Press Enter to Create It!"}
              onChange={(_, value) => {
                setSentiment(value)
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="standard"
                    label={isSentimentLoading ? "Loading Exisiting Sentiment..." : "Sentiment"}
                    placeholder={isSentimentLoading ? "Loading Exisiting Sentiment..." : ""}
                    helperText="How your post is to be received, e.g; friendly, direct, informative."
                  />
                )
              }}
            />
          </FormControl>
        </Box>
        <FormAction
          disabled={!hasValidContent()}
          onClick={submitForm}
          isModal={isModal}
        />
      </Fragment>
    );
  }
);
