import { PostPreviewer } from "Domains/RichInbox/components/PostPreviewer";
import { fetchPostById } from "Domains/RichInbox/async/fetchPostById";
import { useFirebaseProvider } from "Providers/FirebaseProvider";
import React, { useCallback, useEffect, useState } from "react";
import { LoadingIndicator } from "Components/LoadingIndicator";
import { useAsyncRun, useAsyncTask } from "react-hooks-async";
import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "./RichInboxPost.styles";
import CheckIcon from "@material-ui/icons/Check";
import { Toolbar } from "./Toolbar";
import {
  CircularProgress,
  Typography,
  Avatar,
  Button,
  Grid,
  Box,
} from "@material-ui/core";
import {
  postToRichInboxPost,
  RichInboxPostType,
  RichInboxProvider,
} from "Domains/RichInbox/components/RichInboxProvider";

export const RichInboxPost = () => {
  // toggles for showing updating UI
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);

  // tggles for showing deleting UI
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const [localPost, setLocalPost] = useState<
    RichInboxPostType | null | undefined
  >(null);
  const { id } = useParams<{ id: string }>();
  const firebase = useFirebaseProvider();
  const history = useHistory();
  const classes = useStyles();

  const fetchPostTask = useAsyncTask(fetchPostById);
  useAsyncRun(fetchPostTask, firebase.functions, id);
  const { result, pending } = fetchPostTask;

  useEffect(() => {
    if (result && !pending) {
      setLocalPost(postToRichInboxPost(result));
    }
  }, [setLocalPost, result, pending]);

  const handleEditMode = useCallback(() => {
    setUpdated(false);
    setUpdating(false);
  }, []);

  return (
    <>
      {(pending || !result) && <LoadingIndicator />}
      {updating && (
        <Box className={classes.loadingBox} padding={3}>
          <CircularProgress size={150} />
          <br />
          <Typography variant="h2">Saving Post</Typography>
        </Box>
      )}
      {deleting && (
        <Box className={classes.loadingBox} padding={3}>
          <CircularProgress size={150} />
          <br />
          <Typography variant="h2">Deleting Post</Typography>
        </Box>
      )}
      {deleted && (
        <Box className={classes.loadingBox} padding={3}>
          <Avatar className={classes.WarningIconAvator}>
            <CheckIcon className={classes.SuccessIcon} />
          </Avatar>
          <br />
          <Typography variant="h2">Success</Typography>
          <Typography variant="h4" paragraph>
            Your post was Deleted<br />
            Go to publisher to finalise the deletion
          </Typography>
          <Button
            variant={"contained"}
            color = "primary"
            onClick={() => {
              history.push(`/publisher`);
            }}
          >
            Go to Publisher
          </Button>
          <br />
          <Button
            variant={"outlined"}
            onClick={() => history.push(`/richinbox`)}
          >
            Back to All Posts
          </Button>
        </Box>
      )}
      {updated && (
        <Box className={classes.loadingBox} padding={3}>
          <Avatar className={classes.SuccessIconAvator}>
            <CheckIcon className={classes.SuccessIcon} />
          </Avatar>
          <br />
          <Typography variant="h2">Success</Typography>
          <Typography variant="h4" paragraph>
            Your post has been Saved
          </Typography>
          <Button
            variant={"contained"}
            color = "primary"
            onClick={() => {
              history.push(`/publisher`);
            }}
          >
            Go to Publisher
          </Button>
          <br />
          <Button
            variant={"contained"}
            color="primary"
            onClick={handleEditMode}
          >
            Make More Changes
          </Button>
          <br />
          <Button
            variant={"outlined"}
            onClick={() => history.push(`/richinbox`)}
          >
            Back to All Posts
          </Button>
        </Box>
      )}
      {!deleted && !deleting && !updated && !updating && localPost && (
        <RichInboxProvider post={localPost}>
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            justify={"space-between"}
          >
            <Grid item>
              <Typography variant="h3">Manage Richinbox Post</Typography>
            </Grid>
            <Grid item>
              <Toolbar
                originalPost={localPost!}
                setUpdated={setUpdated}
                setUpdating={setUpdating}
                setLocalPost={setLocalPost}
                setDeleted={setDeleted}
                setDeleting={setDeleting}
              />
            </Grid>
          </Grid>
          <PostPreviewer edit />
        </RichInboxProvider>
      )}
    </>
  );
};
