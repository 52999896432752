import { ContentPanelProps } from "./ContentPanel.types";
import { useStyles } from "./ContentPanel.styles";
import React, { FunctionComponent } from "react";
import { Toolbar } from "@material-ui/core";
import clsx from "clsx";

export const ContentPanel: FunctionComponent<ContentPanelProps> = ({
  children,
  navMenuOpen,
}) => {
  const classes = useStyles();

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: navMenuOpen,
      })}
    >
      <Toolbar />
      {children}
    </main>
  );
};
