import { Box, Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import { LoadingIndicator } from 'Components/LoadingIndicator';
import MUIDataTable from 'mui-datatables';
import { useFirebaseProvider } from 'Providers/FirebaseProvider';
import React, { useState } from 'react';
import { useAsyncTask, useAsyncRun } from 'react-hooks-async';
import { fetchHistory } from './async';
import { overviewColumns, timestampDisplayOptions } from './History.const';
import RefreshIcon from "@material-ui/icons/Refresh";
import { ShowDiffDialog } from './components';
import { HistoryWithId } from "@halfbrick/network-effect-web-shared";

export const History = () => {
  const firebase = useFirebaseProvider();
  const fetchHistoryTask = useAsyncTask(fetchHistory);

  const [showDiffDialogData, setShowDiffDialogData] = useState<HistoryWithId | undefined>();

  useAsyncRun(fetchHistoryTask, firebase.functions);

  const refreshHistory = () => {
    fetchHistoryTask.start(firebase.functions);
  };

  const customToolbar = () => {
    return (
      <Tooltip title="Refresh Pending Changes">
        <IconButton aria-label="reload" onClick={refreshHistory}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    );
  };
  
  return (<>
    <Box display={"flex"} alignItems="center" justifyContent="space-between" paddingBottom={2}>
        <Typography variant="h2">History {customToolbar()}</Typography>
    </Box>
    {fetchHistoryTask.pending && <>
      <LoadingIndicator />
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        width="100%"
      >
        <Typography variant="h3">Loading...</Typography>
      </Box>
    </>} 
    {!fetchHistoryTask.pending && fetchHistoryTask.result && 
      <MUIDataTable
        title=""
        data={fetchHistoryTask.result}
        columns={[
          {
            name: "Time", 
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                const dateTime = new Date(fetchHistoryTask.result[tableMeta.rowIndex].timeStamp);
                return (<>{dateTime.toLocaleString("en-US", timestampDisplayOptions)}</>);
              }
            }
          },
          ...overviewColumns,
          { 
            name: "Actions",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowDiffDialogData(fetchHistoryTask.result[tableMeta.rowIndex]);
                  }}>
                    Show
                </Button>
                );
              }
            }
          }
        ]}
        options={{
          print: false,
          download: false,
          filter: false,
          selectableRows: "none",
          customToolbar
        }}
      />}
      <ShowDiffDialog 
        onClose={() => setShowDiffDialogData(undefined)} 
        entry={showDiffDialogData} />
  </>);
};