import { parsePlacementType } from "./RichInboxProvider.utils";
import {
  RichInboxPostType,
  CountriesFilterMode,
  LanguageFilterFilterMode,
} from "./RichInboxProvider.types";
import { add } from "date-fns";

export enum AllowedPostPlacements {
  Inbox = "inbox",
  Interstitial = "interstitial",
  News = "news",
}

export enum SKUFilter {
  Android = "android",
  Ios = "ios",
}

export const defaultValues: RichInboxPostType = {
  title: "",
  sentiment: [],
  segments: [],
  tags: [],
  appVersionFilters: [],
  placementType: parsePlacementType(AllowedPostPlacements.Inbox),
  publishing: {
    isDraft: true,
    isScheduled: false,
    publishDateTime: new Date(),
    expiry: add(new Date(), { days: 365 }),
  },
  SKUFilter: undefined,
  localisation: {
    countries: {
      filterMode: CountriesFilterMode.All,
      filterValues: [],
    },
    languageFilter: {
      filterMode: LanguageFilterFilterMode.All
    },
    languages: [
      {
        name: "English",
        ISO6391: "en",
        shortName: "en",
        IEFT: "en",
      },
    ],
    translations: [],
  },
};
