import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { defaultTheme } from "./ThemeProvider.const";
import React, { FunctionComponent } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  ThemeProvider as MuithemeProvider,
  CssBaseline,
} from "@material-ui/core";

export const ThemeProvider: FunctionComponent = ({ children }) => {
  return (
    <MuithemeProvider theme={defaultTheme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {children}
      </MuiPickersUtilsProvider>
    </MuithemeProvider>
  );
};
