import { PlacementOptionCardProps } from "./PlacementOptionCard.types";
import { useStyles } from "../ManagePlacementType.styles";
import React, { ComponentType } from "react";
import {
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Card,
} from "@material-ui/core";

export const PlacementOptionCard: ComponentType<PlacementOptionCardProps> = ({
  PlacementChoice,
  description,
  clickAction,
  placement,
  image,
}) => {
  const classes = useStyles();

  return (
    <Card
      className={`${classes.card} ${
        PlacementChoice === placement && classes.cardSelected
      }`}
    >
      <CardActionArea
        className={classes.card}
        onClick={() => clickAction(placement)}
      >
        <CardHeader title={placement}></CardHeader>
        <CardMedia className={classes.media} image={image}></CardMedia>
        <CardContent>{description}</CardContent>
      </CardActionArea>
    </Card>
  );
};
