import { createMuiTheme, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const defaultTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
});

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: "1rem",
        maxWidth: "60rem",
        width: "100%",
      },
      editor: {
        backgroundColor: "#fafafa",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        color: grey[600],
        padding: "20px",
        height: "400px",
        maxHeight: "1000px",
        overflow: "auto",
      },
      toolbar: {
        borderBottom: `1px solid ${grey[300]}`,
        backgroundColor: grey[200],
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      },
      placeHolder: {
        paddingLeft: 20,
        paddingTop: 20,
      },
    },
  },
});
