import { useRichInboxProvider } from "Domains/RichInbox/components/RichInboxProvider";
import React, { ComponentType, Fragment, useCallback, useState } from "react";
import { Box, Button, Paper, Tooltip } from "@material-ui/core";
import { useGamesProvider } from "Providers/GamesProvider";
import { useStyles } from "./PreviewSegment.styles";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import { GameItem } from "@halfbrick/network-effect-web-shared";
import { MortarJSONPreviewerBox } from "../../MortarJSONPreviewerBox";

export const PreviewSegment: ComponentType = observer(() => {
  const games = useGamesProvider();
  const store = useRichInboxProvider();
  const classes = useStyles();

  const [activeTitleForPreview, setActiveTitleForPreview] = useState<
    GameItem | null
  >(null);

  const GamesList = useCallback(() => {
    return games.availableGames.filter((game) =>
      store.post.segments.find((segment) => segment.titleID === game.titleID)
    );
  }, [games, store.post.segments]);

  return (
    <Fragment>
      <Paper>
        <Box display="flex" className={classes.segmentMenu}>
          {GamesList().map((segment) => (
            <Tooltip key={segment.titleID} title={segment.displayName}>
              <Button
                variant="text"
                className={`${classes.mediaButton} ${clsx(
                  activeTitleForPreview?.titleID === segment.titleID && "active"
                )}`}
                onClick={() => setActiveTitleForPreview(segment)}
              >
                <img src={segment.logoUrl} alt={segment.displayName} />
              </Button>
            </Tooltip>
          ))}
        </Box>
        <Box padding={2}>
          {/* {!activeTitleForPreview && (<Typography variant="body2">Click on a game icon to launch a post previewer!</Typography>)}
          {activeTitleForPreview?.unity && (
            <WebGLGameContentBox activeTitleForPreview={activeTitleForPreview?.titleID} />)}
          {activeTitleForPreview?.unity === false && ( */}
          <MortarJSONPreviewerBox activeTitleForPreview={activeTitleForPreview?.displayName} />
        </Box>
      </Paper>
    </Fragment>
  );
});
