import { DraftEntityMutability, RawDraftContentState } from "draft-js";
import { RichInboxPostType } from "./RichInboxProvider.types";
import {
  Translation,
  Post,
  Block,
} from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import {
  AllowedPostPlacements,
  defaultValues,
} from "./RichInboxProvider.const";
import { convertToBlocksUnity } from "Utilities/draftToUnityRichText";

export const parsePlacementType = (type: "news" | "inbox" | "interstitial") =>
  AllowedPostPlacements[type[0].toUpperCase() + type.slice(1)];

export const richInboxPostToPost = (post: RichInboxPostType): Post => {
  const { localisation, ...rest } = post;
  const { translations, languages, countries, languageFilter } = localisation;

  const newTranslations = translations.map((item) => {
    const content: Translation["content"] = {
      blocks: item.content.blocks,
      entityMap: Object.entries(item.content.entityMap).map(([key, value]) => {
        return {
          key: parseInt(key, 10),
          data: value.data,
          mutability: value.mutability,
          type: value.type,
        };
      }),
    };

    const transformedContent = convertToBlocksUnity(
      (content as unknown) as RawDraftContentState
    );

    return {
      name: item.name,
      content: transformedContent,
      subject: item.subject,
    };
  });

  return {
    ...defaultValues,
    ...rest,
    localisation: { translations: newTranslations, languages, countries, languageFilter },
  };
};

export const postToRichInboxPost = (post?: Post): RichInboxPostType => {
  if (post) {
    const { localisation, ...rest } = post;
    const {
      translations,
      languages,
      countries = defaultValues.localisation.countries,
      languageFilter = defaultValues.localisation.languageFilter,
    } = localisation;

    const newTranslations = translations.map((item) => {
      const content: RawDraftContentState = {
        blocks: item.content.blocks.map((block: Block) => {
          if (block.rawText) {
            block.text = block.rawText!;
          }
          return block;
        }) as RawDraftContentState["blocks"],
        entityMap: item.content.entityMap.reduce((acc, item) => {
          acc[item.key] = {
            mutability: item.mutability as DraftEntityMutability,
            data: item.data,
            type: item.type,
          };
          return acc;
        }, {}),
      };

      return { name: item.name, content, subject: item.subject || "" };
    });

    return {
      ...defaultValues,
      ...rest,
      localisation: { translations: newTranslations, languages, countries, languageFilter },
    };
  } else return defaultValues;
};
