import { Segment } from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import { fetchGameSegments } from "Domains/RichInbox/async/fetchGameSegments";
import { GameItem } from "@halfbrick/network-effect-web-shared";
import groupBy from "lodash.groupby";
import pickBy from "lodash.pickby";

export const fetchSegmentsAndParseGlobals = async (
  { signal }: { signal: AbortSignal },
  games: GameItem[]
) => {
  const segmentsByGame = await Promise.all(
    games.map(async (game) => {
      const { titleID, APIKey, cloudProvider } = game;

      if (cloudProvider === "playfab") {
        const segments = await fetchGameSegments(signal, titleID, APIKey);
        return segments;
      }

      return [{ Id: titleID, Name: "Players - _All", titleID }] as Segment[];
    })
  );

  // merge all our segments into one list
  const allSegments = ([] as Segment[]).concat.apply([], segmentsByGame);

  // grab the non unique segments by segment name - these are the globals
  const globalSegments = getGlobalSegmentsFromSegmentList(allSegments, games);

  return { allSegments, globalSegments };
};

export const getGlobalSegmentsFromSegmentList = (
  segmentList: Segment[] = [],
  availableGames: GameItem[] = []
) => {
  // if (!availableGames) {
  //   return [];
  // }
  const groupedSegments = pickBy(
    groupBy(segmentList, "Name"),
    (group) => group.length === availableGames.length
  );

  return groupedSegments;
};
