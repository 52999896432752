import { Segment } from "@halfbrick/network-effect-schemas/dist/typescript/Post";

export const fetchGameSegments = async (
  signal: AbortSignal,
  titleID: string,
  APIKey?: string
) => {
  if (APIKey) {
    // Default options are marked with *
    const response = await fetch(
      `https://${titleID}.playfabapi.com/Admin/GetAllSegments`,
      {
        signal,
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          "x-SecretKey": APIKey,
        },
        mode: "cors", // no-cors, *cors, same-origin
        body: JSON.stringify({}), // body data type must match "Content-Type" header
      }
    );
    const result = await response.json();
    const segments = result.data.Segments.map((segment: Segment) => ({
      ...segment,
      titleID,
    }));
    return segments as Segment[];
  }
  return [];
};
