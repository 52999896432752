import React, { FunctionComponent, useMemo } from "react";
import { AspectRatioProps } from "./AspectRatio.types";
import { useStyles } from "./AspectRatio.styles";

export const AspectRatio: FunctionComponent<AspectRatioProps> = ({
  ratio,
  children,
}) => {
  const calculatedRatio = useMemo(() => {
    const [dividend, divisor] = ratio.split("x");
    return parseInt(dividend, 10) / parseInt(divisor, 10);
  }, [ratio]);

  const classes = useStyles({ ratio: calculatedRatio });

  return (
    <div className={classes.outerWrapper}>
      <div className={classes.innerWrapper}>{children}</div>
    </div>
  );
};
