import { PublisherRevertChanges, PublisherRevertChangesResponse } from "@halfbrick/network-effect-web-shared";
import { functions as fn } from "firebase";

export async function revertChange(_: any, functions: fn.Functions, request:PublisherRevertChanges):Promise<PublisherRevertChangesResponse> {
  try {
    const getCall = functions.httpsCallable("revertChange");
    var data = await getCall(request);
    if (data === undefined || data === null || data.data === undefined || data.data === null) {
      return {
          error: "No data returned from call"
      };
    }
    return data.data;

  } catch (error) {
    console.log("[publishSelectedChanges]: An error occured while publishing", error);
    return {
        error: error.toString()
    };
  }
};