import React from 'react'
import { CircularProgress, Typography } from "@material-ui/core";
import Form from "@rjsf/material-ui";
import { JSONSchema7 } from 'json-schema';
import Schema from '@halfbrick/network-effect-schemas/src/models/DashboardApplicationSettings.v1.json'
import { useAsyncRun, useAsyncTask } from "react-hooks-async";
import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { updateSettings } from 'Domains/Settings/views/GenericSettings/async/updateSettings';
import { fetchSettings } from 'Domains/Settings/views/GenericSettings/async/fetchSettings';

export const GenericSettings = () => {
    const firebase = useFirebaseProvider();

    const updateSettingsTask = useAsyncTask(updateSettings);
    const fetchSettingsTask = useAsyncTask(fetchSettings);

    useAsyncRun(fetchSettingsTask, firebase.firestore);

    return (
        <>
            <Typography paragraph variant="h3">Generic Settings</Typography>
            {fetchSettingsTask.pending && <CircularProgress />}
            {updateSettingsTask.started && <CircularProgress />}
            {!fetchSettingsTask.pending && !updateSettingsTask.started && updateSettingsTask.pending && (
                <Form
                    formData={fetchSettingsTask.result ?? {}}
                    schema={Schema as JSONSchema7}
                    onSubmit={async ({ formData }) => {
                        await updateSettingsTask.start(firebase.firestore, formData)
                        window.location.reload()
                    }}
                />
            )}
        </>
    )
}