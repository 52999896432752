import { add } from "date-fns";
import {
  richInboxPostToPost,
  RichInboxPostType,
} from "Domains/RichInbox/components/RichInboxProvider";
import { DateToISO } from "Domains/RichInbox/RichInbox.utilities";
import { functions as fn, auth } from "firebase";

export const migratePost = async (
  { signal },
  functions: fn.Functions,
  connectionId: string,
  post: RichInboxPostType
) => {
  const parsedPost = richInboxPostToPost(post);

  const currentUser = auth().currentUser!.email;

  const postData = {
    ...parsedPost,
    publishing: {
      ...parsedPost.publishing,
      publishDateTime: DateToISO(
        parsedPost.publishing.publishDateTime ?? new Date()
      ),
      expiry: DateToISO(
        parsedPost.publishing.expiry ?? add(new Date(), { days: 365 })
      ),
    },
    createdOn: DateToISO(new Date()),
    createdBy: currentUser,
  };

  const migrateFn = functions.httpsCallable("migratePost");
  const {data} = await migrateFn({ connectionId, postData });
  return data;
};
