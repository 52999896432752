import { createStyles, makeStyles } from "@material-ui/core";
import { navMenuWidth } from "../AppLayout.const";

export const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: navMenuWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: navMenuWidth,
    },
  }),
)