export type ImageGalleryProivderState = {
  galleryLoading: boolean;
  galleryItems: GalleryItem[];
};

export type GalleryItem = {
  Id: string;
  URL: string;
  type: GalleryItemType;
};

export enum GalleryItemType {
  Icon = "icon",
  Generic = "generic"
}