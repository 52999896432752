import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

export const LoadingIndicator = () => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      padding="3rem"
      width="100%"
    >
      <CircularProgress />
    </Box>
  );
};
