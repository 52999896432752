import { useContext } from "react"
import { ConnectedDeploymentsProviderContext } from "./ConnectedDeploymentsProvider.context";

export const useConnectedDeploymentsProvider = () => {
  const context = useContext(ConnectedDeploymentsProviderContext);

  if(context === undefined){
    throw Error("useConnectedDeploymentsProvider must be used within a ConnectedDeploymentsProvider")
  }

  return context;
}