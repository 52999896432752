import { CustomButtonDialogProps } from "./CustomButton.types";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  DialogContent,
  DialogTitle,
  ButtonGroup,
  TextField,
  MenuItem,
  Button,
  Dialog,
  Grid,
} from "@material-ui/core";

export const CustomButtonDialog: FunctionComponent<CustomButtonDialogProps> = ({
  onInsertButton,
  onClose,
  open,
  readOnly,
  initialState,
}) => {
  const defaultWidth = initialState ? initialState.width : 50;
  const defaultTextColor = initialState ? initialState.textColor : "#ffffff";
  const defaultAlignment = initialState ? initialState.alignment : "center";
  const defaultColor = initialState ? initialState.color : "#ff0000";
  const defaultText = initialState ? initialState.text : "Click Me";
  const defaultUrl = initialState ? initialState.url : "";

  const methods = useForm({
    defaultValues: {
      widthPercentage: defaultWidth,
      textColor: defaultTextColor,
      alignment: defaultAlignment,
      color: defaultColor,
      text: defaultText,
      url: defaultUrl,
    },
    mode: "onBlur",
  });
  useEffect(() => {
    methods.reset({
      widthPercentage: defaultWidth,
      textColor: defaultTextColor,
      alignment: defaultAlignment,
      color: defaultColor,
      text: defaultText,
      url: defaultUrl,
    });

    // disable error that "methods" isn't in dependency list (if its added it causes an infinate loop)
    // eslint-disable-next-line
  }, [defaultWidth, defaultTextColor, defaultAlignment, defaultColor, defaultText, defaultUrl]);

  const handleSubmit = useCallback(
    (data: any) => {
      onInsertButton({
        widthPercentage: data.widthPercentage,
        textColor: data.textColor,
        alignment: data.alignment,
        color: data.color,
        text: data.text,
        url: data.url,
      });
      onClose();
    },
    [onInsertButton, onClose]
  );

  return (
    <Dialog
      keepMounted={false}
      onClose={onClose}
      maxWidth={"md"}
      open={open}
      fullWidth
    >
      <DialogTitle>Custom Button</DialogTitle>
      <DialogContent>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Controller
                name="widthPercentage"
                control={methods.control}
                as={
                  <TextField
                    variant="outlined"
                    label="Button Width"
                    disabled={readOnly}
                    fullWidth
                    select
                  >
                    {[50, 100].map((width) => (
                      <MenuItem key={width} value={width}>
                        {width}%
                      </MenuItem>
                    ))}
                  </TextField>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="alignment"
                control={methods.control}
                as={
                  <TextField
                    variant="outlined"
                    label="Button Alignment"
                    disabled={readOnly}
                    fullWidth
                    select
                  >
                    {["left", "right", "center"].map((align) => (
                      <MenuItem key={align} value={align}>
                        {align}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="color"
                control={methods.control}
                as={
                  <TextField
                    variant="outlined"
                    label="Button Color"
                    type="color"
                    disabled={readOnly}
                    fullWidth
                  />
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="textColor"
                control={methods.control}
                as={
                  <TextField
                    variant="outlined"
                    label="Text Color"
                    type="color"
                    disabled={readOnly}
                    fullWidth
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="text"
                control={methods.control}
                rules={{
                  required: {
                    value: true,
                    message: "Button text is required",
                  },
                }}
                as={
                  <TextField
                    variant="outlined"
                    label="Button Text"
                    type="text"
                    disabled={readOnly}
                    fullWidth
                    helperText={methods?.errors?.text?.message}
                    error={Boolean(methods.errors?.text)}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="url"
                control={methods.control}
                as={
                  <TextField
                    variant="outlined"
                    label="Hyperlink"
                    type="text"
                    disabled={readOnly}
                    fullWidth
                    helperText={methods?.errors?.url?.message}
                    error={Boolean(methods.errors?.url)}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup variant="contained">
                <Button onClick={onClose} color="default">
                  Close
                </Button>
                <Button type="submit" color="primary" disabled={readOnly}>
                  Save
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
