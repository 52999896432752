export enum PublishDiscoveryState {
    New = "new",
    Edit = "edit",
    Delete = "delete",
    ConvertToDraft = "convertToDraft"
}

export type PublishDiscoveryDiff = {
    postId:string,
    name:string,
    state:PublishDiscoveryState
};

export type PublisherListChangesResponse = {
    pending: PublishDiscoveryDiff[],
    publishedPostIds: string[]
};

export type PublisherDiffData = {
    entry:PublishDiscoveryDiff,
    error:string | undefined,
    publishedPost:any,
    editPost:any
};
