import { adminRoutes, effectsRoutes } from "./NavMenu.routes";
import { NavMenuProps } from "./NavMenu.types";
import { NavMenuItem } from "../NavMenuItem";
import { useStyles } from "./NavMenu.styles";
import React from "react";
import {
  ListItemText,
  ListItem,
  Toolbar,
  Drawer,
  List,
} from "@material-ui/core";

export const NavMenu = ({ open }: NavMenuProps) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <List>
        <ListItem>
          <ListItemText primary={"Effects"} />
        </ListItem>
        {effectsRoutes.map((route) => (
          <NavMenuItem key={route.to} {...route} />
        ))}
        <ListItem>
          <ListItemText primary={"Admin"} />
        </ListItem>
        {adminRoutes.map((route) => (
          <NavMenuItem key={route.to} {...route} />
        ))}
      </List>
    </Drawer>
  );
};
