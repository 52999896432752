import { firestore as fbFirestore } from "firebase";
import { ParsedConnectedDeployment } from "../ConnectedDeployments.types";

export const fetchConnections = async (
  _: any,
  firestore: fbFirestore.Firestore
) => {
  try {
    const snapshot = await firestore.collection("connected-deployments").get();
    return (snapshot.docs.map((doc) => {
      const document = doc.data();
      document.id = doc.id;
      return document;
    }) as unknown[]) as ParsedConnectedDeployment[];
  } catch (error) {
    console.log("error: ", error);
  }
};
