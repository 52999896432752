import { useState, useEffect } from "react";

/**
 * useHasLoginInfo
 * Syntactic Sugar for running effects based on indexDB for Firabse logins
 */
export const useHasLoginInfo = () => {
  const [hasLoginInfo, setHasLoginInfo] = useState(true);

  // determine if we need to wait for firebase uath before we show the login page
  useEffect(() => {
    if (indexedDB) {
      let DBOpenRequest = indexedDB.open("firebaseLocalStorageDb", 1);
      DBOpenRequest.onerror = function (event) {
        console.warn(event);
      };
      DBOpenRequest.onsuccess = function (event) {
        let db = DBOpenRequest.result;
        var transaction = db.transaction(db.objectStoreNames, "readonly");
        var objectStore = transaction.objectStore("firebaseLocalStorage");
        var itemReq = objectStore.getAllKeys();
        itemReq.onsuccess = () => {
          // check to see if we need to show a login page at any stage
          const hasLogin =
            itemReq.result.findIndex((key) =>
              key.includes("firebase:authUser")
            ) > -1;
          setHasLoginInfo(hasLogin);
        };
      };
    }
  }, []);

  return { hasLoginInfo };
};
