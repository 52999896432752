import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mediaButton: {
    alignItems: "center",
    background: "transparent",
    border: "none",
    borderRadius: "50%",
    boxShadow: "none",
    display: "flex",
    flexShrink: 1,
    height: "3rem",
    justifyContent: "center",
    minWidth: "unset",
    outline: "none",
    overflow: "hidden",
    transition: "all .25s ease-in-out",
    width: "3rem",

    "& img": {
      objectFit: "cover",
      width: "100%",
    },

    "&.active": {
      border: `1px solid ${theme.palette.common.white}`,
      boxShadow: theme.shadows[4],
    },
  },
  segmentMenu: {
    background: theme.palette.grey[700],
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),

    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
}));
