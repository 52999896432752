import { AppVersionFilterFilterMode, SKUFilter, useRichInboxProvider } from "Domains/RichInbox/components/RichInboxProvider";
import React, { ComponentType, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { fetchSegmentsAndParseGlobals } from "./ManageSegments.utilities";
import { LoadingIndicator } from "Components/LoadingIndicator";
import { useAsyncTask, useAsyncRun } from "react-hooks-async";
import { ManageSegmentsProps } from "./ManageSegments.types";
import { GlobalSelectionCard } from "./GlobalSelectionCard";
import { useGamesProvider } from "Providers/GamesProvider";
import { Typography, Grid, Box, Switch, FormControlLabel, Collapse, RadioGroup, Radio, Paper, InputLabel, Select, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { GameSelectionCard } from "./GameSelectionCard";
import { FormAction } from "../FormAction";
import uniqBy from "lodash.uniqby";
import * as semver from "semver";
import { Alert } from "@material-ui/lab";
import { observer } from "mobx-react-lite";

export const ManageSegments: ComponentType<ManageSegmentsProps> =
  observer(({ isModal = false, onSubmit }) => {
    const games = useGamesProvider();
    const store = useRichInboxProvider();

    const [formCanSave, setFormCanSave] = useState(true);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const [segments, setSegments] = useState(store.post.segments);

    const task = useAsyncTask(fetchSegmentsAndParseGlobals);
    useAsyncRun(task, games.availableGames);
    const { pending, result } = task;

    const getSegmentsForGame = (titleID: string) => {
      return result?.allSegments.filter((s) => s.titleID === titleID) ?? [];
    };

    const toggleFilter = () => {
      if (!!store.post.SKUFilter) {
        console.log("Delete the filter")
        store.deleteSKUFilter();
      } else {
        store.setSKUFilter(SKUFilter.Ios)
      }
    }
    const [versionFilters, setVersionFilters] = useState(store.post.appVersionFilters);

    const GamesSelected = useMemo(() => {
      return segments.map(t => t.titleID).filter((v, i, a) => a.indexOf(v) === i)
    }, [segments])

    const AppVersionFilterModeForGame = useCallback((titleID) => {
      return versionFilters?.find((f) => f.titleID === titleID)?.filterMode ?? AppVersionFilterFilterMode.None
    }, [versionFilters])

    const setAppVersionFilterModeForGame = useCallback((titleID, mode) => {
      // remove the existing item
      const cleanedState = versionFilters?.filter((f) => f.titleID !== titleID) ?? []
      if (mode === AppVersionFilterFilterMode.None) {
        setVersionFilters(cleanedState)
      } else {
        const GameFilterValue = versionFilters?.find((f) => f.titleID === titleID) ?? { titleID, filterMode: mode, filterValue: "" }
        GameFilterValue!.filterMode = mode;
        setVersionFilters(cleanedState?.concat([GameFilterValue!]))
      }
    }, [versionFilters])


    const AppVersionFilterValueForGame = useCallback((titleID) => {
      return versionFilters?.find((f) => f.titleID === titleID)?.filterValue
    }, [versionFilters])

    const setAppVersionFilterValueForGame = useCallback((titleID, value) => {
      // remove the existing item
      const cleanedState = versionFilters?.filter((f) => f.titleID !== titleID)
      const GameFilterValue = versionFilters?.find((f) => f.titleID === titleID)
      GameFilterValue!.filterValue = value;
      setVersionFilters(cleanedState?.concat([GameFilterValue!]))
    }, [versionFilters])

    useEffect(() => {
      setErrors([])
      setFormCanSave(true)
      let errorsList: string[] = []
      versionFilters?.forEach((filter) => {
        if (!semver.valid(filter.filterValue, {loose:true})) {
          const Game = games.availableGames.find(g => g.titleID === filter.titleID)
          const newErr = `App version filter for ${Game?.displayName} is not a valid semantic version.`
          errorsList = errorsList.concat([newErr])
        }
      })
      if (errorsList.length > 0) {
        setErrors(errorsList)
        setFormCanSave(false)
      }
    }, [versionFilters, games.availableGames])

    const submitForm = useCallback(() => {
      if (formCanSave) {
        store.setSegments(segments);
        // ensure we only save filters for games we are sending to
        const appVersionFilters = versionFilters.filter(f => GamesSelected.includes(f.titleID))
        store.setAppVersionFilters(appVersionFilters)
        onSubmit();
      } else {
        setShowErrorDialog(true);
      }
    }, [formCanSave, store, segments, onSubmit, versionFilters, GamesSelected]);

    return (
      <Fragment>
        <Dialog open={showErrorDialog} disableBackdropClick={true} disableEscapeKeyDown={true}>
          <DialogTitle>{"Cannot Save Destination Config!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We found errors in your destination config, please resolve all errors before continuing.
            </DialogContentText>
            {errors.map((err, i) => (
              <Alert key={i} severity="error">{err}</Alert>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowErrorDialog(false)} color="primary">
              Okay
          </Button>
          </DialogActions>
        </Dialog>
        <Typography variant="h4" paragraph >Post Destination</Typography>
        <Box paddingBottom={2}>
          <Typography variant="h5" paragraph >SKU Filter</Typography>
          <Typography variant="body1" paragraph >Apply an SKU filter for this message on all destinations.</Typography>
          <FormControlLabel
            control={<Switch checked={!!store.post.SKUFilter} onChange={toggleFilter} />}
            label="Enable SKU Filtering"
          />
          <Collapse in={!!store.post.SKUFilter} timeout="auto" unmountOnExit>
            <RadioGroup aria-label="gender" name="gender1" value={store.post.SKUFilter} onChange={(event) => store.setSKUFilter(event.target.value as SKUFilter)}>
              <FormControlLabel value={SKUFilter.Ios} control={<Radio />} label="Send only to iOS" />
              <FormControlLabel value={SKUFilter.Android} control={<Radio />} label="Send only to Android" />
            </RadioGroup>
          </Collapse>
        </Box>
        <Typography variant="h5" paragraph >Destination Segments</Typography>
        {(games.gamesLoading || pending) && <LoadingIndicator />}
        {!games.gamesLoading && !pending && (
          <Fragment>
            <Typography variant="body1" paragraph>
              Please select Game(s) {"&"} Player Segment(s) to deliver this post
              to.
            </Typography>
            <Box paddingY={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <GlobalSelectionCard
                    savedSegments={segments}
                    setSegments={(segments) =>
                      setSegments(uniqBy(segments, "Id"))
                    }
                    globalSegments={result?.globalSegments ?? {}}
                  />
                </Grid>
                {games.availableGames.map((game, index) => (
                  <Grid item xs={12} md={3} key={index}>
                    <GameSelectionCard
                      game={game}
                      gameSegments={getSegmentsForGame(game.titleID)}
                      savedSegments={segments}
                      setSegments={(segments) =>
                        setSegments(uniqBy(segments, "Id"))
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box paddingY={2}>
              <Typography variant="h5" paragraph >App Version Filters</Typography>
              {GamesSelected.length < 1 && (
                <Typography variant="body1">You must select at least 1 segment in order to filter this message by app versions</Typography>
              )}
              <Grid container spacing={2}>
                {GamesSelected.map(title => (
                  <Grid item xs={12} md={3} key={title}>
                    <Paper>
                      <Box padding={2}>
                        <Typography variant="h6" paragraph>
                          {games.availableGames.find((g) => g.titleID === title)?.displayName}
                        </Typography>
                        <Box paddingY={1} >
                          <InputLabel id="demo-simple-select-label">Filter Mode</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={AppVersionFilterModeForGame(title)}
                            onChange={(event) => setAppVersionFilterModeForGame(title, event.target.value)}
                          >
                            <MenuItem value={AppVersionFilterFilterMode.None}>None</MenuItem>
                            <MenuItem value={AppVersionFilterFilterMode.Equal}>Equal To</MenuItem>
                            <MenuItem value={AppVersionFilterFilterMode.NotEqual}>Not Equal To</MenuItem>
                            <MenuItem value={AppVersionFilterFilterMode.Greater}>Greater Than</MenuItem>
                            <MenuItem value={AppVersionFilterFilterMode.Less}>Less Than</MenuItem>
                          </Select>
                          <br />
                          <br />
                          <Collapse
                            in={AppVersionFilterModeForGame(title) !== AppVersionFilterFilterMode.None}
                            timeout="auto"
                            unmountOnExit>
                            <InputLabel id="demo-simple-select-label">Filter Value</InputLabel>
                            <TextField
                              id="standard-basic"
                              value={AppVersionFilterValueForGame(title)}
                              onChange={(event) => setAppVersionFilterValueForGame(title, event.target.value)} />
                          </Collapse>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <FormAction
              disabled={segments.length < 1}
              onClick={submitForm}
              isModal={isModal}
            />
          </Fragment>
        )}
      </Fragment>
    );
  });