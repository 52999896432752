import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { GameFormProps, GameFormState } from "./GameForm.types";
import { useForm, Controller } from "react-hook-form";
import React, { useCallback, useState } from "react";
import {
  fieldDescriptions,
  cloudProviders,
  defaultValues,
} from "./GameForm.const";
import { Ratings } from "@halfbrick/network-effect-web-shared";
import { setGameData } from "Domains/Games/async";
import { useGamesProvider } from "Providers/GamesProvider";

export const GameForm = ({ onClose, game, id }: GameFormProps) => {
  const firebase = useFirebaseProvider();
  const gamesProvider = useGamesProvider();

  const [isFirebaseApp, setFirebaseApp] = useState(
    Boolean(game?.cloudProvider === "firebase")
  );

  const { handleSubmit, control, errors } = useForm<GameFormState>({
    defaultValues: game ?? defaultValues,
  });

  const submitGame = useCallback(
    async (data: GameFormState) => {
      try {
        await setGameData(undefined, firebase.functions, {
          id,
          gameData: {
            Id: id ?? "",
            ...data
          }
        });
        gamesProvider.refreshGames();
      } catch (error) {
        console.log("An error occured updating the post: ", error);
      } finally {
        onClose();
      }
    },
    [firebase.functions, id, onClose, gamesProvider]
  );

  return (
    <form onSubmit={handleSubmit(submitGame)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            id="titleID"
            name="titleID"
            control={control}
            rules={{
              required: {
                value: true,
                message: "A Title ID is required",
              },
            }}
            as={
              <TextField
                helperText={
                  errors?.titleID?.message ??
                  fieldDescriptions.titleID.helperText
                }
                error={Boolean(errors?.titleID?.message)}
                label={fieldDescriptions.titleID.label}
                fullWidth
              />
            }
          />
        </Grid>
        {!isFirebaseApp && (
          <Grid item xs={12}>
            <Controller
              id="APIKey"
              name="APIKey"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "An API key is required",
                },
              }}
              as={
                <TextField
                  helperText={
                    errors?.APIKey?.message ??
                    fieldDescriptions.APIKey.helperText
                  }
                  error={Boolean(errors?.APIKey?.message)}
                  label={fieldDescriptions.APIKey.label}
                  fullWidth
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            id="rating"
            name="rating"
            control={control}
            render={({ value, ref, onChange, ...rest }) => {
              return (
                <TextField
                  helperText={fieldDescriptions.rating.helperText}
                  label={fieldDescriptions.rating.label}
                  inputRef={ref}
                  value={value}
                  fullWidth
                  select
                  onChange={(e) => {
                    onChange(e);
                  }}
                  {...rest}
                >
                  {Array.from(Ratings, ([key, value]) => (
                    <MenuItem key={value.RatingKey} value={key}>
                      {value.RatingName}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            id="cloudProvider"
            name="cloudProvider"
            control={control}
            render={({ value, ref, onChange, ...rest }) => {
              return (
                <TextField
                  helperText={fieldDescriptions.cloudProvider.helperText}
                  label={fieldDescriptions.cloudProvider.label}
                  inputRef={ref}
                  value={value}
                  fullWidth
                  select
                  onChange={(e) => {
                    setFirebaseApp(e.target.value === "firebase");
                    onChange(e);
                  }}
                  {...rest}
                >
                  {cloudProviders.map((provider) => (
                    <MenuItem key={provider} value={provider}>
                      {provider}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            id="serviceAccount"
            name="serviceAccount"
            control={control}
            as={
              <TextField
                helperText={fieldDescriptions.serviceAccount.helperText}
                label={fieldDescriptions.serviceAccount.label}
                fullWidth
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            id="publishToGameRemoteConfigKey"
            name="publishToGameRemoteConfigKey"
            control={control}
            as={
              <TextField
                helperText={fieldDescriptions.publishToGameRemoteConfigKey.helperText}
                label={fieldDescriptions.publishToGameRemoteConfigKey.label}
                fullWidth
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            id="publishToGameRemoteConfig"
            name="publishToGameRemoteConfig"
            control={control}
            defaultValue={defaultValues.publishToGameRemoteConfig}
            render={(props) => (
              <FormControl error={Boolean(errors?.publishToGameRemoteConfig?.message)}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    }
                    label={fieldDescriptions.publishToGameRemoteConfig.label}
                  />
                </FormGroup>
                <FormHelperText>
                  {fieldDescriptions.publishToGameRemoteConfig.helperText}
                </FormHelperText>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            id="displayName"
            name="displayName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "A display name is required",
              },
            }}
            as={
              <TextField
                helperText={
                  errors?.displayName?.message ??
                  fieldDescriptions.displayName.helperText
                }
                error={Boolean(errors?.displayName?.message)}
                label={fieldDescriptions.displayName.label}
                fullWidth
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            id="logoUrl"
            name="logoUrl"
            control={control}
            rules={{
              required: {
                value: true,
                message: "A logo url is required",
              },
            }}
            as={
              <TextField
                helperText={
                  errors?.logoUrl?.message ??
                  fieldDescriptions.logoUrl.helperText
                }
                error={Boolean(errors?.logoUrl?.message)}
                label={fieldDescriptions.logoUrl.label}
                fullWidth
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            id="enabled"
            name="enabled"
            control={control}
            defaultValue={defaultValues.enabled}
            render={(props) => (
              <FormControl error={Boolean(errors?.enabled?.message)}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    }
                    label={fieldDescriptions.enabled.label}
                  />
                </FormGroup>
                <FormHelperText>
                  {fieldDescriptions.enabled.helperText}
                </FormHelperText>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            id="unity"
            name="unity"
            control={control}
            defaultValue={defaultValues.unity}
            render={(props) => (
              <FormControl error={Boolean(errors?.unity?.message)}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    }
                    label={fieldDescriptions.unity.label}
                  />
                </FormGroup>
                <FormHelperText>
                  {fieldDescriptions.unity.helperText}
                </FormHelperText>
              </FormControl>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        <Grid item xs={12}>
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
