export type ConnectedDeployment = {
  Name: string;
  serviceAccountJSON: string;
  connectionIntent: connectionIntent;
};

export type ParsedConnectedDeployment = ConnectedDeployment & {
  id: string;
};

export enum connectionIntent {
  Promote = "promote",
  Demote = "demote",
}
