export const firebaseConfig = {
  apiKey: "AIzaSyBpAd1W10rL0aADGsQuySXdhNdFjYt7MOc",
  authDomain: "martech-dashboard.firebaseapp.com",
  databaseURL: "https://martech-dashboard.firebaseio.com",
  projectId: "martech-dashboard",
  storageBucket: "martech-dashboard.appspot.com",
  messagingSenderId: "975714418112",
  appId: "1:975714418112:web:eace4888b2b45876acdc25",
  measurementId: "G-67ZYKMXNY2",
}; 
