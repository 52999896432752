/**
 * ESRB Ratings Constants
 *
 * this modules exports types and a map containing the
 * ESRB ratings and their associated info e.g minimum age and description
 */

/**
 * the ESRB Rating of this Game
 */
export enum ESRBRating {
  A = "A",
  E = "E",
  E10 = "E10",
  M = "M",
  T = "T",
}

/**
 * Rating Type
 */
export type Rating = {
  RatingName: string;
  RatingKey: string;
  Description: string;
  minAge: number;
};

/**
 * Rating Map
 */
const Ratings = new Map<string, Rating>();
// E - Everyone
Ratings.set(ESRBRating.E, {
  RatingName: "Everyone",
  RatingKey: "E",
  Description:
    "Content is generally suitable for all ages. May contain minimal cartoon, fantasy or mild violence and/or infrequent use of mild language.",
  minAge: 0,
} as Rating);

// E10 - Everyone 10+
Ratings.set(ESRBRating.E10, {
  RatingName: "Everyone 10+",
  RatingKey: "E10",
  Description:
    "Content is generally suitable for ages 10 and up. May contain more cartoon, fantasy or mild violence, mild language and/or minimal suggestive themes.",
  minAge: 10,
} as Rating);

// T - Teen
Ratings.set(ESRBRating.T, {
  RatingName: "Teen",
  RatingKey: "T",
  Description:
    "Content is generally suitable for ages 13 and up. May contain violence, suggestive themes, crude humor, minimal blood, simulated gambling and/or infrequent use of strong language.",
  minAge: 13,
} as Rating);

// M - Mature
Ratings.set(ESRBRating.M, {
  RatingName: "Mature",
  RatingKey: "M",
  Description:
    "Content is generally suitable for ages 17 and up. May contain intense violence, blood and gore, sexual content and/or strong language.",
  minAge: 17,
} as Rating);

// A - Adults Only
Ratings.set(ESRBRating.A, {
  RatingName: "Adults Only",
  RatingKey: "A",
  Description:
    "Content suitable only for adults ages 18 and up. May include prolonged scenes of intense violence, graphic sexual content and/or gambling with real currency.",
  minAge: 18,
} as Rating);

export { Ratings };
