import React, { ComponentType, Fragment, useState } from "react";
import { PreviewSentimentProps } from "./PreviewSentiment.types";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import {
  ListItemText,
  ListItem,
  Collapse,
  Chip,
  Grid,
  Box,
} from "@material-ui/core";

export const PreviewSentiment: ComponentType<PreviewSentimentProps> = observer(
  ({ sentiment }: PreviewSentimentProps) => {
    const [open, setOpen] = useState(false);

    return (
      <Fragment>
        <ListItem
          onClick={() => setOpen((prevProps) => !prevProps)}
          disableGutters
          button
          dense
        >
          <ListItemText
            primary={`${sentiment.length} Sentiment Value${
              sentiment.length !== 1 ? "s" : ""
            }`}
          />
          {Boolean(sentiment.length) &&
            (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {Boolean(sentiment.length) && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginY={1}>
              <Grid container spacing={1}>
                {sentiment.map((name, key) => {
                  return (
                    <Grid key={key} item>
                      <Chip label={name} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Collapse>
        )}
      </Fragment>
    );
  }
);
