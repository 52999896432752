import React, { ComponentType, Fragment, useState } from "react";
import { PreviewLanguageProps } from "./PreviewLanguages.types";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import {
  ListItemText,
  Collapse,
  ListItem,
  Grid,
  Chip,
  Box,
} from "@material-ui/core";
import countriesDB from "countries-db";
import { CountriesFilterMode } from "../../RichInboxProvider";

export const PreviewLanguages: ComponentType<PreviewLanguageProps> = observer(
  ({ languages, countries, languageFilter }) => {

    const [countriesOpen, setCountriesOpen] = useState(false);
    const [languagesOpen, setLanguagesOpen] = useState(false);

    function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
      <Fragment>
        <ListItem
          onClick={() => countries?.filterMode !== CountriesFilterMode.All && setCountriesOpen((prev) => !prev)}
          disableGutters
          button
          dense
        >
          <ListItemText
            primary={`${capitalize(countries?.filterMode)} Countries`}
          />
          {countries?.filterMode !== CountriesFilterMode.All && (countriesOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {countries?.filterMode !== CountriesFilterMode.All && (
          <Collapse in={countriesOpen} timeout="auto" unmountOnExit>
            <Box marginY={1}>
              <Grid container spacing={1}>
                {countries!.filterValues!.map((v, key) => {
                  return (
                    <Grid key={key} item>
                      <Chip label={countriesDB.getCountry(v, 'name')} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Collapse>
        )}
        <ListItem
          disableGutters
          dense
        >
          <ListItemText
            primary={`Language Filter: ${capitalize(languageFilter?.filterMode)}`}
          />
        </ListItem>
        
        <ListItem
          onClick={() => setLanguagesOpen((prev) => !prev)}
          disableGutters
          button
          dense
        >
          <ListItemText
            primary={`${languages.length} Language${languages.length !== 1 ? "s" : ""
              }`}
          />
          {languages.length > 0 && (languagesOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {languages.length > 0 && (
          <Collapse in={languagesOpen} timeout="auto" unmountOnExit>
            <Box marginY={1}>
              <Grid container spacing={1}>
                {languages.map(({ name }, key) => {
                  return (
                    <Grid key={key} item>
                      <Chip label={name} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Collapse>
        )}
      </Fragment>
    );
  }
);
