import encodeBlock from "./encodeBlock";
import { blockInlineStyles } from "./blockInlineStyles";


export const convertToBlocksUnity = (rawState) => {
  let result = rawState.blocks
    .map((block) => {
      const converted = blockInlineStyles(
        encodeBlock(block),
      );
      block.rawText = block.text
      block.text = converted.replace(/<stripcolor /g, "<")
        .replace(/<\/stripcolor>/g, "</color>")
        .replace(/<stripsize /g, "<")
        .replace(/<\/stripsize>/g, "</size>")
        .replace(/"/g, "")
      return block
    })
  rawState.blocks = result;
  return rawState
};