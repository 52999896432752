import React, { FunctionComponent, useState } from "react";
import { ContentPanel } from "./ContentPanel";
import { Box } from "@material-ui/core";
import { NavMenu } from "./NavMenu";
import { NavBar } from "./NavBar";

export const AppLayout: FunctionComponent = ({ children }) => {
  const [navMenuOpen, setNavMenu] = useState(true);

  return (
    <Box component="div" display="flex">
      <NavBar toggleNavMenu={() => setNavMenu((prevProps) => !prevProps)} />
      <NavMenu open={navMenuOpen} />
      <ContentPanel navMenuOpen={navMenuOpen}>{children}</ContentPanel>
    </Box>
  );
};
