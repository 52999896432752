import { DateToISO } from "../RichInbox.utilities";
import { functions as fn } from "firebase";
import { add } from "date-fns";
import {
  richInboxPostToPost,
  RichInboxPostType,
} from "../components/RichInboxProvider";
import { NOOP } from "@halfbrick/network-effect-web-shared";

export const updatePost = async (
  functions: fn.Functions,
  richinboxPost: RichInboxPostType,
) => {
  try {
    const parsedPost = richInboxPostToPost(richinboxPost);

    const publishPost = {
      ...parsedPost,
      publishing: {
        ...parsedPost.publishing,
        publishDateTime: DateToISO(
          parsedPost.publishing.publishDateTime ?? new Date()
        ),
        expiry: DateToISO(
          parsedPost.publishing.expiry ?? add(new Date(), { days: 365 })
        ),
      },
      lastUpdated: DateToISO(new Date()),
    };

    const tasks:Promise<void>[] = [];
    
    // we persist the tags of this post so we can suggest them next time
    const addTags = functions.httpsCallable("addTags");
    tasks.push(addTags({ tags: publishPost.tags }).then(NOOP));

    // we persist the sentiment of this post so we can suggest them next time
    const addSentiment = functions.httpsCallable("addSentiment");
    tasks.push(addSentiment({ sentiment: publishPost.sentiment }).then(NOOP));

    // now we actually call the update function
    const update = functions.httpsCallable("updatePost");
    tasks.push(update({ post: publishPost, id: parsedPost.__id }).then(NOOP));

    await Promise.all(tasks);
  } catch (error) {
    console.log("[updatePost]: An error occured updating the posts", error);
  }
};
