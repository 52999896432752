import { firestore as fbFirestore } from "firebase";
import {
  ConnectedDeployment,
  ParsedConnectedDeployment,
} from "../ConnectedDeployments.types";

export const createConnection = async (
  _: any,
  firestore: fbFirestore.Firestore,
  data: ConnectedDeployment
) => {
  try {
    const connRef = await firestore
      .collection("connected-deployments")
      .add(data);
    (data as ParsedConnectedDeployment).id = connRef.id;
    return data;
  } catch (error) {
    console.log("error: ", error);
  }
};
