import { Redirect, Route, Switch } from "react-router-dom";
import { AppLayout } from "Components/AppLayout";
import { Games, RichInbox, Settings, Publisher, History } from "Domains";
import NotFoundPage from "Pages/NotFound";
import UsersPage from "Pages/Users";
import React from "react";

export const App = () => {
  return (
    <AppLayout>
      <Switch>
        <Route path="/richinbox">
          <RichInbox />
        </Route>
        <Route path="/games">
          <Games />
        </Route>
        <Route path="/users">
          <UsersPage />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/publisher">
          <Publisher />
        </Route>
        <Route path="/history">
          <History />
        </Route>
        <Route exact path="/">
          <Redirect to="/richinbox" />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </AppLayout>
  );
};
