import { FirebaseProviderContext } from "./FirebaseProvider.context";
import { FirestoreProvider } from "@react-firebase/firestore";
import React, { FunctionComponent, useMemo } from "react";
import firebase, { app, initializeApp } from "firebase";
import { firebaseConfig } from "firebase.config";
import LoginPage from "Pages/Login/Login";
import "firebase/functions";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  IfFirebaseUnAuthed,
  IfFirebaseAuthed,
} from "@react-firebase/auth";

export const FirebaseProvider: FunctionComponent = ({ children }) => {
  const firebaseApp = useMemo(() => {
    try {
      const defaultApp = initializeApp(firebaseConfig);
      const functions = defaultApp.functions();
      //functions.useFunctionsEmulator("http://localhost:6060");
      return {
        analytics: defaultApp.analytics(),
        functions,
        storage: defaultApp.storage(),
        firestore: defaultApp.firestore(),
        remoteConfig: defaultApp.remoteConfig(),
      };
    } catch (error) {
      if (error?.code === "app/duplicate-app") {
        return {
          analytics: app().analytics(),
          functions: app().functions(),
          storage: app().storage(),
          firestore: app().firestore(),
          remoteConfig: app().remoteConfig(),
        };
      }
      throw Error(`[FirebaseProvider] error: ${error}`);
    }
  }, []);

  return (
    <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
      <IfFirebaseAuthed>
        {() => (
          <FirebaseProviderContext.Provider value={firebaseApp}>
            <FirestoreProvider {...firebaseConfig} firebase={firebase}>
              {children}
            </FirestoreProvider>
          </FirebaseProviderContext.Provider>
        )}
      </IfFirebaseAuthed>
      <IfFirebaseUnAuthed>{() => <LoginPage />}</IfFirebaseUnAuthed>
    </FirebaseAuthProvider>
  );
};
