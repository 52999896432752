import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import BackupIcon from '@material-ui/icons/Backup';
import { useStyles } from './PostMigrationControls.styles';
import { useConnectedDeploymentsProvider } from 'Providers/ConnectedDeploymentsProvider';
import { connectionIntent } from 'Domains/Settings/views/ConnectedDeployments/ConnectedDeployments.types';
import { useAsyncTask } from 'react-hooks-async';
import { migratePost } from './async/migratePost';
import { ConfirmButton } from 'Components/ConfirmButton';
import { useFirebaseProvider } from 'Providers/FirebaseProvider';
import { useRichInboxProvider } from 'Domains/RichInbox/components/RichInboxProvider';
import { toJS } from 'mobx';

export const PostMigrationControls = () => {
    const classes = useStyles()
    const { functions } = useFirebaseProvider()
    const { loading, demotionConnections, promotionConnections, } = useConnectedDeploymentsProvider();
    const migrationTask = useAsyncTask(migratePost)
    const store = useRichInboxProvider()

    useEffect(() => {
        if (Boolean(migrationTask.result)) {
            setResultsLoaded(true)
        }
    }, [migrationTask.result])

    const [resultsLoaded, setResultsLoaded] = useState(false)

    const [dialogOpen, setDialogOpen] = useState(false)

    const [migrationType, setMigrationType] = useState(connectionIntent.Promote)
    const [migrationDestination, setMigrationDestination] = useState<string | undefined>()

    const availableOptions = useMemo(() =>
        migrationType === connectionIntent.Promote ? promotionConnections : demotionConnections,
        [migrationType, promotionConnections, demotionConnections])
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Button
                    disabled={loading || !Boolean(promotionConnections.concat(demotionConnections).length)}
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                    className={classes.promoteBtn}
                    endIcon={<BackupIcon />}
                >
                    Migrate Post
                </Button>
            </Grid>
            <Dialog open={dialogOpen} onClose={() => {
                setDialogOpen(false);
                setResultsLoaded(false);
            }}>
                <DialogTitle>Migrate Post</DialogTitle>
                <DialogContent>
                    {!migrationTask.started && resultsLoaded && (
                        <Box paddingBottom={2}>
                            <Typography paragraph variant="h5">Success! Your post was migrated as a draft.</Typography>
                            <Typography paragraph variant="body1">You'll need to make sure you select its destinations before you make it live</Typography>
                            <Button variant="contained" href={migrationTask.result} target="_blank">Go to your migrated post</Button>
                        </Box>
                    )
                    }
                    {migrationTask.started && !resultsLoaded && (<CircularProgress />)}
                    {!migrationTask.started && !resultsLoaded && (
                        <>
                            <DialogContentText>
                                When Migrating a post pleas consider that the this will take the
                                current post as is and insert it into the chosen environment.
                            </DialogContentText>
                            <FormControl>
                                <InputLabel id="select-migration-type-label">Migration Type</InputLabel>
                                <Select
                                    labelId="select-migration-type-label"
                                    id="select-migration-type"
                                    value={migrationType}
                                    onChange={(event) => setMigrationType(event.target.value as connectionIntent)}
                                >
                                    <MenuItem value={connectionIntent.Promote}>Promote</MenuItem>
                                    <MenuItem value={connectionIntent.Demote}>Demote</MenuItem>
                                </Select>
                            </FormControl>
                            {!Boolean(availableOptions.length) && (
                                <Typography>There are no Connected Deployments Configured for {migrationType} migrations</Typography>
                            )}
                            {Boolean(availableOptions.length) && (
                                <FormControl>
                                    <InputLabel id="select-migration-dest-label" >Migration Destination</InputLabel>
                                    <Select
                                        labelId="select-migration-dest-label"
                                        id="select-migration-dest"
                                        value={migrationDestination}
                                        onChange={(event) => { setMigrationDestination(event.target.value as string) }}
                                    >
                                        {availableOptions.map((conn, i) =>
                                            <MenuItem key={i} value={conn.id}>{conn.Name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            )}
                            <DialogActions>
                                <Button variant="contained" onClick={() => setDialogOpen(false)}>Close</Button>
                                <ConfirmButton
                                    disabled={!Boolean(migrationDestination)}
                                    onConfirm={() => {
                                        migrationTask.start(functions, migrationDestination!, toJS(store.post))
                                    }}
                                >
                                    Migrate
                                </ConfirmButton>
                            </DialogActions>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </Grid>
    )
}