import { functions as fn } from "firebase";
import { FetchPublishedVersionsResult } from "../Publisher.types";

export async function fetchPublishedVersions(_: any, functions: fn.Functions):Promise<FetchPublishedVersionsResult> {
  try {
    const getCall = functions.httpsCallable("listPublishedVersions");
    var data = await getCall();
    if (data === undefined || data === null || data.data === undefined || data.data === null) {
      return {
          error: "No data returned from call",
          versions: []
      };
    }
    return {
      error: undefined,
      versions: data.data.versions
    };

  } catch (error) {
    console.log("[fetchPublishedVersions]: An error has occurred fetching published versions", error);
    return {
        error: error.toString(),
        versions: []
    };
  }
};