import { SegmentListItemProps } from "./GlobalSegmentListItem.types";
import React, { ComponentType, Fragment, useState } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AllGamesImage from "Media/AllGames.png";
import {
  ListItemAvatar,
  ListItemText,
  ListItem,
  Collapse,
  Avatar,
  List,
} from "@material-ui/core";

export const GlobalSegmentListItem: ComponentType<SegmentListItemProps> = ({
  segments,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <ListItem
        disableGutters
        dense
        button
        onClick={() => setOpen((prev) => !prev)}
      >
        <ListItemAvatar>
          <Avatar alt={"All Games"} src={AllGamesImage} />
        </ListItemAvatar>
        <ListItemText
          primary={`All Games - ${Object.entries(segments).length} Segment${
            Object.entries(segments).length > 1 ? "s" : ""
          }`}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Object.entries(segments).map(([name], i) => (
            <ListItem key={i}>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};
