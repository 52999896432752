import React from "react";
import {
  Container,
  Typography,
  makeStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import firebase from "firebase/app";
import overlay from "../../Media/overlay.png";
import logo from "../../Media/logo.png";
import msButton from "../../Media/ms-button.svg";
import { useHasLoginInfo } from "../../Hooks/useHasLoginInfo";

const useStyles = makeStyles((theme) => ({
  rootbg: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${overlay})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    position: "fixed",
  },
  card: {
    textAlign: "center",
  },
  logo: {
    maxHeight: "3em",
  },
  title: {
    color: theme.palette.common.white,
  },
}));

function LoginPage() {
  const { hasLoginInfo } = useHasLoginInfo();

  const classes = useStyles();

  return (
    <Container className={classes.rootbg} maxWidth={false}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={8} className={classes.card}>
          {!hasLoginInfo && (
            <>
              <img className={classes.logo} alt="logo" src={logo}></img>
              <Typography variant="h1" className={classes.title}>
                Network Effect Dashboard
              </Typography>
              <br />
              <img
                alt="Login with Microsoft"
                src={msButton}
                onClick={() => {
                  var provider = new firebase.auth.OAuthProvider(
                    "microsoft.com"
                  );
                  provider.setCustomParameters({
                    prompt: "consent",
                    tenant: "1221a61d-2abd-422b-ad3a-0e71dc27b3e9",
                  });
                  firebase.auth().signInWithPopup(provider);
                }}
              ></img>
            </>
          )}
          {hasLoginInfo && (
            <>
              <CircularProgress />
              <Typography
                variant="h3"
                style={{ color: "#ffffff", fontWeight: "bold" }}
              >
                Loading...
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default LoginPage;
