import { firestore as fbFirestore } from "firebase";

export const fetchSettings = async (
  _: any,
  firestore: fbFirestore.Firestore
) => {
  try {
    const settingsRef = firestore
      .collection("settings")
      .doc("application-settings");
    const doc = await settingsRef.get();
    if (doc.exists) {
      return doc.data();
    } else {
        console.log("No Settings Document Exists!");
    }
  } catch (error) {
    console.log("error: ", error);
  }
};
