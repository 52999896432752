import { firestore as fbFirestore } from "firebase";
import {
  ConnectedDeployment,
  ParsedConnectedDeployment,
} from "../ConnectedDeployments.types";

export const updateConnection = async (
  _: any,
  firestore: fbFirestore.Firestore,
  id: string,
  data: ConnectedDeployment
) => {
  try {
    const connRef = firestore.collection("connected-deployments").doc(id);
    await connRef.set(data, { merge: true });
    (data as ParsedConnectedDeployment).id = id;
    return data;
  } catch (error) {
    console.log("error: ", error);
  }
};
