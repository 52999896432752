import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  loadingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
  },
  SuccessIconAvator: {
    backgroundColor: theme.palette.success.dark,
    width: 150,
    height: 150,
  },
  SuccessIcon: {
    color: theme.palette.common.white,
    fontSize: "5.2875rem",
  },
}));
