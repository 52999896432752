import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mediaButton: {
    alignItems: "center",
    background: "transparent",
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
    boxShadow: theme.shadows[4],
    display: "flex",
    flexShrink: 1,
    height: "2rem",
    justifyContent: "center",
    minWidth: "unset",
    outline: "none",
    overflow: "hidden",
    transition: "all .25s ease-in-out",
    width: "2rem",

    "& img": {
      objectFit: "cover",
      width: "100%",
    },
  }
}));
