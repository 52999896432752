import { fetchGameById } from "Domains/Games/async";
import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { useAsyncRun, useAsyncTask } from "react-hooks-async";
import { Box, CircularProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { GameForm } from "Domains/Games/components";
import React, { useCallback } from "react";

export const GameEdit = () => {
  const firebase = useFirebaseProvider();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const fetchGameTask = useAsyncTask(fetchGameById);

  useAsyncRun(fetchGameTask, firebase.functions, id);
  const { result, pending } = fetchGameTask;

  const handleClose = useCallback(() => {
    history.push("/games");
  }, [history]);

  if (pending) {
    return (
      <Box display="flex" justifyContent="center" marginTop={10}>
        <CircularProgress />
      </Box>
    );
  }

  return <GameForm game={result as any} id={id} onClose={handleClose} />;
};
