import React from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MUIDataTable from "mui-datatables";
import { FirestoreCollection } from "@react-firebase/firestore";
import { useDialogState } from "../../Hooks/useDialogState/index.js";
import { GameForm } from "Domains/Games/components";

const columns = [
  { name: "uid", label: "UID" },
  { name: "role", label: "Role" },
];

const options = {
  print: false,
  download: false,
  filter: false,
};

function UsersPage() {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  return (
    <Box>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography paragraph variant="h2">
            Manage Users
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={openDialog}>
            Add User
          </Button>
        </Grid>
      </Grid>
      <FirestoreCollection path="/users/" limit={5}>
        {({ isLoading, value: results = [] }) => {
          return (
            <>
              {isLoading && <CircularProgress />}
              {!isLoading && results.length > 0 && (
                <MUIDataTable
                  data={results}
                  columns={columns}
                  options={options}
                />
              )}
              {!isLoading && results.length === 0 && (
                <MUIDataTable data={[]} columns={columns} options={options} />
              )}
            </>
          );
        }}
      </FirestoreCollection>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h3">Add New User</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <GameForm onClose={closeDialog} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default UsersPage;
