import { Post } from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import { firestore, functions as fn } from "firebase";
import { EnsureRawJSONPostISOIsDate } from "../RichInbox.utilities";

export const fetchPosts = async (_: any, functions: fn.Functions) => {
  try {
    const getPosts = functions.httpsCallable("ListPosts");
    const { data } = await getPosts();

    return data.documents
      .map((post: any) => EnsureRawJSONPostISOIsDate(post))
      .map((post: any) => ({
        ...post,
        documentId: post.id,
      }))
      .map((post: Post | any) => {
        post.games = post.segments
          .map((s) => s.titleID)
          .filter((v, i, a) => a.indexOf(v) === i);
        return post;
      }) as firestore.DocumentData[];
  } catch (error) {
    console.log("[fetchPosts]: An error occured fetching the posts", error);
  }
};
