import React, { Fragment, useCallback } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Typography, Button, Grid, Box } from "@material-ui/core";
import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { fetchPosts } from "Domains/RichInbox/async/fetchPosts";
import { LoadingIndicator } from "Components/LoadingIndicator";
import { useAsyncRun, useAsyncTask } from "react-hooks-async";
import { overviewColumns } from "./RichInboxOverviewColumns";
import MUIDataTable from "mui-datatables";
import last from "lodash.last";
import { useGamesProvider } from "Providers/GamesProvider";
import { fetchPendingChanges } from "Domains/Publisher/async";

export const RichInboxOverview = () => {
  const { gamesLoading, availableGames } = useGamesProvider()
  const firebase = useFirebaseProvider();
  const { url } = useRouteMatch();
  const history = useHistory();

  const fetchPostsTask = useAsyncTask(fetchPosts);
  const fetchPendingChangesTask = useAsyncTask(fetchPendingChanges);

  useAsyncRun(fetchPostsTask, firebase.functions);
  useAsyncRun(fetchPendingChangesTask, firebase.functions);
  const { result, pending } = fetchPostsTask;

  const anyPending:boolean = pending || fetchPendingChangesTask.pending;

  const handleRowClick = useCallback(
    (row: string[]) => {
      const documentId = last(row);
      history.push(`${url}/${documentId}`);
    },
    [url, history]
  );

  return (
    <Fragment>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Typography variant="h2">Rich Inbox</Typography>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to={`${url}/new`}
            variant="contained"
            color="primary"
          >
            New Post
          </Button>
        </Grid>
      </Grid>
      {anyPending && <LoadingIndicator />}
      {!anyPending && !gamesLoading && result && fetchPendingChangesTask.result && (
        <Box paddingY={2}>
          <MUIDataTable
            title=""
            data={result}
            columns={overviewColumns(result, availableGames, fetchPendingChangesTask.result)}
            options={{
              sortOrder: {
                name: "lastUpdated",
                direction: "desc"
              },
              print: false,
              download: false,
              filter: true,
              selectableRows: "none",
              onRowClick: handleRowClick,
            }}
          />
        </Box>
      )}
    </Fragment>
  );
};
