import { ManageGeneralInformation } from "Domains/RichInbox/components/ManageGeneralInformation";
import { ManagePlacementType } from "Domains/RichInbox/components/ManagePlacementType";
import { ManageLocalisation } from "Domains/RichInbox/components/ManageLocalisation";
import { ManagePublishing } from "Domains/RichInbox/components/ManagePublishing";
import { ManageSegments } from "Domains/RichInbox/components/ManageSegments";
import { ManageContent } from "Domains/RichInbox/components/ManageContent";
import { PostPreviewer } from "Domains/RichInbox/components/PostPreviewer";

export const steps = [
  { label: "General Information", Component: ManageGeneralInformation },
  { label: "Select Placement", Component: ManagePlacementType },
  { label: "Localisation", Component: ManageLocalisation },
  { label: "Set Content", Component: ManageContent },
  { label: "Select Destination", Component: ManageSegments },
  { label: "Manage Publishing", Component: ManagePublishing },
  { label: "Review and Save", Component: PostPreviewer },
];
