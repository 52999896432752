import { MUIDataTableColumnDef } from "mui-datatables";

export const overviewColumns:MUIDataTableColumnDef[] = [
  { name: "user", label: "User" },
  { name: "action", label: "Action" },
  { name: "displayName", label: "Post" },
  { name: "message", label: "Message" },
  {
    name: "documentId",
    label: "ID",
    options: {
      display: false,
    },
  },
];

export const timestampDisplayOptions:Intl.DateTimeFormatOptions = {
  timeZoneName: "short"
};
