import { RichInboxProviderContext } from "./RichInboxProvider.context";
import { useContext } from "react";

export const useRichInboxProvider = () => {
  const context = useContext(RichInboxProviderContext);

  if (context === undefined) {
    throw Error(
      "useRichInboxProvider must be used within a RichInboxProviderContext"
    );
  }

  return context;
};
