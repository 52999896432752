import React from 'react'
import { Typography } from '@material-ui/core'

function NotFoundPage() {
  return (
    <Typography variant="h2">Page Not Found</Typography>
    )
}

export default NotFoundPage
