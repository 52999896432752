export enum AdminConfirmationType {
    RegenerateGameCollections = "regenerateGameCollections",
    ClearGameCollections = "clearGameCollections",
    RevertToPublishedState = "revertToPublishedState",
    RegenerateAllGameVersions = "regenerateAllGameVersions"
}

export type AdminConfirmationPopupData = {
    message:JSX.Element;
    buttonName:String;
    action:AdminConfirmationType;
}

export type AdminResultPopupData = {
    title:string;
    message:JSX.Element;
}
