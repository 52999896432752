import SettingsIcon from "@material-ui/icons/Settings";
import GameIcon from "@material-ui/icons/Gamepad";
import UserIcon from "@material-ui/icons/People";
import MailIcon from "@material-ui/icons/Mail";
import PublishIcon from "@material-ui/icons/Publish";
import HistoryIcon from "@material-ui/icons/History";
import { NavMenuLink } from "./NavMenu.types";
import React from "react";

export const effectsRoutes: NavMenuLink[] = [
  {
    to: "/richinbox",
    label: "Rich Inbox",
    icon: <MailIcon />,
  },
];

export const adminRoutes: NavMenuLink[] = [
  {
    to: "/publisher",
    label: "Publisher",
    icon: <PublishIcon />
  },
  {
    to: "/history",
    label: "History",
    icon: <HistoryIcon />
  },
  {
    to: "/games",
    label: "Games",
    icon: <GameIcon />,
  },
  {
    to: "/users",
    label: "Users",
    icon: <UserIcon />,
  },
  {
    to: "/settings",
    label: "Settings",
    icon: <SettingsIcon />,
  },
];
