import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  errorBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  promoteBtn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  demoteBtn: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  loadingBox: {
    marginTop: 80,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
  },
  SuccessIconAvator: {
    backgroundColor: theme.palette.success.dark,
    width: 150,
    height: 150,
  },
  WarningIconAvator: {
    backgroundColor: theme.palette.warning.dark,
    width: 150,
    height: 150,
  },
  SuccessIcon: {
    color: theme.palette.common.white,
    fontSize: "5.2875rem",
  },
}));
