import { useRichInboxProvider } from "Domains/RichInbox/components/RichInboxProvider";
import React, { ComponentType, Fragment, useCallback, useEffect, useState } from "react";
import { ManagePublishingProps } from "./ManagePublishing.types";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { FormAction } from "../FormAction";
import { observer } from "mobx-react-lite";
import Alert from "@material-ui/lab/Alert";
import {
  FormControlLabel,
  Typography,
  Collapse,
  Switch,
  Grid,
  Box,
  Input,
} from "@material-ui/core";
import { add, differenceInDays } from "date-fns";

export const ManagePublishing: ComponentType<ManagePublishingProps> = observer(
  ({ isModal = false, onSubmit }) => {
    const store = useRichInboxProvider();

    const [publishing, setPublishing] = useState(store.post.publishing);

    const injectDefaultExpiry = useCallback(() => {
      setPublishing((prevProps) => ({
        ...prevProps,
        expiry: add(prevProps.publishDateTime, { days: 365 }),
      }));
    }, []);

    useEffect(() => {
      if (!publishing.expiry) {
        injectDefaultExpiry();
      }
    }, [publishing.expiry, injectDefaultExpiry])

    const toggleScheduled = useCallback(() => {
      setPublishing((prevProps) => ({
        ...prevProps,
        isScheduled: !prevProps.isScheduled,
      }));
    }, []);

    const setPublishDateTime = useCallback((newDateTime: Date | null) => {
      setPublishing((prevProps) => ({
        ...prevProps,
        publishDateTime: newDateTime ?? prevProps.publishDateTime,
      }));
    }, []);


    const submitForm = useCallback(() => {
      store.setPost({ publishing });
      onSubmit();
    }, [store, publishing, onSubmit]);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      var expiry = add(publishing.publishDateTime, { days: Number(event.target.value) })
      setPublishing((prevProps) => ({
        ...prevProps,
        expiry: expiry,
      }));
    };

    return (
      <Fragment>
        <Typography variant="body1">
          Configure when this post will be visible to players in your selected
          destinations.
        </Typography>
        <Box paddingY={2}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={publishing.isScheduled === false}
                onChange={toggleScheduled}
              />
            }
            label="Publish Immediately (Once published in the Publisher)"
          />
          <Collapse in={publishing.isScheduled}>
            <Box paddingY={2}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <DatePicker
                    autoOk
                    orientation="landscape"
                    variant="static"
                    openTo="date"
                    value={publishing.publishDateTime}
                    onChange={setPublishDateTime}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TimePicker
                    autoOk
                    orientation="landscape"
                    variant="static"
                    openTo="hours"
                    value={publishing.publishDateTime}
                    onChange={setPublishDateTime}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Alert severity="warning">
                    the Date & Time listed here is relative to the player's
                    timezone on their device
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Typography id="input-slider" gutterBottom>
            Post Expiry (Days to stay published for)
      </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Input
                value={differenceInDays(publishing.expiry, publishing.publishDateTime)}
                margin="dense"
                onChange={handleInputChange}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 365,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <FormAction onClick={submitForm} isModal={isModal} />
      </Fragment>
    );
  }
);
