import { useContext } from "react"
import { InboxTagsProviderContext } from "./InboxTagsProvider.context";

export const useInboxTagsProvider = () => {
  const context = useContext(InboxTagsProviderContext);

  if(context === undefined){
    throw Error("useInboxTagsProvider must be used within a InboxTagsProvider");
  }

  return context;
}