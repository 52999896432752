import { Segment } from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import { GameSelectionCardProps } from "./GameSelectionCard.types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStyles } from "./GameSelectionCard.styles";
import React, { useCallback, useMemo } from "react";
import {
  CardContent,
  CardHeader,
  Typography,
  FormGroup,
  TextField,
  CardMedia,
  colors,
  Card,
  Box,
} from "@material-ui/core";
import { toJS } from "mobx";

export const GameSelectionCard = ({
  game,
  gameSegments,
  setSegments,
  savedSegments,
}: GameSelectionCardProps) => {

  // first we need to filter out the list of segments we have for this current game
  // make it a memo so we regenerate it every time the saved segments change
  const segmentsForGame = useMemo(() => {
    return toJS(savedSegments).filter(s => s.titleID === game.titleID)
  }, [savedSegments, game.titleID])

  const classes = useStyles();

  const handleChange = useCallback(
    (value: Segment[]) => {
      const stripped = savedSegments.filter(
        (segment) => segment.titleID !== game.titleID
      );
      if (value.length > 0) {
        const newSegments = stripped.concat(value);
        setSegments(newSegments);
      } else {
        setSegments(stripped);
      }
    },
    [game.titleID, savedSegments, setSegments]
  );

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        style={{ backgroundColor: colors.orange[900] }}
        image={game.logoUrl}
      ></CardMedia>
      <CardHeader title={game.displayName}></CardHeader>
      <CardContent>
        <Box paddingY={1}>
          <Typography>Segments:</Typography>
          <FormGroup>
            {gameSegments && (
              <Autocomplete
                multiple
                onChange={(_, value) => {
                  handleChange(value);
                }}
                value={segmentsForGame}
                options={gameSegments.filter(s => !segmentsForGame.includes(s))}
                getOptionLabel={(option) => option.Name}
                renderInput={({ id, ...rest }) => (
                  <TextField
                    {...rest}
                    key={id}
                    variant="standard"
                    label="Select Segments"
                    placeholder="Segments"
                  />
                )}
              />
            )}
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
};
