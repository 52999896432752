import { IfFirebaseAuthed } from "@react-firebase/auth";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import { NavBarProps } from "./Navbar.types";
import { useStyles } from "./Navbar.styles";
import { Button } from "@material-ui/core";
import firebase from "firebase/app";
import React from "react";

export const NavBar = ({ toggleNavMenu }: NavBarProps) => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleNavMenu}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Network Effect Manager
          </Typography>
          <IfFirebaseAuthed>
            {() => (
              <Button
                color="inherit"
                onClick={() => {
                  firebase.auth().signOut();
                }}
              >
                Logout
              </Button>
            )}
          </IfFirebaseAuthed>
        </Toolbar>
      </AppBar>
    </>
  );
};
