import { Post } from "@halfbrick/network-effect-schemas/dist/typescript/Post";
import { EnsureRawJSONPostISOIsDate } from "../RichInbox.utilities";
import { functions as fn } from "firebase";

export const fetchPostById = async (
  _: any,
  functions: fn.Functions,
  id: string
) => {
  try {
    const getPost = functions.httpsCallable("getPostByID");
    const { data } = await getPost({ id });
    const post = EnsureRawJSONPostISOIsDate(data);
    return { ...post, __id: id } as Post;
  } catch (error) {
    console.log("[fetchPostById]: An error occured fetching the post", error);
  }
};
