import { makeStyles, Theme, darken } from "@material-ui/core";

export const useStyles = makeStyles<
  Theme,
  {
    widthPercentage: number;
    buttonColor: string;
    textColor: string;
  }
>({
  root: {
    color: (props) => props.textColor,
    backgroundColor: (props) => props.buttonColor,
    width: (props) => `${props.widthPercentage}%`,

    "&:hover": {
      backgroundColor: (props) => darken(props.buttonColor, 0.2),
    },
  },
});
