import { PublishChangesRequest, PublishChangesResponse } from "@halfbrick/network-effect-web-shared";
import { functions as fn } from "firebase";

export async function publishSelectedChanges(_: any, functions: fn.Functions, request:PublishChangesRequest):Promise<PublishChangesResponse> {
  try {
    const getCall = functions.httpsCallable("publishChanges");
    var data = await getCall(request);
    if (data === undefined || data === null || data.data === undefined || data.data === null) {
      return {
          error: "No data returned from call"
      };
    }
    return data.data;

  } catch (error) {
    console.log("[publishSelectedChanges]: An error occured while publishing", error);
    return {
        error: error.toString()
    };
  }
};