import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    media: {
      border: `1px solid ${theme.palette.grey[700]}`,
      borderRadius: theme.shape.borderRadius,
      height: "8rem",
      marginRight: theme.spacing(2),
      overflow: "hidden",
      width: "8rem",

      "& img": {
        objectFit: "cover",
        width: "100%",
      },

      "& svg": {
        height: "100%",
        width: "100%",
      },
    },
  })
);
