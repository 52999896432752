import { functions as fn } from "firebase";
import {
  RichInboxPostType,
} from "../components/RichInboxProvider";

export const deletePost = async (
  functions: fn.Functions,
  post: RichInboxPostType
) => {
  try {
    const del = functions.httpsCallable("deletePost");
    await del({ id: post.__id });
  } catch (error) {
    console.log("[deletePost]: An error occured deleting the posts", error);
  }
};
