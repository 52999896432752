import { useRichInboxProvider } from "Domains/RichInbox/components/RichInboxProvider";
import { Box, Button, Typography } from "@material-ui/core";
import { useStyles } from "./InboxBanner.styles";
import { observer } from "mobx-react-lite";
import React, {
  ComponentType,
  Fragment,
  useState,
} from "react";
import { ImageGalleryModal } from "Components/ImageGalleryModal";

export const InboxBanner: ComponentType = observer(() => {
  const store = useRichInboxProvider();
  const classes = useStyles();

  const [galleyOpen, setGalleyOpen] = useState(false)

  return (
    <Fragment>
      <Typography variant="h5" paragraph>
        Inbox Banner Image
      </Typography>
      <Typography variant="body1" paragraph>
        Inbox Post types can have an additional Banner image associated with the
        post.
      </Typography>
      <Box
        justifyContent="flex-start"
        alignItems="center"
        display="flex"
        width="100%"
      >
        <Box
          className={classes.media}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {store.post.bannerURL ? <img src={store.post.bannerURL} alt="avatar" />
            : (<Typography align="center" variant="body2">
              No Preview Available
            </Typography>
            )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Button variant="outlined" component="span" onClick={() => setGalleyOpen(true)}>
            Upload Image
          </Button>
        </Box>
      </Box>
      <ImageGalleryModal
        isIcons={false}
        isOpen={galleyOpen}
        onClose={() => setGalleyOpen(false)}
        onInsertImage={(url) => store.setPost({ bannerURL: url })
        }
      />
    </Fragment>
  );
});
