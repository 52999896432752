import React from 'react'

export const ToolBarButton = ({ icon, onClick }: any) => {
    return (
        <div
            className="rdw-option-wrapper"
            onClick={onClick}
        >
            {icon}
        </div>
    )
}
