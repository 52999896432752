import { FirestoreCollection } from "@react-firebase/firestore";
import React, { FunctionComponent } from "react";
import { InboxTagsProviderContext } from "./InboxTagsProvider.context";

export const InboxTagsProvider: FunctionComponent = ({ children }) => {
  return (
    <FirestoreCollection path="/inbox-tags/">
      {({ isLoading, value }) => {
        const availableTags = value?.map(tag => tag.name)
        return (
          <InboxTagsProviderContext.Provider
            value={{
              availableTags,
              isLoading,
            }}
          >
            {children}
          </InboxTagsProviderContext.Provider>
        );
      }}
    </FirestoreCollection>
  );
};
