import { GamesProviderContext } from "./GamesProvider.context";
import React, { FunctionComponent } from "react";
import { useFirebaseProvider } from "Providers/FirebaseProvider";
import { fetchAvailableGames } from "./async";
import { useAsyncTask, useAsyncRun } from "react-hooks-async";
import { useCallback } from "react";
import { NOOP } from "@halfbrick/network-effect-web-shared";

export const GamesProvider: FunctionComponent = ({ children }) => {
  const firebase = useFirebaseProvider();
  const fetchListingTask = useAsyncTask(fetchAvailableGames);

  useAsyncRun(fetchListingTask, firebase.functions);
  const refreshListing = useCallback(() => {
    fetchListingTask.start(firebase.functions);
  }, [fetchListingTask, firebase.functions])

  if (fetchListingTask.pending) {
    return (
      <GamesProviderContext.Provider value={{
        gamesLoading: true,
        availableGames: [],
        refreshGames: NOOP
      }}>
        {children}
      </GamesProviderContext.Provider>
    );
  }
  
  return (
    <GamesProviderContext.Provider value={{
      gamesLoading: false,
      availableGames: fetchListingTask.result || [],
      refreshGames: refreshListing
    }}>
      {children}
    </GamesProviderContext.Provider>
  );
};
