import { useState } from "react";

/**
 * useDialogState - shorthand for managin dialog visibility
 */
export const useDialogState = () => {
  // set the state
  const [dialogOpen, setDialogOpen] = useState(false);

  // helper functions
  const closeDialog = () => setDialogOpen(false);
  const openDialog = () => setDialogOpen(true);

  // return the lot
  return { dialogOpen, openDialog, closeDialog };
};
