import { useFirebaseProvider } from "Providers/FirebaseProvider/FirebaseProvider.hooks";
import React, { ComponentType, useCallback, useState } from "react";
import { FileUploadViewProps } from "./FileUploadView.types";
import { useStyles } from "./FileUploadView.styles";
import { DropzoneArea } from "material-ui-dropzone";
import CheckIcon from "@material-ui/icons/Check";
import md5 from "md5";
import {
  CircularProgress,
  DialogActions,
  Typography,
  Button,
  Avatar,
  Box,
} from "@material-ui/core";
import { GalleryItem, GalleryItemType } from "Providers/ImageGalleryProivder/ImageGalleryProivder.types";

export const FileUploadView: ComponentType<FileUploadViewProps> = ({
  dropzoneText = "Drop an image here or click",
  destinationPath,
  acceptedFiles,
  onUploadComplete,
  isIcons
}) => {

  const { storage, firestore } = useFirebaseProvider();
  const classes = useStyles();

  const [fileUploads, setFileUploads] = useState<File[]>([]);
  const [isComplete, setComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const uploadFile = useCallback(async () => {
    if (Boolean(fileUploads.length)) {
      try {
        setLoading(true);
        const [file] = fileUploads;
        const id = md5(file.name + Math.random().toString(36).substring(7))
        const storageRef = storage.ref(destinationPath);
        const fileRef = storageRef.child(id);
        await fileRef.put(file);
        const url = await fileRef.getDownloadURL();
        // make the firestore object in here now and then go and save it so we can get it back later
        const item = {
          Id: id,
          URL: url,
          type: isIcons ? GalleryItemType.Icon : GalleryItemType.Generic
        } as GalleryItem;
        await firestore.collection("gallery").doc(id).set(item);
        onUploadComplete(url)
      } catch (error) {
        console.error("error uploading file: ", error);
      } finally {
        setComplete(true);
        setLoading(false);
      }
    }
  }, [fileUploads, destinationPath, storage, onUploadComplete, firestore, isIcons]);


  return (
    <>
      {!isLoading && !isComplete && (
        <DropzoneArea
          filesLimit={1}
          showPreviewsInDropzone={false}
          showPreviews={true}
          useChipsForPreview={true}
          acceptedFiles={acceptedFiles}
          dropzoneText={dropzoneText}
          onChange={(files) => setFileUploads(files)}
          clearOnUnmount
        />
      )}
      {isLoading && !isComplete && (
        <Box className={classes.loadingBox} marginY={10}>
          <CircularProgress
            variant="indeterminate"
            size={150}
          ></CircularProgress>
          <Typography variant="h2">Uploading File ...</Typography>
        </Box>
      )}
      {!isLoading && isComplete && (
        <Box className={classes.loadingBox} marginY={10}>
          <Avatar className={classes.SuccessIconAvator}>
            <CheckIcon className={classes.SuccessIcon} />
          </Avatar>
          <br />
          <Typography variant="h2">Upload Complete</Typography>
        </Box>
      )}
      {!isLoading && !isComplete && (
        <DialogActions>
          <Button onClick={uploadFile} color="primary" variant="contained">
            Upload
          </Button>
        </DialogActions>
      )}
    </>
  );
};
