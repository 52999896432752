import { getGlobalSegmentsFromSegmentList } from "../ManageSegments/ManageSegments.utilities";
import { ConfirmPublishDialogProps } from "./ConfirmPublishDialog.types";
import { useRichInboxProvider } from "../RichInboxProvider";
import { useGamesProvider } from "Providers/GamesProvider";
import { useStyles } from "./ConfirmPublishDialog.styles";
import React, { ComponentType, useState } from "react";
import WarningIcon from "@material-ui/icons/Warning";
import AllGamesImage from "Media/AllGames.png";
import { observer } from "mobx-react-lite";
import {
  DialogActions,
  DialogContent,
  Typography,
  Checkbox,
  Avatar,
  Button,
  Dialog,
  Grid,
  Box,
} from "@material-ui/core";

export const ConfirmPublishDialog: ComponentType<ConfirmPublishDialogProps> = observer(
  ({ open, onClose, publishPost }) => {
    const games = useGamesProvider();
    const store = useRichInboxProvider();
    const classes = useStyles();

    const [checked, setChecked] = useState(false);

    const globals = getGlobalSegmentsFromSegmentList(
      store.post.segments,
      games.availableGames
    );

    return (
      <Dialog
        PaperProps={{ className: classes.paperMods }}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Avatar className={classes.WarningIconAvator}>
          <WarningIcon className={classes.WarningIcon} />
        </Avatar>
        <Typography variant="h3" align="center">
          DANGER
        </Typography>
        <DialogContent>
          <Typography variant="body1" paragraph>
            You are about to add this post to the following segments:
          </Typography>
          <Box paddingY={2} color="primary">
            {Object.keys(globals).length > 0 && (
              <Grid container spacing={2} justify={"space-between"}>
                <Grid item>
                  <Typography variant="h5">All Games</Typography>
                  {Object.keys(globals).map((Name) => (
                    <Typography key={Name} variant="body2">
                      {Name}
                    </Typography>
                  ))}
                </Grid>
                <Grid item>
                  <Avatar src={AllGamesImage} />
                </Grid>
              </Grid>
            )}

            {games.availableGames
              .filter((game) =>
                store.post.segments
                  .filter((s) => !Object.keys(globals).includes(s.Name))
                  .some((segment) => segment.titleID === game.titleID)
              )
              .map(({ titleID, displayName, logoUrl }) => {
                return (
                  <Grid
                    key={titleID}
                    container
                    spacing={2}
                    justify={"space-between"}
                  >
                    <Grid item>
                      <Typography variant="h5">{displayName}</Typography>
                      {store.post.segments
                        .filter((s) => s.titleID === titleID)
                        .filter((s) => !Object.keys(globals).includes(s.Name))
                        .map(({ Name }) => (
                          <Typography key={Name} variant="body2">
                            {Name}
                          </Typography>
                        ))}
                    </Grid>
                    <Grid item>
                      <Avatar src={logoUrl} />
                    </Grid>
                  </Grid>
                );
              })}
          </Box>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography variant="body1">
              Yes, I am sure I want to queue this post for publishing.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!checked}
            onClick={publishPost}
            color="primary"
          >
            Create Post
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
