import { FirestoreCollection } from "@react-firebase/firestore";
import { ImageGalleryProivderContext } from "./ImageGalleryProivder.context";
import React, { FunctionComponent } from "react";

export const ImageGalleryProivder: FunctionComponent = ({ children }) => {
  return (
    <FirestoreCollection path="/gallery/">
      {({ isLoading: galleryLoading, value: galleryItems }) => {
        return (
          <ImageGalleryProivderContext.Provider
            value={{
              galleryItems,
              galleryLoading,
            }}
          >
            {children}
          </ImageGalleryProivderContext.Provider>
        );
      }}
    </FirestoreCollection>
  );
};
