import { HistoryListingResult, HistoryWithId } from "@halfbrick/network-effect-web-shared";
import { functions as fn } from "firebase";

export const fetchHistory = async (_: any, functions: fn.Functions):Promise<HistoryWithId[]> => {
  try {
    const getCall = functions.httpsCallable("fetchHistoryListing");
    var data = await getCall();
    if (data === undefined || data === null || data.data === undefined || data.data === null) {
      console.log("Error. No listing returned from call");
      return [];
    }

    const apiResponse:HistoryListingResult = data.data;

    return apiResponse.history;
  } catch (error) {
    console.log("error: ", error);
    return [];
  }
};
