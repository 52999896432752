import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from './DeleteButton.styles';
import { DeleteButtonProps } from './DeleteButton.types';


export const DeleteButton = ({ onDelete, disabled = false }: DeleteButtonProps) => {
    const classes = useStyles();
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
    return (
        <>
            <Button disabled={disabled} className={classes.deleteButton} onClick={() => { setConfirmationDialogOpen(true) }} variant="contained" color="primary" >Delete</Button>
            <Dialog
                open={confirmationDialogOpen}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this?</DialogContentText>
                    <DialogContentText>THIS ACTION CANNOT BE UNDONE!</DialogContentText>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => setConfirmationDialogOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={async () => {
                                await onDelete();
                                setConfirmationDialogOpen(false);
                            }}
                        >
                            Yes, Delete it!
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}
