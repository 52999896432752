import React, { ComponentType, useCallback, useEffect, useRef, useState } from "react";
import { AtomicBlockUtils, ContentBlock, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Modifier, convertFromHTML, BlockMapBuilder, CharacterMetadata, genKey } from "draft-js";
import { HeroImageComponent } from "./HeroImage";
import { ImageGalleryModal } from "Components/ImageGalleryModal";
import { RichTextEditorProps } from "./RichTextEditor.types";
import { Box, ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "./RichTextEditor.theme";
import { useStyles } from "./RichTextEditor.styles";
import isEqual from "lodash.isequal";
import { Editor } from "react-draft-wysiwyg";
import { List, OrderedSet } from "immutable";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ColorPicker } from "./ColorPalette";
import { CallToAction, Panorama, Save } from "@material-ui/icons";
import { ToolBarButton } from "./ToolBarButton";
import { CustomButtonComponent, CustomButtonDialog } from "./CustomButton";


export const RichTextEditor: ComponentType<RichTextEditorProps> = ({
  defaultValue,
  onSave,
  readOnly
}) => {

  const editorRef = useRef<any>(null)
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(defaultValue)))
  const [injectionState, setInjectionState] = useState<EditorState | undefined>();
  useEffect(() => {
    setEditorState(EditorState.createWithContent(convertFromRaw(defaultValue)));
  }, [defaultValue]);

  const [customButtonDialog, setCustomButtonDialog] = useState(false);
  const [customButtonInitialState, setCustomButtonInitialState] = useState<any | undefined>(undefined);
  const [customButtonEditKey, setCustomButtonEditKey] = useState<string | undefined>(undefined);
  const [heroDialogOpen, setHeroDialog] = useState(false);

  const [stale, setStale] = useState(false);
  const classes = useStyles();

  const insertHeroImage = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('HERO-IMAGE', 'IMMUTABLE', {
      url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithImage = Modifier.applyEntity(
      contentStateWithEntity,
      editorState.getSelection(),
      entityKey,
    );
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithImage,
    });
    const finalState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    setInjectionState(finalState);
    setEditorState(finalState);
  }

  const insertCustomButton = (data) => {
    const contentState = editorState.getCurrentContent();
    if (customButtonEditKey) {
      contentState.replaceEntityData(customButtonEditKey, data);
    } else {
      const contentStateWithEntity = contentState.createEntity('CUSTOM-BUTTON', 'IMMUTABLE', data);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const contentStateWithImage = Modifier.applyEntity(
        contentStateWithEntity,
        editorState.getSelection(),
        entityKey,
      );
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithImage,
      });
      const finalState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
      setInjectionState(finalState);
      setEditorState(finalState);
    }
  }


  const onEditorStateChange = useCallback(
    (state: EditorState) => {
      if (injectionState) {
        setEditorState(injectionState);
        setInjectionState(undefined);
      } else {
        setEditorState(state);
      }
      const rawState = convertToRaw(state.getCurrentContent());
      if (!isEqual(defaultValue, rawState)) {
        setStale(true);
      } else setStale(false);
    },
    [defaultValue, injectionState]
  );

  function myBlockRenderer(contentBlock: ContentBlock) {
    const type = contentBlock.getType();
    if (type === 'atomic') {
      const entityKey = contentBlock.getEntityAt(0);
      const entity = editorState.getCurrentContent().getEntity(entityKey)
      switch (entity.getType()) {
        case "HERO-IMAGE":
        case "hero-image":
          return {
            component: HeroImageComponent,
            editable: false,
            props: {
              ...entity.getData()
            }
          };
        case "custom-button":
        case "CUSTOM-BUTTON":
          return {
            component: CustomButtonComponent,
            editable: false,
            props: {
              ...entity.getData(),
              onClick: () => { 
                setCustomButtonEditKey(entityKey);
                setCustomButtonInitialState(JSON.parse(JSON.stringify(entity.getData())));
                setCustomButtonDialog(true);
              }
            }
          };
        default:
          return contentBlock;
      }
    }
  }

  // Whilelist of allowed rules when we paste
  const STYLE_WHITELIST: RegExp[] = [
    /BOLD/,
    /ITALIC/,
    /UNDERLINE/,
    // Any font colour except the default 0,0,0 - black
    /^color-rgb\((?!0,0,0)[\d,]+\)$/,
    // Any font BG colour except the default 255,255,255 - white
    /^bgcolor-rgb\((?!255,255,255)[\d,]+\)$/,
  ];

  const handlePastedText = (text: string, html: string, editorState: EditorState, onChange) => {
    // Nothing HTML pasted
    if (!html)
      return false;
    
    // Go ahead and render with both converter
    const draftjsConvert = convertFromHTML(html);
    const wysiwygConvert = htmlToDraft(html);
    
    // Sanitise our style'd blocks
    const styledBlocks = wysiwygConvert.contentBlocks.filter(e => {
      const text = e.getText();
      // This is the secret sauce to match ContentBlocks with draft-js converter
      // Use only non-empty or empty but "atomic" blocks
      return text.trim() !== "" || (e.getType() === "atomic" && text !== "" && text !== "\n");
    });

    // Create our empty block map array
    const contentBlocks: ContentBlock[] = [];
    
    // Loop thru blocks
    for (let index = 0; index < draftjsConvert.contentBlocks.length; index++) {
      const block = draftjsConvert.contentBlocks[index];
      
      // Create empty charmeta list
      let charMetaList: List<CharacterMetadata> = List();
      
      // Traverse each char in this block
      const styleBlock = styledBlocks[index];
      for (let offset = 0; offset < block.getLength(); offset++) {
        const inlineStyles = styleBlock.getInlineStyleAt(offset);
        
        // Collect the styles we want for this char
        const validStyles: string[] = [];
        inlineStyles.forEach(style => {
          // Test against our whitelist styles
          // and apply that to new char meta object if passes any condition
          if (style && STYLE_WHITELIST.some(p => p.test(style))) {
            validStyles.push(style);
          }
        });

        // Create a char meta without any style
        const newCharMeta = CharacterMetadata.create({
          entity: styleBlock.getEntityAt(offset),
          style: OrderedSet(validStyles),
        });

        // That's an immutable list!
        charMetaList = charMetaList.push(newCharMeta);
      }

      // Image block fix
      const newContentBlock = new ContentBlock({
        key: genKey(),
        type: block.getType(),
        text: block.getText(),
        depth: block.getDepth(),
        data: block.getData(),
        characterList: charMetaList,
      })

      contentBlocks.push(newContentBlock);
    }

    // Well, sometimes we have nothing to paste
    if (contentBlocks.length > 0) {
      let currentContent = editorState.getCurrentContent();
      
      // DO NOT merge global entities (images, buttons etc) - they're broken
      // wysiwygConvert.entityMap.forEach((entity, key: string) => {
      //   // console.log(entity);
      //   // currentContent = currentContent.mergeEntityData(key, entity);
      //   currentContent = currentContent.mergeEntityData(key, entity.data);
      // });
      
      // Replace the selection with pasted content
      const newContentState = Modifier.replaceWithFragment(
        currentContent,
        editorState.getSelection(),
        BlockMapBuilder.createFromArray(contentBlocks),
      );

      // Push the newly created EditorState to react
      onChange(EditorState.push(editorState, newContentState, "insert-fragment"));
      return true;
    }
    
    return false;
  };
  
  const handleSave = useCallback(
    () => {
      onSave(convertToRaw(editorState.getCurrentContent()));
      setStale(false);
    },
    [onSave, editorState])
  return (
    <Box paddingY={1}>
      <ThemeProvider theme={defaultTheme}>
        <Editor
          toolbarStyle={{ position: "sticky", top: 0, left: 0, zIndex: 200 }}
          editorRef={(ref) => { editorRef.current = ref; }}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName={classes.editorWrapper}
          editorClassName={classes.editorComponent}
          toolbar={{
            options: ['inline', 'fontSize', 'colorPicker', 'remove', 'history'],
            inline: {
              options: ['bold'],
            },
            colorPicker: { component: ColorPicker },
          }}
          handlePastedText={handlePastedText}
          toolbarHidden={readOnly}
          readOnly={readOnly}
          customBlockRenderFunc={myBlockRenderer}
          toolbarCustomButtons={[
            <ToolBarButton
              icon={<CallToAction />}
              onClick={() => setCustomButtonDialog(true)}
            />,
            <ToolBarButton
              icon={<Panorama />}
              onClick={() => setHeroDialog(true)}
            />,
            <ToolBarButton
              icon={<Save className={stale ? classes.coloredRed : ""} />}
              onClick={handleSave}
            />,
          ]}
        />
      </ThemeProvider>
      <ImageGalleryModal
        isOpen={heroDialogOpen}
        onClose={() => setHeroDialog(false)}
        onInsertImage={(url) => insertHeroImage(url)}
      />
      <CustomButtonDialog
        onClose={() => {
          setCustomButtonDialog(false);
          setCustomButtonInitialState(undefined);
          setCustomButtonEditKey(undefined);
        }}
        open={customButtonDialog}
        readOnly={readOnly}
        onInsertButton={(data) => insertCustomButton(data)}
        initialState={customButtonInitialState}
      />
    </Box>
  );
};
