import { functions as fn } from "firebase";
import { GameItem, ESRBRating, FetchGameByIdRequest } from "@halfbrick/network-effect-web-shared";

export const fetchGameById = async (_: any, functions: fn.Functions, id: string):Promise<GameItem> => {
  const getCall = functions.httpsCallable("fetchGameById");
  const request:FetchGameByIdRequest = {
    id
  };
  const data = await getCall(request);
  if (data === undefined || data === null || data.data === undefined || data.data === null) {
    console.log("Error. Game not returned");
    return {
      Id: id,
      APIKey: "ERROR",
      titleID: "ERROR",
      displayName: "ERROR",
      logoUrl: "ERROR",
      rating: ESRBRating.E,
      unity: false,
      cloudProvider: "ERROR",
      enabled: false,
      serviceAccount: "",
      publishToGameRemoteConfig: false
    };
  }

  const response:GameItem = data.data;
  return response;
};
