import { AttributeSectionProps } from "./AttributeSection.types";
import { useStyles } from "./AttributeSection.styles";
import { Grid, Typography } from "@material-ui/core";
import React, { ComponentType } from "react";

export const AttributeSection: ComponentType<AttributeSectionProps> = ({
  ValueComponent,
  EditComponent,
  canEdit,
  title,
}) => {
  const classes = useStyles();
  return (
    <Grid container alignItems={"center"} justify={"space-between"}>
      <Grid item>
        <Typography className={classes.heading} variant="h6">
          {title}
        </Typography>
      </Grid>

      <Grid item>{canEdit && EditComponent}</Grid>
      <Grid item xs={12}>
        {ValueComponent}
      </Grid>
    </Grid>
  );
};
